import { BaseFieldTypeLogic } from "@smartsuite/fields-logic/lib/field-type-registry/field-logic";
import { getFieldLogic } from "@smartsuite/fields-logic/lib/field-type-registry/fields-logic-registry";
import memoize from "lodash.memoize";
import { mockPreferences } from "../config/preferences";
import { fieldTypes } from "../registry/fields-logic/field-types-map";
import { FormConfigApplicationStructureItem } from "../types/form";
import { isBlank, prepareFormValueToComparer } from "./formValueFormatters";
import { Comparison, DateModes, FilterField } from "@smartsuite/types";

// Returns sorted list of filter rules, removes inapplicable rules.
// Rules is inapplicable if comparison value is required but not specified.
export function cleanFilterRules(rules: FilterField[]): FilterField[] {
  const emptyComparisons: Comparison[] = [
    "is_empty",
    "is_not_empty",
    "is_overdue",
    "is_not_overdue",
  ];
  const emptyDateModes: DateModes[] = [
    "next_number_of_days",
    "past_number_of_days",
    "date_range",
    "exact_date",
    "exact_date_time",
  ];

  // we take filter rules that either have a value or don't require a value
  return rules.filter(({ value, comparison }) => {
    // date field case
    if (value?.date_mode) {
      return !(emptyDateModes.includes(value.date_mode) && !value.date_mode_value);
    }
    return !isBlank(value) || emptyComparisons.includes(comparison);
  });
}

/**
 * Determines if the condition is satisfied.
 * - If the condition relies on an invalid App field, it is considered as malformed.
 * - If the condition relies on an App field that was not migrated to fields-logic repo yet,
 * it is considered as malformed.
 * - If we cannot grab the field logic of the App field, it is considered as malformed.
 * - If we cannot get a comparer method for the field type, it is considered as malformed.
 *
 * Whenever the condition is malformed, it returns the value in satisfyValueWhenInvalid
 * (default: true). Otherwise, it returns the result of the comparison.
 *
 * @param filterField The filter row configured on the Form Builder for the current field
 * @param formValues The value currently entered by the user in the form for the field, stored in Formik
 * @param appField The App field that the condition is configured for, from the App structure
 * @param satisfyValueWhenInvalid The default value to return when the condition is malformed
 * @returns True if the app field exists, the field logic exists, the comparison method exists,
 * and the comparison method returns true. Otherwise, returns the value of satisfyValueWhenInvalid.
 */
export const isConditionSatisfied = (
  filterField: FilterField,
  formValue: unknown,
  appField?: FormConfigApplicationStructureItem,
  satisfyValueWhenInvalid: boolean = true
): boolean => {
  // If condition relies on an invalid app field, consider it as malformed and satisfy it.
  if (!appField) {
    console.warn(`Rule using an invalid app field cannot be validated. Ignoring it...`);
    return satisfyValueWhenInvalid;
  }

  // If condition relies on an App field that was not migrated to fields-logic repo yet,
  // consider it as malformed and satisfy it.
  if (fieldTypes[appField.field_type].name === BaseFieldTypeLogic.name) {
    console.warn(
      `Rule using field of type "${appField.field_type}" cannot be validated yet. Ignoring the it...\nConsider removing the field "${appField.label}" from the conditions for now.`
    );
    return satisfyValueWhenInvalid;
  }

  // Grab the logic object of the rule field. If not available, consider rule as a malformed and satisfy it.
  const fieldLogic = getFieldLogic(appField.field_type);
  if (!fieldLogic) {
    console.warn(
      `Cannot get field logic of field with type "${appField.field_type}". Ignoring it...`
    );
    return satisfyValueWhenInvalid;
  }

  // Grab the appropriate comparison method to validate if the provided form value satisfies
  // the individual rule for a field
  const generator = fieldLogic.getComparisons(appField, filterField)[filterField.comparison];
  if (typeof generator === "function") {
    const filterValue = fieldLogic.processFilterValue(
      filterField.value,
      filterField.comparison,
      appField,
      mockPreferences
    );
    const comparer = generator(filterValue);
    return comparer(
      prepareFormValueToComparer(formValue, appField, filterField.comparison),
      appField
    );
  } else {
    console.warn(
      `Cannot get a comparer generator for field with type "${appField.field_type}" and comparison "${filterField.comparison}". Ignoring it...\nCheck that the field "${appField.label}" has a valid comparison configured.`
    );
    return satisfyValueWhenInvalid;
  }
};

// Memoize the isConditionSatisfied function to avoid unnecessary re-evaluations for when
// other form fields get altered. Using the information about the filter, form value, app field
// and fallback as the memoization key.
// The form value gets stringified because it may hold an array of values or an object.
export const isConditionSatisfiedMemoized = memoize(
  isConditionSatisfied,
  (filterField, formValue, appField, satisfyValueWhenInvalid) => {
    return `${filterField.field}-${filterField.comparison}-${filterField.value}-${JSON.stringify(
      formValue
    )}-${appField?.field_type}-${satisfyValueWhenInvalid}`;
  }
);
