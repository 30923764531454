export function extractErrorMessage(error: unknown): string | undefined {
  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error)) {
    return error.find((err) => !!err);
  }

  if (isErrorDict(error)) {
    return error.non_field_errors
      ? extractErrorMessage(error.non_field_errors)
      : extractErrorMessage(error[0]);
  }

  return undefined;
}

function isErrorDict(error: unknown): error is ErrorDict {
  return (
    typeof error === "object" &&
    (!!(error as ErrorDict)?.[0] || !!(error as ErrorDict)?.non_field_errors)
  );
}

interface ErrorDict {
  [key: string]: string;
}
