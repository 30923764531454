import { FormikValues } from "formik";
import React, { ReactNode, useCallback, useMemo } from "react";
import {
  FieldVisibilityCallback,
  useFormFieldVisibility,
} from "../../hooks/useFormFieldVisibility";
import { FormConfig, FormConfigDividerItem, FormConfigItem, FormItemType } from "../../types/form";
import { FormFieldItem } from "../FormFieldItem/FormFieldItem";
import { FormHTMLBlockItem } from "../FormHTMLBlockItem/FormHTMLBlockItem";
import { FormSectionItem } from "../FormSectionItem/FormSectionItem";
import { FormImageItem } from "../FormImageItem/FormImageItem";
import { FormVideoItem } from "../FormVideoItem/FormVideoItem";
import { FormCalloutItem } from "../FormCalloutItem/FormCalloutItem";
import { FormDividerItem } from "../FormDividerItem/FormDividerItem";
import { FormRecaptchaItem } from "../FormRecaptchaItem/FormRecaptchaItem";

export interface FormItemsProps {
  config: FormConfig;
  formItems: FormConfigItem[];
  formValues: FormikValues;
}

export const FormItems: React.FC<FormItemsProps> = ({ config, formItems, formValues }) => {
  const appFieldMap = useMemo(() => {
    return new Map(config?.application?.structure?.map((appField) => [appField.slug, appField]));
  }, [config?.application?.structure]);

  const { checkFieldVisibility } = useFormFieldVisibility(formItems, formValues, appFieldMap);

  const renderFormFieldItem = useCallback(
    (item: FormConfigItem, fieldVisibilityCallback: FieldVisibilityCallback) => {
      const appField = appFieldMap.get(item.slug);
      return (
        <FormFieldItem
          appField={appField}
          item={item}
          key={item.slug}
          checkFieldVisibility={fieldVisibilityCallback}
        />
      );
    },
    [appFieldMap]
  );

  const renderFormHTMLBlockItem = (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormHTMLBlockItem
        key={item.slug}
        state={item}
        checkFieldVisibility={fieldVisibilityCallback}
      />
    );
  };

  const renderImageItem = (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormImageItem key={item.slug} item={item} checkFieldVisibility={fieldVisibilityCallback} />
    );
  };

  const renderVideoItem = (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormVideoItem key={item.slug} item={item} checkFieldVisibility={fieldVisibilityCallback} />
    );
  };

  const renderCalloutItem = (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormCalloutItem key={item.slug} item={item} checkFieldVisibility={fieldVisibilityCallback} />
    );
  };

  const renderFormDividerItem = (
    item: FormConfigDividerItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormDividerItem key={item.slug} item={item} checkFieldVisibility={fieldVisibilityCallback} />
    );
  };

  const renderRecaptchaItem = (
    item: FormConfigItem,
    fieldVisibilityCallback: FieldVisibilityCallback
  ): ReactNode => {
    return (
      <FormRecaptchaItem
        key={item.slug}
        item={item}
        checkFieldVisibility={fieldVisibilityCallback}
      />
    );
  };

  const renderFormSectionItem = useCallback(
    (item: FormConfigItem, fieldVisibilityCallback: FieldVisibilityCallback) => {
      return (
        <FormSectionItem
          key={item.slug}
          item={item}
          checkFieldVisibility={fieldVisibilityCallback}
          renderFormFieldItem={renderFormFieldItem}
          renderFormHTMLBlockItem={renderFormHTMLBlockItem}
          renderFormVideoItem={renderVideoItem}
          renderFormImageItem={renderImageItem}
          renderFormCalloutItem={renderCalloutItem}
          renderFormDividerItem={renderFormDividerItem}
          renderFormRecaptchaItem={renderRecaptchaItem}
        />
      );
    },
    [renderFormFieldItem]
  );

  return (
    <>
      {formItems?.map((item) => {
        if (item.type === FormItemType.field)
          return renderFormFieldItem(item, checkFieldVisibility);

        if (item.type === FormItemType.html_block)
          return renderFormHTMLBlockItem(item, checkFieldVisibility);

        if (item.type === FormItemType.section)
          return renderFormSectionItem(item, checkFieldVisibility);

        if (item.type === FormItemType.image) {
          return renderImageItem(item, checkFieldVisibility);
        }

        if (item.type === FormItemType.video) {
          return renderVideoItem(item, checkFieldVisibility);
        }

        if (item.type === FormItemType.callout) {
          return renderCalloutItem(item, checkFieldVisibility);
        }

        if (item.type === FormItemType.divider) {
          return renderFormDividerItem(item, checkFieldVisibility);
        }

        if (item.type === FormItemType.recaptcha) {
          return renderRecaptchaItem(item, checkFieldVisibility);
        }

        return null;
      })}
    </>
  );
};
