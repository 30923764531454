import { extractErrorMessage } from "../helpers/errors";
import { UseFormFieldResult } from "./useFormField";
import { InputGroupState } from "@smartsuite/react-ui/lib/components/forms/InputGroup/InputGroup.constants";

export interface UseCombinedFieldStateResult {
  state: InputGroupState;
  errorMessage: string | undefined;
}

export function useCombinedFieldState(
  fields: Array<UseFormFieldResult<unknown>>
): UseCombinedFieldStateResult {
  return {
    state: fields.find((field) => field.state === "error")
      ? "error"
      : fields.find((field) => field.state === "active")
      ? "active"
      : "default",
    errorMessage: extractErrorMessage(fields.map((field) => field.errorMessage)),
  };
}
