import React, { FC } from "react";

import { $t } from "../../../../../../../../utils/intl";
import "./SelectLinkedRecordModalHeader.sass";
import { SearchField } from "@smartsuite/react-ui/lib/components/input-group/SearchField/SearchField";
import { SelectLinkedRecordModalHeaderText } from "./SelectLinkedRecordModalHeader.text";
import { IconButton } from "@smartsuite/react-ui/lib";

interface SelectLinkedRecordModalHeaderProps {
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
  onClose: () => void;
}

export const SelectLinkedRecordModalHeader: FC<SelectLinkedRecordModalHeaderProps> = ({
  searchValue,
  onChangeSearchValue,
  onClose,
}) => {
  return (
    <header
      className="select-linked-records-header"
      aria-label="Select linked records modal header"
    >
      <div className="select-linked-records-header__main">
        <SearchField
          value={searchValue}
          showClearButton
          autoFocus
          onClear={() => onChangeSearchValue("")}
          onChange={(e) => onChangeSearchValue(e.target.value)}
          placeholder={$t(SelectLinkedRecordModalHeaderText.searchPlaceholder)}
          line
        />
      </div>
      <IconButton
        name="close"
        ariaLabel="Close"
        onClick={onClose}
        backgroundColor="#F3F3F4"
        kind="round"
      />
    </header>
  );
};
