import { sortRecords } from "@smartsuite/fields-logic/lib/sort";
import {
  AppField,
  AppStructureFieldParams,
  FieldChoiceValue,
  FilterField,
  SimpleRecord,
} from "@smartsuite/types";
import { isArray } from "lodash-es";
import { mockPreferences } from "../../../../config/preferences";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../../../../types/form";

interface SelectedFormValues {
  [key: string]: Array<{ id: string }>;
}

export const convertFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  if (!filterField.value) {
    return filterField;
  }

  if (isArray(filterField.value)) {
    return convertArrayFilterValue(filterField, selectedFormValues);
  } else {
    return convertStringFilterValue(filterField, selectedFormValues);
  }
};

export const convertArrayFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField =>
  filterField?.value?.reduce((acc: string[], oldValue: string) => {
    if (selectedFormValues[oldValue]) {
      return [...acc, ...selectedFormValues[oldValue].map((v) => v.id)];
    }
    return acc;
  }, []);

export const convertStringFilterValue = (
  filterField: FilterField,
  selectedFormValues: SelectedFormValues
): FilterField => {
  const dynamicTextFilterVarRegex = /(\[\[[a-z0-9_]+\]\])/g;

  const tokens = filterField.value.split(dynamicTextFilterVarRegex);

  return tokens.reduce((acc: string[], token: string) => {
    if (dynamicTextFilterVarRegex.test(token)) {
      const value = token.slice(2, -2);
      const selectedValueId = selectedFormValues[value]?.map((v) => v.id);
      if (selectedValueId) {
        return [...acc, ...selectedValueId];
      }
    } else {
      if (token) {
        return [...acc, token];
      }
    }

    return acc;
  }, []);
};

interface GetSortedRecordsParams {
  records: SimpleRecord[];
  formItem: FormConfigItem;
  field: FormConfigApplicationStructureItem;
  linkedApplicationFields?: {
    [key: string]: AppField<AppStructureFieldParams<unknown, FieldChoiceValue>>;
  };
}

export const getSortedRecords = ({
  records,
  formItem,
  field,
  linkedApplicationFields,
}: GetSortedRecordsParams): SimpleRecord[] => {
  const sortData =
    formItem.params?.sort_data ??
    field.params?.select_record_modal_sort_data ??
    field.params?.sort_data;

  const subItemsFields = Object.values(linkedApplicationFields ?? {}).filter(
    (field) => field.field_type === "subitemsfield"
  );

  const parseRecords = (records: SimpleRecord[]): SimpleRecord[] => {
    if (subItemsFields.length === 0) {
      return records;
    }

    return records.map((record) => {
      const subItems = subItemsFields.reduce(
        (acc, field) => ({
          ...acc,
          [field.slug]: { count: record[field.slug]?.items?.length || 0 },
        }),
        {}
      );

      return { ...record, ...subItems };
    });
  };

  const parsedRecords = parseRecords(records);

  const sortedRecords =
    sortData && parsedRecords.length && linkedApplicationFields
      ? sortRecords(
          parsedRecords,
          Object.values(linkedApplicationFields ?? {}),
          sortData,
          mockPreferences
        )
      : records;

  return sortedRecords;
};
