import React, { FC, useRef, useState } from "react";
import cx from "classnames";
import {
  AddButton,
  ClickOutside,
  InputGroup,
  isEnterKey,
  Paragraph,
} from "@smartsuite/react-ui/lib";
import { TextColorNames } from "@smartsuite/types";
import PhoneInput, { Country, isValidPhoneNumber } from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/types";

import { $t } from "../../../../../utils/intl";
import { fieldsText } from "../../../fields.text";

import "./AddPhone.sass";
import { validatorText } from "../../../../validator.text";

export interface AddPhoneProps {
  defaultCountry: Country;
  onAddNewPhone: (value: E164Number) => void;
  forceAdding?: boolean;
  onChangeEnteredValue?: (value?: E164Number) => void;
}

export const AddPhone: FC<AddPhoneProps> = ({
  onAddNewPhone,
  defaultCountry,
  forceAdding,
  onChangeEnteredValue,
}) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState<E164Number | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  const [isAdding, setIsAdding] = useState(false);

  const addPhone = (): void => {
    if (!showError) {
      if (value) {
        onAddNewPhone(value);
        setValue(undefined);
      }

      setIsAdding(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (isEnterKey(event)) {
      event.preventDefault();
      addPhone();
    }
  };

  const handleChange = (newValue?: E164Number): void => {
    if (newValue?.length) {
      setValue(newValue);
      setShowError(!isValidPhoneNumber(newValue));
    } else {
      setValue(undefined);
      setShowError(false);
    }

    if (onChangeEnteredValue) {
      onChangeEnteredValue(newValue);
    }
  };

  const setAddingMode = (): void => {
    setIsAdding(true);

    setTimeout(() => {
      if (inputRef.current) {
        const input = inputRef.current as HTMLInputElement;
        input.focus();
      }
    });
  };

  const inputClassName = cx("", {
    "add-phone-element": !forceAdding,
  });

  return (
    <ClickOutside onClickOutside={addPhone}>
      {showError || isAdding || forceAdding ? (
        <div>
          <InputGroup className={inputClassName} state={showError ? "error" : "default"}>
            <PhoneInput
              data-testid="phone-input"
              ref={inputRef}
              countryCallingCodeEditable={false}
              defaultCountry={defaultCountry}
              placeholder={$t(fieldsText.phonePlaceholder)}
              value={value}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
            />
          </InputGroup>
          {showError && (
            <div className="add-phone-element__error-message">
              <Paragraph color="error" size="s">
                {$t(validatorText.validationPhone)}
              </Paragraph>
            </div>
          )}
        </div>
      ) : (
        <AddButton
          className="add-phone-element"
          textStyle={{
            size: "xs",
            weight: "semibold",
            color: TextColorNames.primary,
            hoverColor: TextColorNames.primary,
          }}
          iconStyle={{ marginRight: 6 }}
          onClick={setAddingMode}
        >
          Add Phone Number
        </AddButton>
      )}
    </ClickOutside>
  );
};
