import { FieldMetaProps, useFormikContext } from "formik";
import { extractErrorMessage } from "../helpers/errors";

export function useFormikErrorMessage(meta: FieldMetaProps<unknown>): string | undefined {
  const { submitCount } = useFormikContext();

  return meta.error && (meta.touched || submitCount > 0)
    ? extractErrorMessage(meta.error)
    : undefined;
}
