import {
  DropdownPanel,
  isEnterKey,
  MenuOption,
  MenuOptionWrapper,
  Scrollbar,
  TextFieldGroup,
} from "@smartsuite/react-ui/lib";
import React, { MutableRefObject, useMemo, useRef } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { FormFieldProps } from "../../../types";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import { MAX_TIME_LENGTH, TimeFieldValue } from "../time.config";
import { getTimeChoices, isValidTime } from "../time.helpers";

import { $t } from "../../../../utils/intl";
import { validatorText } from "../../../validator.text";
import "./TimeFieldControl.sass";
import { PlainTextFieldProps } from "@smartsuite/react-ui/lib/components/forms/PlainTextField/PlainTextField";

export type TimeFieldControlProps = FormFieldProps &
  Pick<PlainTextFieldProps, "placeholder" | "type">;

export const TimeFieldControl: React.FunctionComponent<TimeFieldControlProps> = ({
  caption,
  context,
  field,
  formItem,
  label,
  name,
  placeholder,
  required,
  type,
}) => {
  const [focus, setFocus] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState("");
  const dropdownTargetRef = useRef<HTMLInputElement | null>(null);
  const timeChoices = useMemo(
    () => getTimeChoices(context.config.form_state.time_format ?? "24h"),
    [context.config.form_state.time_format]
  );

  const formField = useFormField<TimeFieldValue>({
    name,
    validate: validateWithYup(
      applyYupDefaults(
        yup.string().test("isValidTime", $t(validatorText.validationTime), (value) => {
          return value ? isValidTime(value) : true;
        }),
        field,
        formItem
      )
    ),
  });

  // Prevents attempted form submission on enter. It also prevents
  // a previously edited dropdown field to have its value cleaned.
  const handleKeyDown = (event: KeyboardEvent): void => {
    if (isEnterKey(event)) {
      event.preventDefault();
      handleBlur();
    }
  };

  const handleSelectSuggestion = (suggestion: string): void => {
    formField.onChange(suggestion);
    setFilterValue("");
    formField.onFocus();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    formField.onChange(event);
    setFilterValue(value);
  };

  const handleFocus = (): void => {
    formField.onFocus();
    setFocus(true);
  };

  const handleBlur = (): void => {
    setFocus(false); // close dropdown panel
    // Blurs the input field and clears the filter
    setTimeout(() => {
      formField.onBlur();
      setFilterValue("");
    }, 200);
  };

  const timeChoicesFiltered = useMemo(() => {
    if (!filterValue) return timeChoices;
    return timeChoices.filter((timeChoice) => {
      return timeChoice.label.toLowerCase().includes(filterValue.toLowerCase());
    });
  }, [filterValue, timeChoices]);

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
    >
      <TextFieldGroup
        inputGroupState={formField.state}
        maxLength={MAX_TIME_LENGTH}
        name={formField.name}
        placeholder={placeholder}
        ref={dropdownTargetRef}
        required={required}
        type={type}
        value={formField.value}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
      />
      <DropdownPanel
        at="left bottom+8"
        my="left top"
        showArrow={true}
        arrowBackground="white"
        calculateArrowByPanel={true}
        className="time-field__dropdown"
        isActive={focus && timeChoicesFiltered.length > 0}
        targetElementRef={dropdownTargetRef as MutableRefObject<HTMLElement | undefined>}
      >
        <Scrollbar
          classes="time-field__scrollbar"
          gutterBottom={2}
          gutterRight={2}
          gutterTop={10}
          translateContentSizeYToHolder
          noScrollX
        >
          <MenuOptionWrapper smallListPaddings={true}>
            {timeChoicesFiltered.map((timeChoice) => {
              return (
                <MenuOption
                  key={timeChoice.value}
                  onClick={() => handleSelectSuggestion(timeChoice.label)}
                >
                  {timeChoice.label}
                </MenuOption>
              );
            })}
          </MenuOptionWrapper>
        </Scrollbar>
      </DropdownPanel>
    </FormFieldControl>
  );
};
