import { FieldRegistryConfig } from "../../types";
import { DateFieldControl } from "./DateFieldControl/DateFieldControl";
import { dateToDateValue } from "@smartsuite/fields-logic/lib/utils/date";

export interface DateFieldValue {
  date: string | null;
  include_time: boolean;
}

export const dateFieldConfig: FieldRegistryConfig<DateFieldValue> = {
  icon: "date-select",
  control: (props) => <DateFieldControl {...props} />,
  convertFormValueToApi: (value) =>
    value.include_time
      ? {
          ...value,
          date: value.date ? new Date(value.date).toISOString() : null,
        }
      : dateToDateValue(new Date(value.date ?? ""), value.include_time),
};
