import { FormConfig, FormConfigApplicationStructureItem } from "../types/form";

export function getFieldFromSlug(
  slug: string,
  config: FormConfig
): FormConfigApplicationStructureItem {
  const field = config.application.structure.find((item) => item.slug === slug);
  if (!field) {
    throw new Error(`No field found for form item of slug "${slug}"`);
  }
  return field;
}

export function getFormattedRedirectUrl(redirectUrl: string): string {
  if (redirectUrl && !redirectUrl.toLowerCase().includes("http")) {
    return "https://" + redirectUrl;
  }
  return redirectUrl;
}
