import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useSize } from "@umijs/hooks";
import { ID } from "@smartsuite/types";

export type VSDirection = "horizontal" | "vertical";

export interface VSOptionType {
  itemSize: number | ((index: number) => number);
  direction?: VSDirection;
  overscan?: number;
  viewPortRef?: MutableRefObject<HTMLElement>;
}

export interface VSListItem<T> {
  data: T;
  index: number;
}

export interface VSContainerProps {
  ref: (element: HTMLElement) => void;
  onScroll: (event: React.UIEvent) => void;
  style: { overflowY: string };
}

export interface VSWrappedProps {
  style: {
    width: string;
    height: number;
    paddingTop: number;
    paddingLeft: number;
  };
}

type VSSize = {
  width?: number;
  height?: number;
};

type VSPosition = {
  start: number;
  end: number;
};

export const useVirtualList = <TItem extends { id: ID }>(
  list: TItem[],
  options: VSOptionType
): {
  list: Array<VSListItem<TItem>>;
  containerProps: VSContainerProps;
  containerSize: VSSize;
  wrapperProps: VSWrappedProps;
  scrollToIndex: (index: number) => void;
  scrollToPosition: (position: number) => void;
} => {
  const { direction = "vertical" } = options;
  const itemSize = options.itemSize;
  // eslint-disable-next-line no-void
  const overscan = options.overscan === void 0 ? 5 : options.overscan;
  const [size, containerRef]: [VSSize, MutableRefObject<HTMLElement>] = useSize();
  const [state, setState] = useState<VSPosition>({ start: 0, end: overscan + 1 });

  const getViewCapacity = useCallback(
    (containerSize: number) => {
      if (typeof itemSize === "number") {
        return Math.ceil(containerSize / itemSize);
      }

      // eslint-disable-next-line no-void
      const start = state.start === void 0 ? 0 : state.start;
      let sum = 0;
      let capacity = 0;

      for (let i = start; i < list.length; i++) {
        const elementSize = itemSize(i);
        sum += elementSize;

        if (sum >= containerSize) {
          capacity = i;
          break;
        }
      }

      return capacity - start;
    },
    [list.length, itemSize, state.start]
  );

  const getOffset = useCallback(
    (scrollTo: number) => {
      if (typeof itemSize === "number") {
        return Math.floor(scrollTo / itemSize) + 1;
      }

      let sum = 0;
      let offset = 0;

      for (let i = 0; i < list.length; i++) {
        const elementSize = itemSize(i);
        sum += elementSize;

        if (sum >= scrollTo) {
          offset = i;
          break;
        }
      }

      return offset + 1;
    },
    [list.length, itemSize]
  );

  const calculateRange = useCallback(
    (scrollTo?: number) => {
      const element = containerRef.current;

      if (element) {
        const elementScrollTo = direction === "vertical" ? element.scrollTop : element.scrollLeft;
        const elementSize = direction === "vertical" ? element.clientHeight : element.clientWidth;
        const offset = getOffset(scrollTo ?? elementScrollTo);
        const viewCapacity = getViewCapacity(elementSize);
        const from = offset - overscan;
        const to = offset + viewCapacity + overscan;

        setState({
          start: from < 0 ? 0 : from,
          end: to > list.length ? list.length : to,
        });
      }
    },
    [containerRef, getOffset, getViewCapacity, list.length, overscan, direction]
  );

  useEffect(() => {
    calculateRange(getDistance(state.start));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width, size.height, list.length]);

  const totalHeight = useMemo(() => {
    if (typeof itemSize === "number") {
      return list.length * itemSize;
    }

    return list.reduce(function (sum, _, index) {
      return sum + itemSize(index);
    }, 0);
  }, [list, itemSize]);

  const getDistance = useCallback(
    (index: number) => {
      if (typeof itemSize === "number") return index * itemSize;

      return list.slice(0, index).reduce(function (sum, _, i) {
        return sum + itemSize(i);
      }, 0);
    },
    [list, itemSize]
  );

  const scrollToIndex = useCallback(
    (index: number): void => {
      calculateRange(getDistance(index));
    },
    [calculateRange, getDistance]
  );

  const scrollToPosition = useCallback(
    (scrollTo: number): void => {
      calculateRange(scrollTo);
    },
    [calculateRange]
  );

  const getList = useMemo(() => {
    return list.slice(state.start, state.end).map(function (element, index) {
      return {
        data: element,
        index: index + state.start,
      };
    });
  }, [list, state.start, state.end]);

  return {
    list: getList,
    containerProps: {
      ref: function ref(element) {
        containerRef.current = element;
      },
      onScroll: function onScroll(event: React.UIEvent) {
        event.preventDefault();
        calculateRange();
      },
      style: { overflowY: "auto" },
    },
    containerSize: size,
    wrapperProps: {
      style: {
        width: "100%",
        height: totalHeight,
        paddingTop: direction === "vertical" ? getDistance(state.start) : 0,
        paddingLeft: direction === "horizontal" ? getDistance(state.start) : 0,
      },
    },
    scrollToIndex,
    scrollToPosition,
  };
};
