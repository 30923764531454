import { createContext, useContext } from "react";
import { FormConfig } from "../types/form";

export interface FormContextValue {
  accountId: string;
  config: FormConfig;
}

export const FormContext = createContext<FormContextValue | null>(null);

export function useFormContext(): FormContextValue {
  const context = useContext(FormContext);
  if (!context) throw new Error("FormContext must be initialized.");
  return context;
}
