import { ChangeEvent, FC, FocusEvent, KeyboardEvent, useRef, useState } from "react";
import * as yup from "yup";
import {
  LinkPill,
  InputGroup,
  isEnterKey,
  isBackspaceKey,
  isDeleteKey,
} from "@smartsuite/react-ui/lib";
import cx from "classnames";

import { FormFieldProps } from "../../../types";
import { useFormField } from "../../../../hooks/useFormField";
import { LinkFieldValue } from "../link.config";
import { validateWithYup } from "../../../validator";
import { $t } from "../../../../utils/intl";
import { validatorText } from "../../../validator.text";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import "./MultipleLinkFieldControl.sass";
import { URL_REGEX } from "../../text/validation";

export type LinkFieldControlProps = FormFieldProps;

// TODO: Components that handled the "multiple" fields are very similar, need refactoring into one component
export const MultipleLinkFieldControl: FC<LinkFieldControlProps> = ({
  formItem,
  name,
  required,
  label,
  caption,
  placeholder,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>("");

  const formField = useFormField<LinkFieldValue>({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.string().matches(URL_REGEX, $t(validatorText.validationUrl)))
        .when({
          is: () => formItem.required,
          then: (s) => s.required($t(validatorText.validationRequired)),
        })
    ),
  });

  const handleAddLink = (link: string): void => {
    if (formField.value?.includes(link)) return;

    if (formField.value?.length) {
      formField.onChange([...formField.value, link]);
    } else {
      formField.onChange([link]);
    }
  };

  const handleChange = (oldValue: string, newValue: string): void => {
    formField.onChange(formField.value.map((link) => (link === oldValue ? newValue : link)));
  };

  const handleRemoveLink = (link: string): void => {
    formField.onChange(formField.value.filter((currentLink) => currentLink !== link));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (isEnterKey(event)) {
      event.preventDefault();
      handleAddLink(inputValue);
      setInputValue("");
    }
    if ((isBackspaceKey(event) || isDeleteKey(event)) && !inputValue) {
      handleRemoveLink(formField.value[formField.value.length - 1]);
    }
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    formField.onBlur(event);
    if (inputValue) {
      handleAddLink(inputValue);
      setInputValue("");
    }
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);

    if (event.target.value.charAt(event.target.value.length - 1) === " ") {
      handleAddLink(event.target.value.trim());
      setInputValue("");
    }

    if (event.target.value.charAt(event.target.value.length - 1) === ",") {
      handleAddLink(event.target.value.trim().slice(0, -1));
      setInputValue("");
    }
  };

  const handleInputGroupClick = (): void => {
    if (inputRef.current) {
      inputRef.current.focus();
      formField.onFocus();
    }
  };

  const inputClassNames = cx("link-field-control__input-multiple", {
    "link-field-control__input-multiple__not_empty": !!formField.value?.length,
  });

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
    >
      <InputGroup state={formField.state} onClick={handleInputGroupClick}>
        <div className="link-field-control__multiple">
          {formField.value?.map((link: string) => {
            return (
              <LinkPill
                key={link}
                initValue={link}
                onChange={handleChange}
                onRemove={handleRemoveLink}
              />
            );
          })}
          <input
            ref={inputRef}
            data-testid="input"
            value={inputValue}
            placeholder={!formField.value?.length ? placeholder : ""}
            className={inputClassNames}
            onKeyDown={handleKeyDown}
            onChange={handleChangeInputValue}
            onFocus={formField.onFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </InputGroup>
    </FormFieldControl>
  );
};
