/* eslint-disable jsx-a11y/no-redundant-roles */
import { Paragraph, RoundButton, roundButtonStylesLarge } from "@smartsuite/react-ui/lib";
import { Formik, FormikHelpers } from "formik";

import { FormConfig, FormValues } from "../../types/form";
import { FormikScrollToError } from "../FormikScrollToError/FormikScrollToError";
import { FormItems } from "../FormItems/FormItems";
import { FormTitle } from "../FormTitle/FormTitle";

import { createPortal } from "react-dom";
import { loadCheckpoint, saveCheckpointDebounced } from "../../helpers/checkpoints";
import { ProgressRestoreBanner } from "../ProgressRestore/ProgressRestoreBanner";
import { ProgressRestoreTooltip } from "../ProgressRestore/ProgressRestoreTooltip";
import "./Form.sass";

export interface FormProps {
  accountId: string;
  config: FormConfig;
  hash: string;
  onSubmit: (values: FormValues, formik: FormikHelpers<FormValues>) => Promise<void> | void;
}

export const Form: React.FC<FormProps> = ({ accountId, config, hash, onSubmit }) => {
  const formState = config?.form_state;
  const hasProgressRestore = formState?.has_progress_restore;
  const initialValues = hasProgressRestore
    ? loadCheckpoint(accountId, hash, config?.application, config?.form_state.items)
    : {};

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, handleSubmit, status, values }) => {
        if (hasProgressRestore && !isSubmitting) saveCheckpointDebounced(accountId, hash, values);

        return (
          <form role="form" onSubmit={handleSubmit}>
            <FormikScrollToError />

            <FormTitle title={formState?.title} description={formState?.description} />

            <FormItems config={config} formItems={formState?.items} formValues={values} />

            <div className="form__footer">
              <RoundButton
                styles={roundButtonStylesLarge}
                loading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                {formState?.submit_label ?? "Submit"}
              </RoundButton>
            </div>

            {status === "error" && (
              <div className="form__unexpected-error">
                <br />
                <Paragraph color="error">
                  An unexpected error has occurred, please try again later.
                </Paragraph>
              </div>
            )}

            {hasProgressRestore && <ProgressRestoreBanner />}
            {hasProgressRestore &&
              createPortal(
                <ProgressRestoreTooltip />,
                document.querySelector(".page-layout__content") ?? document.body
              )}
          </form>
        );
      }}
    </Formik>
  );
};
