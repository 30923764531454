import {
  DropdownPanel,
  MenuOption,
  MenuOptionWrapper,
  PlainTextField,
} from "@smartsuite/react-ui/lib";
import { MutableRefObject, useCallback, useEffect, useRef } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { UseFormFieldResult } from "../../../../hooks/useFormField";
import { autocompleteTypesMap } from "../address.constants";
import { AddressFieldTypes } from "../address.types";
import "./AutocompleteAddressField.sass";

export type AutocompleteAddressFieldProps = {
  formField: UseFormFieldResult<string>;
  formFieldType: AddressFieldTypes;
  isLoaded: boolean;
  isMultiple: boolean;
  placeholder?: string;

  onSelectSuggestion: (
    suggestion: google.maps.places.AutocompletePrediction,
    formField: UseFormFieldResult<string>,
    formFieldType: AddressFieldTypes,
    isMultiple: boolean
  ) => Promise<void>;
};

export const AutocompleteAddressField: React.FunctionComponent<AutocompleteAddressFieldProps> = ({
  formField,
  formFieldType,
  isLoaded,
  isMultiple,
  placeholder,
  onSelectSuggestion,
}) => {
  const dropdownTargetRef = useRef<HTMLInputElement | null>(null);

  const {
    init,
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      types: autocompleteTypesMap.get(formFieldType) ?? ["geocode"],
    },
    debounce: 300,
  });

  useEffect(() => {
    if (isLoaded) init();
  }, [init, isLoaded]);

  useEffect(() => {
    setValue(formField.value);
  }, [formField.value, setValue]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      formField.onChange(e);
    },
    [formField, setValue]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      formField.onBlur(e);
    },
    [formField]
  );

  const handleFocus = useCallback(() => {
    formField.onFocus();
  }, [formField]);

  const handleSelectSuggestion = (suggestion: google.maps.places.AutocompletePrediction): void => {
    // Single line renders the entire address in the input
    if (!isMultiple) {
      setValue(suggestion.description, false);
    }

    clearSuggestions();
    void onSelectSuggestion(suggestion, formField, formFieldType, isMultiple);
  };

  return (
    <>
      <PlainTextField
        className="autocomplete-address-field__input"
        disabled={!ready}
        name={formField.name}
        placeholder={placeholder}
        ref={dropdownTargetRef}
        state={formField.state}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <DropdownPanel
        at="left bottom+4"
        my="left top"
        className="autocomplete-address-field__dropdown"
        isActive={status === "OK" && formField.state === "active"}
        targetElementRef={dropdownTargetRef as MutableRefObject<HTMLElement | undefined>}
      >
        <MenuOptionWrapper maxHeight={300}>
          {data.map((suggestion) => {
            const {
              place_id: placeId,
              structured_formatting: { main_text: mainText, secondary_text: secondaryText },
            } = suggestion;

            return (
              <MenuOption key={placeId} onClick={() => handleSelectSuggestion(suggestion)}>
                <strong>{mainText}</strong> <small>{secondaryText}</small>
              </MenuOption>
            );
          })}
        </MenuOptionWrapper>
      </DropdownPanel>
    </>
  );
};
