import { isDocEmpty } from "@smartsuite/smartdoc/lib/esm/core/helpers";
import React, { Suspense } from "react";
import * as yup from "yup";
import { ErrorBoundary } from "../../../../components/ErrorBoundary/ErrorBoundary";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { SmartdocFieldValue } from "../smartdoc.config";
import { type SmartdocDocument } from "./Smartdoc";

const Smartdoc = React.lazy(() => import(/* webpackChunkName: "smartdoc" */ "./Smartdoc"));

export type SmartdocFieldControlProps = FormFieldProps;

export const SmartdocFieldControl: React.FunctionComponent<SmartdocFieldControlProps> = ({
  formItem,
  name,
  label,
  required,
  caption,
}) => {
  const formField = useFormField<SmartdocFieldValue>({
    name,
    validate: validateWithYup(
      yup.object().when({
        is: () => formItem.required,
        then: (s) =>
          s.test(
            "isNotEmptySmartdoc",
            $t(validatorText.validationRequired),
            (value) => !isDocEmpty(value?.data)
          ),
      })
    ),
  });

  const handleChange = (doc: SmartdocDocument): void => {
    formField.onChange({ data: doc });
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
    >
      <ErrorBoundary>
        <Suspense fallback={null}>
          <Smartdoc
            initialDoc={formField.value?.data}
            state={formField.state}
            onChange={handleChange}
            onFocus={formField.onFocus}
            onBlur={formField.onBlur}
          />
        </Suspense>
      </ErrorBoundary>
    </FormFieldControl>
  );
};
