import { defineMessages } from "react-intl";

export const SelectLinkedRecordModalFooterText = defineMessages({
  cancel: {
    defaultMessage: "Cancel",
    id: "47FYwb",
  },
  linkRecords: {
    defaultMessage: "Link records",
    id: "3s+rAO",
  },
  linkRecordsWithCount: {
    defaultMessage: "Link {count} records",
    id: "MhRYtY",
  },
});
