import { Member } from "../../../types/form";
import { FieldRegistryConfig } from "../../types";
import { AssignedToFieldControl } from "./AssignedToFieldControl/AssignedToFieldControl";

export type AssignToFormValue = Member & {
  member: Member;
};

export type AssignToFieldValue = string[];

/**
 * NB: In order to allow comparisons of types "contains" and "does not contain" we need to
 * have access to the actual label of the selected choices. Traditionally, this field would
 * only hold an array of member IDs, but we need to be able to grab the member name in
 * comparison time.
 * This data is not accessible by default inside the comparison function because it would
 * require a query to the API to fetch the member name and access to context data. Instead,
 * we store the member name in the field choice value, within the 'member' key.
 *
 * In the comparison, we read either the names or the IDs, depending on the method.
 * When submitting the form, we send only the IDs.
 */
export const assignToFieldConfig: FieldRegistryConfig<AssignToFormValue[], AssignToFieldValue> = {
  icon: "user",
  control: AssignedToFieldControl,
  convertFormValueToApi: (value) => value.map((v) => v.id),
};
