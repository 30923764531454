import {
  KeyValueCustomMultipleSelect,
  KeyValueCustomSelect,
  Pill,
  Text,
} from "@smartsuite/react-ui/lib";
import { useLinkedRecords } from "../../hooks/useLinkedRecords";
import { LinkedRecordFormValue } from "../../../linked-record.config";
import { $t } from "../../../../../../utils/intl";
import { fieldsStateText, fieldsText } from "../../../../fields.text";
import {
  FormConfigApplicationStructureItem,
  FormConfigItem,
  LinkedRecordObject,
} from "../../../../../../types/form";
import { UseFormFieldResult } from "../../../../../../hooks/useFormField";
import { useEffect, useState } from "react";
import { SimpleRecord } from "@smartsuite/types";
import { getSortedRecords } from "../../linked-records.helpers";

interface SelectLinkedRecordDropdownProps {
  field: FormConfigApplicationStructureItem;
  formField: UseFormFieldResult<LinkedRecordObject[]>;
  formItem: FormConfigItem;
  alreadySelectedRecords?: LinkedRecordObject[];
  handleSelectChange: (changeValue: LinkedRecordObject | LinkedRecordObject[]) => void;
}

export const SelectLinkedRecordDropdown: React.FunctionComponent<
  SelectLinkedRecordDropdownProps
> = ({ field, formItem, formField, handleSelectChange }) => {
  const [dropdownRecords, setDropdownRecords] = useState<SimpleRecord[]>([]);

  const handleRecordsChange = (records: SimpleRecord[]): void => {
    setDropdownRecords(
      records
        .map((linkedRecord) => ({
          id: linkedRecord.id as string,
          title: linkedRecord.title,
          linkedRecord: {
            id: linkedRecord.id as string,
            title: linkedRecord.title,
          },
        }))
        .sort((lr1, lr2) => lr1.title.toLowerCase().localeCompare(lr2.title.toLowerCase()))
    );
  };

  const { isMultiple, isRecordsLoading, linkedApplicationFields } = useLinkedRecords({
    field,
    formField,
    formItem,
    enabled: formField.state === "active",
    onRecordsChange: handleRecordsChange,
  });

  useEffect(() => {
    if (!isRecordsLoading) {
      const sortedRecords = getSortedRecords({
        records: dropdownRecords,
        formItem,
        field,
        linkedApplicationFields,
      });

      setDropdownRecords(sortedRecords);
    }
  }, [JSON.stringify(dropdownRecords), JSON.stringify(linkedApplicationFields), isRecordsLoading]);

  return (
    <>
      {isMultiple && (
        <KeyValueCustomMultipleSelect<"linkedRecord", "title", LinkedRecordFormValue>
          labelField="title"
          options={dropdownRecords as LinkedRecordFormValue[]}
          placeholder={
            isRecordsLoading
              ? $t(fieldsStateText.loading)
              : $t(fieldsText.multipleSelectPlaceholder)
          }
          renderSelectedOption={(option) => <Pill text={option.title} />}
          renderOption={(option) => (
            <Text
              tag="div"
              weight="medium"
              wordBreak="break-word"
              color="currentColor"
              className="linkedrecord-multple-field-control-option"
            >
              {option.title}
            </Text>
          )}
          searchPlaceholder={$t(fieldsText.multipleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value}
          valueField="linkedRecord"
          valueFieldPath="id"
          virtualized
          itemHeight={36}
          selectListItemsClass="linkedrecord-multiple-field-control-list"
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}

      {!isMultiple && (
        <KeyValueCustomSelect<"linkedRecord", "title", LinkedRecordFormValue>
          labelField="title"
          options={dropdownRecords as LinkedRecordFormValue[]}
          placeholder={
            isRecordsLoading ? $t(fieldsStateText.loading) : $t(fieldsText.singleSelectPlaceholder)
          }
          renderOption={(option) => (
            <Text
              tag="div"
              weight="medium"
              wordBreak="break-word"
              color="currentColor"
              className="linkedrecord-single-field-control-option"
            >
              {option.title}
            </Text>
          )}
          searchPlaceholder={$t(fieldsText.singleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value?.[0]}
          valueField="linkedRecord"
          valueFieldPath="id"
          virtualized
          itemHeight={36}
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}
    </>
  );
};
