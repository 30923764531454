import { defineMessages } from "react-intl";

export const FormNotFoundMessageText = defineMessages({
  imgAlt: {
    defaultMessage: "Form not found",
    id: "8HE0GP",
  },
  header: {
    defaultMessage: "Form Not Available",
    id: "0r8Ex9",
  },
  description: {
    defaultMessage: "The form you're looking for does not exist or was disabled by its creator.",
    id: "2VzFqE",
  },
});
