import { defineMessages } from "react-intl";

export const ProgressRestoreText = defineMessages({
  tooltipLabel: {
    defaultMessage: "Progress auto-saved",
    id: "JTi4oP",
  },
  autosaveTitle: {
    defaultMessage: "Autosave your work",
    id: "tNVTRS",
  },
  autosaveDescription: {
    defaultMessage:
      "This form automatically saves your progress. You can come back and finish it at any time.",
    id: "VNsR5L",
  },
  autosaveNote: {
    defaultMessage: "Note : You need to use the same browser to restore your saved progress.",
    id: "JVzYSP",
  },
});
