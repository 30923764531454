import { InputGroup, NativeSelect, PlainTextField } from "@smartsuite/react-ui/lib";
import React from "react";
import * as yup from "yup";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { FullNameFieldValue } from "../full-name.config";
import { FullNameFieldControlMessages } from "./FullNameFieldControl.text";

import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useCombinedFieldState } from "../../../../hooks/useCombinedFieldState";
import { useFormField } from "../../../../hooks/useFormField";
import { validatorText } from "../../../validator.text";
import "./FullNameFieldControl.sass";

export type FullNameFieldControlProps = FormFieldProps;

export type FullNamePart = keyof FullNameFieldValue;

export const FullNameFieldControl: React.FunctionComponent<FullNameFieldControlProps> = ({
  field,
  formItem,
  name,
  label,
  required,
  caption,
}) => {
  const requiredText = $t(validatorText.validationRequired);
  const firstNameField = useFormField({
    name: `${name}.first_name`,
    validate: validateWithYup(
      yup.string().when({
        is: () => formItem.required && displayFields.includes("first_name"),
        then: (s) => s.required(requiredText),
      })
    ),
  });
  const lastNameField = useFormField({
    name: `${name}.last_name`,
    validate: validateWithYup(
      yup.string().when({
        is: () => formItem.required && displayFields.includes("last_name"),
        then: (s) => s.required(requiredText),
      })
    ),
  });
  const middleNameField = useFormField({
    name: `${name}.middle_name`,
  });
  const titleField = useFormField({
    name: `${name}.title`,
    validate: validateWithYup(
      yup.string().when({
        is: () => formItem.required && displayFields.includes("title"),
        then: (s) => s.required(requiredText),
      })
    ),
  });

  const choices = field.params?.choices ?? [];
  const displayFields: FullNamePart[] = (field.params?.display_fields as FullNamePart[]) ?? [
    "first_name",
    "last_name",
  ];

  const { state, errorMessage } = useCombinedFieldState([
    titleField,
    firstNameField,
    middleNameField,
    lastNameField,
  ]);

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={state}
      errorMessage={errorMessage}
    >
      <div className="full-name-field-control__fields">
        {displayFields.includes("title") && (
          <div className="full-name-field-control__field full-name-field-control__field--title">
            <InputGroup state={titleField.state}>
              <NativeSelect
                placeholder={$t(FullNameFieldControlMessages.titlePlaceholder)}
                name={titleField.name}
                value={titleField.value}
                defaultValue={undefined}
                onBlur={titleField.onBlur}
                onFocus={titleField.onFocus}
                onChange={titleField.onChange}
              >
                <option value=""></option>
                {choices.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </NativeSelect>
            </InputGroup>
          </div>
        )}

        {displayFields.includes("first_name") && (
          <div className="full-name-field-control__field">
            <PlainTextField
              placeholder={$t(FullNameFieldControlMessages.firstNamePlaceholder)}
              name={firstNameField.name}
              value={firstNameField.value}
              state={firstNameField.state}
              onBlur={firstNameField.onBlur}
              onFocus={firstNameField.onFocus}
              onChange={firstNameField.onChange}
            />
          </div>
        )}

        {displayFields.includes("middle_name") && (
          <div className="full-name-field-control__field">
            <PlainTextField
              placeholder={$t(FullNameFieldControlMessages.middleNamePlaceholder)}
              name={middleNameField.name}
              value={middleNameField.value}
              state={middleNameField.state}
              onBlur={middleNameField.onBlur}
              onFocus={middleNameField.onFocus}
              onChange={middleNameField.onChange}
            />
          </div>
        )}

        {displayFields.includes("last_name") && (
          <div className="full-name-field-control__field">
            <PlainTextField
              placeholder={$t(FullNameFieldControlMessages.lastNamePlaceholder)}
              name={lastNameField.name}
              value={lastNameField.value}
              state={lastNameField.state}
              onBlur={lastNameField.onBlur}
              onFocus={lastNameField.onFocus}
              onChange={lastNameField.onChange}
            />
          </div>
        )}
      </div>
    </FormFieldControl>
  );
};
