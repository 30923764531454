import {
  AppField,
  AppStructureFieldParams,
  FieldChoiceValue,
  SimpleRecord,
} from "@smartsuite/types";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getLinkedApplicationFields, getLinkedRecords } from "../../../../../api";
import { useFormContext } from "../../../../../hooks/useFormContext";
import { Fields } from "../../../../../types/fields";
import { getFilterData } from "../components/SelectLinkedRecordModal/SelectLinkedRecordModal.helpers";
import { convertFilterValue } from "../linked-records.helpers";
import { useIsPreview } from "../../../../../hooks/useIsPreview";
import { useFormikContext } from "formik";
import {
  FormConfigApplicationStructureItem,
  FormConfigItem,
  LinkedRecordObject,
} from "../../../../../types/form";
import { UseFormFieldResult } from "../../../../../hooks/useFormField";

interface UseLinkedRecordsProps {
  field: FormConfigApplicationStructureItem;
  formField: UseFormFieldResult<LinkedRecordObject[]>;
  onRecordsChange?: (record: SimpleRecord[]) => void;
  formItem: FormConfigItem;
  enabled?: boolean;
}

interface UseLinkedRecordsResult {
  records?: SimpleRecord[];
  isRecordsLoading: boolean;
  linkedApplicationFields:
    | {
        [key: string]: AppField<AppStructureFieldParams<unknown, FieldChoiceValue>>;
      }
    | undefined;
  linkedAppStructureLoading: boolean;
  isMultiple: boolean;
  isPreview: boolean;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const useLinkedRecords = ({
  field,
  formItem,
  onRecordsChange,
  enabled = true,
}: UseLinkedRecordsProps): UseLinkedRecordsResult => {
  const [searchValue, setSearchValue] = useState("");

  const formContext = useFormContext();
  const { values } = useFormikContext();

  const { isPreview } = useIsPreview();

  const isMultiple = field.params?.entries_allowed === "multiple";

  const valuesCacheKey = useMemo(() => {
    const selectedFormValues = values as { [key: string]: Array<{ id: string }> };
    return Object.keys(selectedFormValues).reduce((acc, key) => {
      return `${acc}_${key}_${JSON.stringify(selectedFormValues[key])}`;
    }, "");
  }, [values]);

  const { data: records, isLoading: recordsLoading } = useQuery(
    `${Fields.linkedrecordfield}_${field.slug}_${field.params?.linked_application}_records_${valuesCacheKey}`,
    async () => {
      if (
        formContext.accountId &&
        formContext.config.sharing_hash &&
        field.params?.linked_application
      ) {
        let filterData = getFilterData(formItem.params, field.params);

        if (filterData) {
          const selectedFormValues = values as { [key: string]: Array<{ id: string }> };

          filterData = {
            ...filterData,
            fields: filterData.fields.map((filterField) => ({
              ...filterField,
              value: filterField?.is_dynamic_value
                ? convertFilterValue(filterField, selectedFormValues)
                : filterField.value,
            })),
          };
        }

        const res =
          (await getLinkedRecords(
            formContext.accountId,
            formContext.config.sharing_hash,
            field.params.linked_application,
            field.slug,
            filterData,
            isPreview
          )) || [];

        onRecordsChange?.(res);

        return res;
      }
    },
    {
      enabled,
      staleTime: Infinity,
    }
  );

  const { data: linkedApplicationFields, isLoading: linkedAppStructureLoading } = useQuery(
    `${Fields.linkedrecordfield}_${field.slug}_${field.params?.linked_application}_fields_labels`,
    async () => {
      if (
        formContext.accountId &&
        formContext.config.sharing_hash &&
        field.params?.linked_application
      ) {
        return await getLinkedApplicationFields(
          formContext.accountId,
          field.params.linked_application,
          formContext.config.sharing_hash,
          isPreview
        );
      }
    },
    {
      staleTime: Infinity,
    }
  );

  return {
    records,
    isRecordsLoading: recordsLoading,
    linkedApplicationFields,
    linkedAppStructureLoading,
    searchValue,
    isMultiple,
    isPreview,
    setSearchValue,
  };
};
