import { defineMessages } from "react-intl";

export const fieldsText = defineMessages({
  currencyPlaceholder: { defaultMessage: "Enter amount", id: "+HMSZw" },
  emailPlaceholder: { defaultMessage: "Enter email", id: "KinTIy" },
  linkPlaceholder: { defaultMessage: "Enter web address", id: "mzEzG9" },
  multipleSelectPlaceholder: { defaultMessage: "Select option(s)", id: "1tRWK2" },
  multipleSelectSearchOptionPlaceholder: { defaultMessage: "Search option(s)", id: "4WpzKy" },
  numberPlaceholder: { defaultMessage: "Enter number", id: "VsgYoF" },
  percentPlaceholder: { defaultMessage: "Enter percent", id: "rbgC1y" },
  phonePlaceholder: { defaultMessage: "Enter phone number", id: "OTAs3L" },
  singleSelectSearchOptionPlaceholder: { defaultMessage: "Search option", id: "7tebQ2" },
  singleSelectPlaceholder: { defaultMessage: "Select option", id: "tyF1wo" },
  textPlaceholder: { defaultMessage: "Write here...", id: "oC/s1v" },
  textPlaceholderTip: { defaultMessage: `Tip: Press "/" for commands`, id: "m9q8WN" },
  timePlaceholder: { defaultMessage: "Enter time here...", id: "L+VbpF" },
  titlePlaceholder: { defaultMessage: "Write here", id: "1TfSYy" },
  durationPlaceholder: { defaultMessage: "Type minutes, hours or days", id: "00VVKx" },
  IPAddressPlaceholder: { defaultMessage: "Enter IP address", id: "EeF7CH" },
});

export const fieldsStateText = defineMessages({
  loading: {
    defaultMessage: "Loading...",
    id: "gjBiyj",
  },
});
