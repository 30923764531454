import { Fields } from "../types/fields";
import { addressFieldConfig } from "./fields/address/address.config";
import { assignToFieldConfig } from "./fields/assign-to/assign-to.config";
import { currencyFieldConfig } from "./fields/currency/currency.config";
import { dateFieldConfig } from "./fields/date/date.config";
import { emailFieldConfig } from "./fields/email/email.config";
import { fileFieldConfig } from "./fields/file/file.config";
import { fullNameFieldConfig } from "./fields/full-name/full-name.config";
import { linkFieldConfig } from "./fields/link/link.config";
import { linkedRecordFieldConfig } from "./fields/linked-record/linked-record.config";
import { multipleSelectFieldConfig } from "./fields/multiple-select/multiple-select.config";
import { numberSlideFieldConfig } from "./fields/number-slider/number-slider.config";
import { numberFieldConfig } from "./fields/number/number.config";
import { percentCompleteFieldConfig } from "./fields/percent-complete/percent-complete.config";
import { percentFieldConfig } from "./fields/percent/percent.config";
import { phoneFieldConfig } from "./fields/phone/phone.config";
import { ratingFieldConfig } from "./fields/rating/rating.config";
import { recordTitleFieldConfig } from "./fields/record-title/record-title.config";
import { signatureFieldConfig } from "./fields/signature/signature.config";
import { singleSelectFieldConfig } from "./fields/single-select/single-select.config";
import { smartdocFieldConfig } from "./fields/smartdoc/smartdoc.config";
import { statusFieldConfig } from "./fields/status/status.config";
import { textFieldConfig } from "./fields/text/text.config";
import { textareaFieldConfig } from "./fields/textarea/textarea.config";
import { timeFieldConfig } from "./fields/time/time.config";
import { yesNoFieldConfig } from "./fields/yes-no/yes-no.config";
import { durationFieldConfig } from "./fields/duration/duration.config";
import { FieldRegistryConfig } from "./types";
import { ipAddressConfig } from "./fields/ip-address/ip-address.config";
import { colorPickerConfig } from "./fields/color-picker/color-picker.config";
import { tagFieldConfig } from "./fields/tags/tags.config";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fieldRegistryMap: Record<Fields, FieldRegistryConfig<any>> = {
  [Fields.addressfield]: addressFieldConfig,
  [Fields.currencyfield]: currencyFieldConfig,
  [Fields.datefield]: dateFieldConfig,
  [Fields.emailfield]: emailFieldConfig,
  [Fields.filefield]: fileFieldConfig,
  [Fields.fullnamefield]: fullNameFieldConfig,
  [Fields.linkedrecordfield]: linkedRecordFieldConfig,
  [Fields.linkfield]: linkFieldConfig,
  [Fields.multipleselectfield]: multipleSelectFieldConfig,
  [Fields.numberfield]: numberFieldConfig,
  [Fields.numbersliderfield]: numberSlideFieldConfig,
  [Fields.percentcompletefield]: percentCompleteFieldConfig,
  [Fields.percentfield]: percentFieldConfig,
  [Fields.phonefield]: phoneFieldConfig,
  [Fields.ratingfield]: ratingFieldConfig,
  [Fields.recordtitlefield]: recordTitleFieldConfig,
  [Fields.signaturefield]: signatureFieldConfig,
  [Fields.singleselectfield]: singleSelectFieldConfig,
  [Fields.smartdocfield]: smartdocFieldConfig,
  [Fields.statusfield]: statusFieldConfig,
  [Fields.textareafield]: textareaFieldConfig,
  [Fields.textfield]: textFieldConfig,
  [Fields.timefield]: timeFieldConfig,
  [Fields.userfield]: assignToFieldConfig,
  [Fields.yesnofield]: yesNoFieldConfig,
  [Fields.durationfield]: durationFieldConfig,
  [Fields.ipaddressfield]: ipAddressConfig,
  [Fields.colorpickerfield]: colorPickerConfig,
  [Fields.tagsfield]: tagFieldConfig,
};

export function getFieldRegistryConfig(field: Fields): FieldRegistryConfig<unknown> {
  return fieldRegistryMap[field];
}
