import { getUntranslatedCountryNameForCode } from "../../../utils/countriesList";
import { FieldRegistryConfig } from "../../types";
import { AddressFieldControl } from "./AddressFieldControl/AddressFieldControl";
import { addressFieldValueToSysRoot } from "./address.helpers";
import { AddressFieldValue, AddressFormValue } from "./address.types";

export const addressFieldConfig: FieldRegistryConfig<AddressFormValue, AddressFieldValue> = {
  icon: "address-field",
  control: AddressFieldControl,
  convertFormValueToApi: (value) => {
    const parsedValue = {
      ...value,
      location_country: getUntranslatedCountryNameForCode(value.location_country) ?? "",
    };

    return {
      ...parsedValue,
      sys_root: addressFieldValueToSysRoot(parsedValue),
      location_latitude: parsedValue?.location_latitude || "",
      location_longitude: parsedValue?.location_longitude || "",
    };
  },
};
