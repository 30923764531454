import { FormikValues } from "formik";
import { useCallback, useMemo } from "react";
import { cleanFilterRules, isConditionSatisfiedMemoized } from "../helpers/conditions";
import { FormConfigApplicationStructureItem, FormConfigItem, FormItemType } from "../types/form";
import { Filter, FilterField } from "@smartsuite/types";

export type FieldVisibilityCallback = (fieldSlug: string, visitedFields?: string[]) => boolean;

export interface UseFormFieldVisibilityResult {
  checkFieldVisibility: FieldVisibilityCallback;
}

export const useFormFieldVisibility = (
  formItems: FormConfigItem[],
  formValues: FormikValues,
  appFieldMap: Map<string, FormConfigApplicationStructureItem>
): UseFormFieldVisibilityResult => {
  // Array containing all form fields and sections (and fields within it) to calculate
  // their conditions.
  const formConditionalItems = useMemo(() => {
    const items: FormConfigItem[] = [];
    formItems?.forEach((item) => {
      if (item.type === FormItemType.section) {
        items.push(item);
        items.push(...(item.params.items ?? []).filter((i) => i.type !== FormItemType.section));
      } else {
        items.push(item);
      }
    });
    return items;
  }, [formItems]);

  // This map contains the valid conditions for each field slug
  const validConditionsMap = useMemo(
    () =>
      formConditionalItems.reduce<Record<string, Filter | null>>((acc, item) => {
        acc[item.slug] = null;
        const conditions = item.params?.conditions;

        // If the rule is disabled or empty, then it won't got to the map
        if (!conditions?.enabled || !conditions?.object) return acc;

        // If the rule has no valid entries, then it won't got to the map
        const validConditions = cleanFilterRules(conditions.object.fields);
        if (!validConditions.length) return acc;

        // Other rules are added to the map for the field slug
        acc[item.slug] = { ...conditions.object, fields: validConditions };
        return acc;
      }, {}),
    [formConditionalItems]
  );

  const checkFieldVisibility = useCallback(
    (fieldSlug: string, visitedFields: string[] = []): boolean => {
      const allItems = formItems.reduce<FormConfigItem[]>((acc, item) => {
        if (item.type === FormItemType.section) {
          return acc.concat(item.params.items ?? []);
        }
        return acc.concat(item);
      }, []);

      if (allItems.find((item) => item.slug === fieldSlug)?.params?.hidden) {
        return false;
      }

      // !! Warning !! Fields that are inside of sections will not consider the section's conditions
      // in its own visibility calculation. Instead, we're relying on the fact that the section
      // won't render because of unsatisfied conditions, thus causing the fields to not be
      // rendered as well. If there's a need to change this behavior, consider having a map of
      // section slugs to their conditions, and check if the field is inside a section, and if
      // so, check the section's conditions as well on the comparisonCallback.

      // Return false if field has already been visited to prevent infinite recursion
      if (visitedFields.includes(fieldSlug)) {
        return false;
      }

      // Puts the current field in the list to prevent infinite recursion
      visitedFields.push(fieldSlug);

      // If field has no valid conditions to be parsed, then it's visible by default
      const validConditions = validConditionsMap[fieldSlug];

      if (!validConditions) {
        return true;
      }

      // If operator is AND, ensure all valid filters are satisfied. If it's OR, ensure at
      // least one is satisfied. The visibility is calculated on both the valid conditions
      // for this field as well as the visibility of the fields it references.
      const comparisonCallback = (filterField: FilterField): boolean => {
        const fieldValue = formValues[filterField.field];
        const fieldDefinition = appFieldMap.get(filterField.field);

        return isConditionSatisfiedMemoized(filterField, fieldValue, fieldDefinition, false);
      };

      let finalVisibility = false;
      if (validConditions.operator === "and") {
        finalVisibility = validConditions.fields.every(comparisonCallback);
      } else {
        finalVisibility = validConditions.fields.some(comparisonCallback);
      }

      // Check visibility of referenced fields only if the current field's conditions are met
      if (finalVisibility) {
        finalVisibility = validConditions.fields.every((filterField) =>
          checkFieldVisibility(filterField.field, [...visitedFields])
        );
      }

      return finalVisibility;
    },
    [appFieldMap, formItems, formValues, validConditionsMap]
  );

  return {
    checkFieldVisibility,
  };
};
