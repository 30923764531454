import { getFieldLogic } from "@smartsuite/fields-logic/lib/field-type-registry/fields-logic-registry";
import debounce from "lodash.debounce";
import { FormConfigApplication, FormConfigItem, FormItemType, FormValues } from "../types/form";

// Set of helpers to store temporary progress of a user filling in a form, and then being
// able to restore that progress if they leave the page and come back to it.
// Includes logic to clean up the data stored in the local storage, to ensure that the data
// is valid for the form config.

/**
 * Returns the key to use for the local storage, unique for each form and account.
 * This ensures a user can hold progress of multiple different forms at the same time.
 * @param accountId The account ID from the URL
 * @param hash The form's hash from the URL
 * @returns The key to use for the local storage
 */
const getLocalStorageKey = (accountId: string, hash: string): string => {
  return `${accountId}_${hash}`;
};

export const clearCheckpoint = (accountId: string, hash: string): void => {
  localStorage.removeItem(getLocalStorageKey(accountId, hash));
};

const saveCheckpoint = (accountId: string, hash: string, values: FormValues): void => {
  localStorage.setItem(getLocalStorageKey(accountId, hash), JSON.stringify(values));
};

export const saveCheckpointDebounced = debounce(saveCheckpoint, 500);

export const loadCheckpoint = (
  accountId: string,
  hash: string,
  application: FormConfigApplication,
  formItems: FormConfigItem[]
): FormValues => {
  const formValues = localStorage.getItem(getLocalStorageKey(accountId, hash));
  if (formValues) {
    return cleanupCheckpointData(JSON.parse(formValues), application, formItems);
  }
  return {};
};

const cleanupCheckpointData = (
  formValues: FormValues,
  application: FormConfigApplication,
  formItems: FormConfigItem[]
): FormValues => {
  const formFieldsFlatList = formItems
    .filter((formItem) => [FormItemType.section, FormItemType.field].includes(formItem.type))
    .map((formItem) => {
      return formItem.type === FormItemType.section
        ? formItem.params.items
            ?.filter((item) => item.type === FormItemType.field)
            .map((item) => item)
        : [formItem];
    })
    .flat();
  const formItemsSlugMap = new Map(
    formFieldsFlatList.map((formItem) => [formItem?.slug, formItem])
  );
  const appFieldsSlugMap = new Map(
    application.structure.map((appField) => [appField.slug, appField])
  );

  const cleanedValues: FormValues = {};
  Object.keys(formValues).forEach((key) => {
    // Ignore all keys from the object that aren't in the form config
    if (!formItemsSlugMap.has(key)) return;

    // Grab the field logic for each of the fields by its type. if not available, ignore the field
    const storedFieldValue = formValues[key];
    const fieldType = appFieldsSlugMap.get(key)?.field_type;
    const fieldLogic = fieldType ? getFieldLogic(fieldType) : null;
    if (!fieldLogic) return;

    // Otherwise, allow the field data to be loaded
    cleanedValues[key] = storedFieldValue;
  });

  return cleanedValues;
};
