// Gathered from https://mathiasbynens.be/demo/url-regex from @diogoperini, with an extra

import { FieldValidator } from "formik";
import * as yup from "yup";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../../../types/form";
import { $t } from "../../../utils/intl";
import { applyYupDefaults, validateWithYup } from "../../validator";
import { validatorText } from "../../validator.text";

// '?' after the first // to make the protocol optional
export const URL_REGEX =
  /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/gimu;

export function validateByType(
  type: string | undefined,
  field: FormConfigApplicationStructureItem,
  formItem: FormConfigItem
): FieldValidator {
  return type === "number"
    ? validateWithYup(
        applyYupDefaults(
          yup.number().typeError($t(validatorText.validationNumber)),
          field,
          formItem
        )
      )
    : type === "email"
    ? validateWithYup(
        applyYupDefaults(yup.string().email($t(validatorText.validationEmail)), field, formItem)
      )
    : type === "url"
    ? validateWithYup(
        applyYupDefaults(
          yup.string().matches(URL_REGEX, $t(validatorText.validationUrl)),
          field,
          formItem
        )
      )
    : validateWithYup(applyYupDefaults(yup.string(), field, formItem));
}
