import React from "react";

import "./PageLayout.sass";
import { Scrollbar } from "@smartsuite/react-ui/lib";

export interface PageLayoutProps {
  children: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  return (
    <div className="page-layout">
      <Scrollbar
        classes="page-layout__scrollbar"
        contentProps={{ className: "page-layout__content" }}
        gutterRight={2}
        gutterTop={2}
        gutterBottom={2}
      >
        <div className="page-layout__wrapper">{children}</div>
      </Scrollbar>
    </div>
  );
};
