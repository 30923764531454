import React from "react";

import { $t } from "../../../utils/intl";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { TextFieldControl } from "../text/TextFieldControl/TextFieldControl";
import { MultipleLinkFieldControl } from "./MultipleLinkFieldControl/MultipleLinkFieldControl";
import { isArrayValue } from "@smartsuite/fields-logic/lib/safe-field-value/type-checking/arrays";

export type LinkFieldValue = string[];

export const linkFieldConfig: FieldRegistryConfig<LinkFieldValue, LinkFieldValue> = {
  icon: "link",
  control: (props) =>
    props.field.params?.entries_allowed === "multiple" ? (
      <MultipleLinkFieldControl placeholder={$t(fieldsText.linkPlaceholder)} {...props} />
    ) : (
      <TextFieldControl placeholder={$t(fieldsText.linkPlaceholder)} type="url" {...props} />
    ),
  convertFormValueToApi: (value) => (isArrayValue(value) ? value : [value]),
};
