export interface AddressFormValue {
  location_address: string;
  location_address2: string;
  location_city: string;
  location_state: string;
  location_zip: string;
  location_country: string;
  // Hidden in the UI, populated when selecting an autocomplete option
  location_longitude: string;
  location_latitude: string;
}

export interface AddressFieldValue extends AddressFormValue {
  sys_root: string;
}

export enum AddressFieldTypes {
  location_address = "location_address",
  location_address2 = "location_address2",
  location_city = "location_city",
  location_state = "location_state",
  location_zip = "location_zip",
  location_country = "location_country",
  location_latitude = "location_latitude",
  location_longitude = "location_longitude",
  sys_root = "sys_root",
}

export type ParseAddressComponents = Partial<{
  address: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}>;

export type AddressComponentsLevels =
  | "route"
  | "streetNumber"
  | "subpremise"
  | "locality"
  | "postalTown"
  | "admAreaLvl2"
  | "admAreaLvl1"
  | "postalCode"
  | "country";
