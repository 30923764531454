// add types as we support them
// this list should be in sync with the list of supported field types from main app
export enum Fields {
  addressfield = "addressfield",
  currencyfield = "currencyfield",
  datefield = "datefield",
  emailfield = "emailfield",
  filefield = "filefield",
  fullnamefield = "fullnamefield",
  linkedrecordfield = "linkedrecordfield",
  linkfield = "linkfield",
  multipleselectfield = "multipleselectfield",
  numberfield = "numberfield",
  numbersliderfield = "numbersliderfield",
  percentcompletefield = "percentcompletefield",
  percentfield = "percentfield",
  phonefield = "phonefield",
  ratingfield = "ratingfield",
  recordtitlefield = "recordtitlefield",
  signaturefield = "signaturefield",
  singleselectfield = "singleselectfield",
  smartdocfield = "richtextareafield",
  statusfield = "statusfield",
  textareafield = "textareafield",
  textfield = "textfield",
  timefield = "timefield",
  userfield = "userfield",
  yesnofield = "yesnofield",
  durationfield = "durationfield",
  ipaddressfield = "ipaddressfield",
  colorpickerfield = "colorpickerfield",
  tagsfield = "tagsfield",
}
