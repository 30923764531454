import { BasicOnOff } from "@smartsuite/react-ui/lib";
import { FormFieldControl } from "../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../hooks/useFormField";
import { $t } from "../../../utils/intl";
import { FormFieldProps } from "../../types";
import { yesNoFieldText } from "./YesNoFieldControl.text";

export const YesNoFieldControl: React.FunctionComponent<FormFieldProps> = ({
  name,
  label,
  required,
  caption,
}) => {
  const formField = useFormField<boolean>({
    name,
  });

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
    >
      <BasicOnOff
        size="l"
        isChecked={formField.value ?? false}
        onChange={formField.onChange}
        onText={$t(yesNoFieldText.yes)}
        offText={$t(yesNoFieldText.no)}
      />
    </FormFieldControl>
  );
};
