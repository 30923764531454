import { FC } from "react";
import {
  EmptyButton,
  ModalFooter,
  Placements,
  RoundButton,
  roundButtonStylesMedium,
  Tooltip,
} from "@smartsuite/react-ui/lib";
import { SelectModalSaveButtonTooltip } from "./components/SelectModalSaveButtonTooltip/SelectModalSaveButtonTooltip";
import { SelectLinkedRecordModalFooterText } from "./SelectLinkedRecordModalFooter.text";
import { $t } from "../../../../../../../../utils/intl";

interface SelectLinkedRecordModalFooterProps {
  selectedRecordsLength: number;
  onSave: () => void;
  onClose: () => void;
}

const placements: Placements = ["top"];

export const SelectLinkedRecordModalFooter: FC<SelectLinkedRecordModalFooterProps> = ({
  selectedRecordsLength,
  onSave,
  onClose,
}) => {
  return (
    <ModalFooter
      buttons={
        <>
          <EmptyButton
            className="select-linked-record-modal-view__cancel"
            size="lg"
            onClick={onClose}
          >
            {$t(SelectLinkedRecordModalFooterText.cancel)}
          </EmptyButton>

          <Tooltip
            disabled={!selectedRecordsLength}
            placements={placements}
            openingDelay={1000}
            renderTooltipContent={<SelectModalSaveButtonTooltip />}
            padding={2}
            target={
              <RoundButton
                ariaLabel="Save records"
                disabled={!selectedRecordsLength}
                styles={roundButtonStylesMedium}
                onClick={onSave}
              >
                {selectedRecordsLength
                  ? $t(SelectLinkedRecordModalFooterText.linkRecordsWithCount, {
                      count: selectedRecordsLength,
                    })
                  : $t(SelectLinkedRecordModalFooterText.linkRecords)}
              </RoundButton>
            }
          ></Tooltip>
        </>
      }
    />
  );
};
