/* eslint-disable n/handle-callback-err */
import { Paragraph } from "@smartsuite/react-ui/lib";
import React from "react";

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: unknown): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown): void {}

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (process.env.NODE_ENV === "development") {
        return <Paragraph color="error">Something went wrong here</Paragraph>;
      }

      return null;
    }

    return this.props.children;
  }
}
