import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

interface UseIsPreviewResult {
  isPreview: boolean;
}

export const useIsPreview = (): UseIsPreviewResult => {
  const [searchParams] = useSearchParams();

  const isPreview = useMemo(() => searchParams.has("isPreview"), [searchParams]);

  return {
    isPreview,
  };
};
