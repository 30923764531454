import { useFormikContext } from "formik";
import { useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";

export const FormikScrollToError: React.FC = () => {
  const { errors, isSubmitting, isValid } = useFormikContext();
  const prevIsSubmitting = usePrevious(isSubmitting);

  useEffect(() => {
    if (isValid || isSubmitting || !prevIsSubmitting) {
      return;
    }

    const fieldErrorNames = Object.keys(errors);
    if (fieldErrorNames.length <= 0) {
      return;
    }

    const element = document.querySelector(`[data-field-name='${fieldErrorNames[0]}']`);
    if (!element) {
      return;
    }

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isSubmitting, isValid, prevIsSubmitting, errors]);

  return null;
};
