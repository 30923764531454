import { languageToFnsLocale } from "@smartsuite/fields-logic/lib/utils/date";
import { addMinutes, format as formatDate, startOfDay } from "date-fns";

// First and second parts treat 12-hour format, third part treats 24-hour format
// Note the leading 0 in the hour is optional
const TIME_REGEX =
  /^\s*(((0?\d|1[0-2])([:.\s]?[0-5]\d){1,2}(\s+[apAP][mM])?)|(([1-9]|1[0-2])(\s*[apAP][mM]))|((0?\d|1\d|2[0-3])([:.\s]?[0-5]\d){1,2}))\s*$/;

// This regex is used to check if the time is in 12-hour format
const SHORT_TIME_H_REGEX = /^\s*(0?\d|1[0-2])(\s*[apAP][mM])\s*$/;
const SHORT_TIME_HM_REGEX = /^\s*(0?\d|1[0-2])([:.\s]?[0-5]\d)(\s*[apAP][mM])\s*$/;
const SHORT_TIME_HMS_REGEX = /^\s*(0?\d|1[0-2])([:.\s]?[0-5]\d)([:.\s]?[0-5]\d)(\s*[apAP][mM])\s*$/;

// This regex is used to parse 24-hour format times
const LONG_TIME_HMS_REGEX = /^\s*(0?\d|1\d|2[0-3])([:.\s]?[0-5]\d)([:.\s]?[0-5]\d)?\s*$/;

export const isValidTime = (value: string): boolean => {
  return TIME_REGEX.test(value);
};

export type TimeChoice = {
  value: number;
  label: string;
};

export function generateTimeChoices(
  minutesStep: number,
  format: string,
  locale: Locale
): TimeChoice[] {
  const minutesInADay = 24 * 60;
  const midnight = startOfDay(new Date());
  const choices: TimeChoice[] = [];
  for (let minutes = 0; minutes < minutesInADay; minutes += minutesStep) {
    const date = addMinutes(midnight, minutes);
    const label = formatDate(date, format, { locale });
    choices.push({ label, value: minutes });
  }
  return choices;
}

export function getTimeChoices(timeFormat: "12h" | "24h", minutesStep: number = 15): TimeChoice[] {
  const navigatorLanguage = navigator.language ?? "en";
  const fnsLanguage = languageToFnsLocale({
    label: navigatorLanguage,
    value: navigatorLanguage,
  });
  // 24h => HH:mm     12h => h:mm a
  return generateTimeChoices(minutesStep, timeFormat === "24h" ? "HH:mm" : "h:mm a", fnsLanguage);
}

const convert12hTimeTo24HourFormat = (time: string): string => {
  let hour;
  let minute;
  let second;
  let meridiem;
  const shortMatchH = time.match(SHORT_TIME_H_REGEX); // 3pm
  const shortMatchHm = time.match(SHORT_TIME_HM_REGEX); // 3:00 pm
  const shortMatchHms = time.match(SHORT_TIME_HMS_REGEX); // 3:00:00 pm

  // Time is not a in valid 12h format
  if (!shortMatchH && !shortMatchHm && !shortMatchHms) return time;

  // Extract the components depending on the match made
  if (shortMatchHms) {
    hour = shortMatchHms[1];
    minute = shortMatchHms[2];
    second = shortMatchHms[3];
    meridiem = shortMatchHms[4];
  } else if (shortMatchHm) {
    hour = shortMatchHm[1];
    minute = shortMatchHm[2];
    meridiem = shortMatchHm[3];
  } else if (shortMatchH) {
    hour = shortMatchH[1];
    meridiem = shortMatchH[2];
  }

  let meridiemParsed = meridiem;

  // Parses seconds
  let secondParsed = second ?? "";
  if (secondParsed.match(/am|pm/i)) {
    // In case the user enters something like 3pm, the regex would place the
    // 'meridiem' in the 'second', so we need to remove it from there and place it
    // in the 'meridiemParsed' instead.
    secondParsed = "";
    meridiemParsed = second;
  } else {
    secondParsed = secondParsed.replace(/[.\s]/g, "");
  }

  if (secondParsed && !secondParsed.includes(":")) {
    secondParsed = `:${secondParsed}`;
  }

  // Parses minutes
  let minuteParsed = minute ?? "00";
  minuteParsed = minuteParsed.replace(/[.\s]/g, "");
  if (minuteParsed && !minuteParsed?.includes(":")) {
    minuteParsed = `:${minuteParsed}`;
  }

  const hourInt = parseInt(hour ?? "0", 10);

  let meridiemShift = 0;
  if (meridiemParsed?.trim().toLowerCase() === "am" && hourInt === 12) {
    // If it was 12* am, we subtract 12 hours to make it start from 00:**
    meridiemShift = -12;
  } else if (meridiemParsed?.trim().toLowerCase() === "pm" && hourInt !== 12) {
    // Any time except 12* pm, we add 12 hours to make it start from 12:**
    meridiemShift = 12;
  }

  // Calculate the hours shift and prepare the new 24h-format time string
  const hourIntShifted = hourInt + meridiemShift;
  const hourParsed = hourIntShifted.toString().padStart(2, "0");
  return `${hourParsed}${minuteParsed}${secondParsed}`;
};

const cleanup24hTime = (time: string): string => {
  const longMatchHMS = time.match(LONG_TIME_HMS_REGEX); // 09:23:55
  if (!longMatchHMS) return time; // Time is not a in valid 24h format

  // Extract the components
  const hour = longMatchHMS[1];
  const minute = longMatchHMS[2];
  const second = longMatchHMS[3];

  // Parses seconds
  let secondParsed = second ?? "";
  secondParsed = secondParsed.replace(/[.\s]/g, "");
  if (secondParsed && !secondParsed.includes(":")) {
    secondParsed = `:${secondParsed}`;
  }

  // Parses minutes
  let minuteParsed = minute ?? "00";
  minuteParsed = minuteParsed.replace(/[.\s]/g, "");
  if (minuteParsed && !minuteParsed?.includes(":")) {
    minuteParsed = `:${minuteParsed}`;
  }

  // Parses hours
  const hourInt = parseInt(hour ?? "0", 10);
  const hourParsed = hourInt.toString().padStart(2, "0");
  return `${hourParsed}${minuteParsed}${secondParsed}`;
};

// Receives a time string, and converts it to 24h format if possible.
// If the time is invalid, returns the original string.
// If the time is already in 24h format (does not contain a meridiem), perform a cleanup and return.
// If the time is in 12h format, converts it to 24h format (HH:MM:SS, where seconds are only sent if received).
//
// This won't be used in the UI, so the user will see the time as they entered it.
// It is used instead in the comparisons for conditional rendering, as the reference values are
// always in 24h format, and when submitting the form, to always work with 24h times.
//
// NB: the comparison logic from fields-logic ignores the seconds in the time... We send them anyway.
export const convertTimeTo24HourFormat = (time: string): string => {
  if (!isValidTime(time)) return time;

  if (time.match(/(am|pm)/i)) {
    return convert12hTimeTo24HourFormat(time);
  } else {
    return cleanup24hTime(time);
  }
};
