import { FormConfigImageItem } from "../../types/form";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FC, useMemo } from "react";
import cx from "classnames";
import { createFilestackClient } from "../../config/filestack";
import { useFormContext } from "../../hooks/useFormContext";

interface ImageItemProps {
  item: FormConfigImageItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormImageItem: FC<ImageItemProps> = ({ item, checkFieldVisibility }) => {
  const { config } = useFormContext();

  const { imageUrl, fileStackImageHandle, maxHeight, alignment } = item.params;

  const filestack = useMemo(
    () => createFilestackClient(config.filestack_api_key, { security: config.file_security }),
    [config.file_security, config.filestack_api_key]
  );

  const uploadedImageUrl = fileStackImageHandle
    ? filestack.transform(fileStackImageHandle, {
        quality: { value: 80 },
        resize: {
          height: maxHeight ? maxHeight * 2 : undefined,
        },
      })
    : null;

  if (!imageUrl && !uploadedImageUrl) return null;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  const containerClassNames = cx("image-item", {
    "align-left": alignment === "left",
    "align-center": alignment === "center",
    "align-right": alignment === "right",
  });

  const imageClassNames = cx("image-item__image", {
    "align-cover": alignment === "cover",
  });

  return (
    <div data-testid="form-image-item" className={containerClassNames}>
      <img
        className={imageClassNames}
        style={{
          maxHeight: maxHeight ? `${maxHeight}px` : "auto",
        }}
        src={uploadedImageUrl ?? imageUrl}
        alt={`form-item-${item.slug}`}
      />
    </div>
  );
};
