import { Icon, Paragraph, Title, Tooltip } from "@smartsuite/react-ui/lib";
import React from "react";

import { $t } from "../../utils/intl";
import { ProgressRestoreText } from "./ProgressRestore.text";
import "./ProgressRestoreTooltip.sass";

const ProgressRestoreTooltipContent: React.FC = () => {
  return (
    <div className="progress-restore-tooltip-content">
      <Title size="xxs" weight="medium" color="reverse">
        {$t(ProgressRestoreText.autosaveTitle)}
      </Title>
      <Paragraph size="m" weight="regular" color="reverse">
        {$t(ProgressRestoreText.autosaveDescription)}
      </Paragraph>
      <Paragraph
        size="s"
        weight="regular"
        color="reverse"
        className="progress-restore-tooltip-content__note"
      >
        {$t(ProgressRestoreText.autosaveNote)}
      </Paragraph>
    </div>
  );
};

export const ProgressRestoreTooltip: React.FC = () => {
  return (
    <div className="progress-restore-tooltip">
      <Paragraph size="xs" weight="medium">
        {$t(ProgressRestoreText.tooltipLabel)}
      </Paragraph>
      <Tooltip
        padding={2}
        maxWidth={350}
        renderTooltipContent={<ProgressRestoreTooltipContent />}
        target={
          <div className="progress-restore-tooltip__icon">
            <Icon name="checkmark" size="M" />
          </div>
        }
      />
    </div>
    //
  );
};
