import { defineMessages } from "react-intl";

export const signatureText = defineMessages({
  placeholder: {
    defaultMessage: "Type here",
    id: "+/goVX",
  },
  drawMode: {
    defaultMessage: "Draw",
    id: "i1+AIw",
  },
  typeMode: {
    defaultMessage: "Type",
    id: "+U6ozc",
  },
  clear: {
    defaultMessage: "Clear",
    id: "/GCoTA",
  },
});
