import { defineMessages } from "react-intl";

export const validatorText = defineMessages({
  validationRequired: {
    defaultMessage: "This field is required",
    id: "TKmub+",
  },
  validationNumber: {
    defaultMessage: "Please enter a valid number",
    id: "6zUj8M",
  },
  validationNumberMinMax: {
    defaultMessage: "Value must be between {minValue} and {maxValue}",
    id: "kS9EPW",
  },
  validationDate: {
    defaultMessage: "Please enter a valid date",
    id: "6DCLcI",
  },
  validationEmail: {
    defaultMessage: "Please enter a valid e-mail",
    id: "WVDJpZ",
  },
  validationTime: {
    defaultMessage: "Please enter a valid time",
    id: "hN524h",
  },
  validationUrl: {
    defaultMessage: "Please enter a valid URL",
    id: "ajU+HT",
  },
  validationPhone: {
    defaultMessage: "Please enter a valid Phone Number",
    id: "iewZug",
  },
  validationMaxFiles: {
    defaultMessage: "You can only upload a maximum of {max} files",
    id: "97Rini",
  },
  validationIPAddress: {
    defaultMessage: "Please enter a valid IP address",
    id: "2U7Clm",
  },
});
