import React, { FC, useMemo } from "react";

import { FormFieldProps } from "../../../types";
import { useFormField } from "../../../../hooks/useFormField";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { CalendarPickerInputDropdown } from "@smartsuite/react-ui/lib";
import { DateFieldValue } from "../date.config";
import { enUS } from "date-fns/locale";

import "../date.sass";
import { format } from "date-fns";
import { validateWithYup } from "../../../validator";
import * as yup from "yup";
import { $t } from "../../../../utils/intl";
import { validatorText } from "../../../validator.text";

type DateFieldControlProps = FormFieldProps;

export const maxYearForSelectOffset = 50;
export const minYearForSelect = 1500;
export const maxYearForSelect = (): number => {
  return new Date().getFullYear() + maxYearForSelectOffset;
};

export const DateFieldControl: FC<DateFieldControlProps> = ({
  name,
  label,
  context,
  caption,
  required,
  formItem,
}) => {
  const shouldShowTimePicker = useMemo(() => formItem.params?.include_time, [formItem.params]);

  const formField = useFormField<DateFieldValue>({
    name,
    validate: validateWithYup(
      yup.object().when({
        is: () => formItem.required,
        then: (s) => s.required($t(validatorText.validationRequired)),
      })
    ),
  });

  const timeFormat = useMemo(
    () => context.config.form_state.time_format ?? "24h",
    [context.config.form_state.time_format]
  );

  const handleChange = (date: Date, includeTime: boolean): void => {
    formField.onChange({
      ...formField.value,
      include_time: includeTime,
      date: date ? date.toString() : null,
    });
  };

  const maxYear = useMemo(() => maxYearForSelect(), []);

  const formattedValue = useMemo(() => {
    if (!formField.value?.date) return "";

    const timeFormatPart = timeFormat === "12h" ? "hh:mm a" : "HH:mm";

    return formField.value.include_time
      ? format(new Date(formField.value?.date), `MMMM dd, yyyy, ${timeFormatPart} (zzzz)`)
      : format(new Date(formField.value?.date), "MMMM dd, yyyy");
  }, [formField.value, timeFormat]);

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
    >
      <CalendarPickerInputDropdown
        className="form-date-field"
        value={formField.value?.date ? new Date(formField.value?.date) : null}
        formattedValue={formattedValue}
        yearsOptionStart={minYearForSelect}
        yearsOptionEnd={maxYear}
        placeholder="Select Date"
        locale={enUS}
        isClearable
        showArrow={false}
        timeFormat={timeFormat}
        showTimePicker={shouldShowTimePicker}
        onDateChange={handleChange}
      />
    </FormFieldControl>
  );
};
