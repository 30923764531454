import { getFieldFromSlug } from "../helpers/structure";
import { Fields } from "../types/fields";
import { BaseRecord, FormConfig, FormValues } from "../types/form";
import { getFieldRegistryConfig } from "./map";

export function convertFormValueToApi<FormValue, FieldValue>(
  value: FormValue,
  fieldType: Fields
): FieldValue | undefined {
  const config = getFieldRegistryConfig(fieldType);

  if (!config?.convertFormValueToApi) {
    return value as unknown as FieldValue;
  }

  return config.convertFormValueToApi(value) as FieldValue;
}

/**
 * Converts a form values object to a valid record object expected by SmartSuite API.
 */
export function convertFormValuesToApi(values: FormValues, config: FormConfig): BaseRecord {
  return Object.entries(values).reduce((record, [slug, value]) => {
    if (value?.shouldSkip) {
      return { ...record };
    }
    const field = getFieldFromSlug(slug, config);

    return {
      ...record,
      [slug]: convertFormValueToApi(value, field.field_type),
    };
  }, {});
}
