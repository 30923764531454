import React, { FunctionComponent } from "react";

import "./SelectRecordPreloader.sass";

export const SelectRecordPreloader: FunctionComponent<React.PropsWithChildren> = () => {
  return (
    <div className="select-record-preloader">
      <svg
        id="eH3YkzWRNpc1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 740 400"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        width="740"
        height="400"
      >
        <g transform="translate(0-12.848957)" opacity="0.8">
          <rect
            width="740"
            height="84"
            rx="6"
            ry="6"
            transform="translate(0 39.377089)"
            fill="#fff"
            stroke="#f3f3f4"
            strokeWidth="2"
          />
          <rect
            id="eH3YkzWRNpc4"
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.204319 0 0 1 15 55.235518)"
            fill="#e9e9e9"
            strokeWidth="0"
          />
          <rect
            width="131.061027"
            height="20"
            rx="10"
            ry="10"
            transform="translate(225.144773 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="translate(15 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="74.226796"
            height="20"
            rx="10"
            ry="10"
            transform="translate(403.426439 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="117.526726"
            height="20"
            rx="10"
            ry="10"
            transform="translate(581.708107 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
        </g>
        <g transform="translate(0 84.62291)" opacity="0.6">
          <rect
            width="740"
            height="84"
            rx="6"
            ry="6"
            transform="translate(0 39.377089)"
            fill="#fff"
            stroke="#f3f3f4"
            strokeWidth="2"
          />
          <rect
            id="eH3YkzWRNpc11"
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.106198 0 0 1 15 55.235518)"
            fill="#e9e9e9"
            strokeWidth="0"
          />
          <rect
            width="131.061027"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.702492 0 0 1 225.144773 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.781568 0 0 1 15 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="74.226796"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.579497 0 0 1 403.426439 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="117.526726"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.821359 0 0 1 581.708107 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
        </g>
        <g transform="translate(0 180.048424)" opacity="0.4">
          <rect
            width="740"
            height="84"
            rx="6"
            ry="6"
            transform="translate(0 39.377089)"
            fill="#fff"
            stroke="#f3f3f4"
            strokeWidth="2"
          />
          <rect
            id="eH3YkzWRNpc18"
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.413957 0 0 1 15 55.235518)"
            fill="#e9e9e9"
            strokeWidth="0"
          />
          <rect
            width="131.061027"
            height="20"
            rx="10"
            ry="10"
            transform="translate(225.144773 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.106198 0 0 1 15 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="74.226796"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.302966 0 0 1 403.426439 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="117.526726"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.896382 0 0 1 581.708107 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
        </g>
        <g transform="translate(0 276.622908)" opacity="0.2">
          <rect
            width="740"
            height="84"
            rx="6"
            ry="6"
            transform="translate(0 39.377089)"
            fill="#fff"
            stroke="#f3f3f4"
            strokeWidth="2"
          />
          <rect
            id="eH3YkzWRNpc25"
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.958474 0 0 1 15 55.235518)"
            fill="#e9e9e9"
            strokeWidth="0"
          />
          <rect
            width="131.061027"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.10525 0 0 1 225.144773 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="140"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.781568 0 0 1 15 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="74.226796"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(1.497071 0 0 1 403.426439 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
          <rect
            width="117.526726"
            height="20"
            rx="10"
            ry="10"
            transform="matrix(.821359 0 0 1 581.708107 88.848957)"
            fill="#f3f3f4"
            strokeWidth="0"
          />
        </g>
        <rect
          width="140"
          height="13"
          rx="6"
          ry="6"
          transform="translate(15 0)"
          fill="#f3f3f4"
          strokeWidth="0"
        />
        <rect
          width="76"
          height="13"
          rx="6.5"
          ry="6.5"
          transform="translate(225.144773 0)"
          fill="#f3f3f4"
          strokeWidth="0"
        />
        <rect
          width="111.12276"
          height="13"
          rx="6.5"
          ry="6.5"
          transform="translate(403.426439 0)"
          fill="#f3f3f4"
          strokeWidth="0"
        />
        <rect
          width="70.1554"
          height="13"
          rx="6.5"
          ry="6.5"
          transform="translate(581.708107 0)"
          fill="#f3f3f4"
          strokeWidth="0"
        />
      </svg>
    </div>
  );
};
