import { KeyValueCustomSelect, Paragraph } from "@smartsuite/react-ui/lib";
import { useMemo, useRef } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { isMobilePhone } from "../../../../utils/devices";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import { fieldsText } from "../../fields.text";
import { SingleSelectFieldValue } from "../single-select.config";
import { FieldChoice } from "@smartsuite/types";

import "./SingleSelectFieldControl.sass";

export interface SingleSelectFieldControlProps extends FormFieldProps {
  choices?: FieldChoice[];
}

export const SingleSelectFieldControl: React.FunctionComponent<SingleSelectFieldControlProps> = ({
  field,
  formItem,
  name,
  label,
  required,
  choices,
  caption,
}) => {
  const dummyItemRef = useRef<HTMLSpanElement>(null);
  const formField = useFormField<SingleSelectFieldValue>({
    name,
    validate: validateWithYup(applyYupDefaults(yup.string(), field, formItem)),
  });

  // Options can be limited by configuration on the form builder.
  // If enabled, the choices passed in are filtered by the choices selected in the config.
  // If disabled, use either the choices passed by the config of this field type, or the
  // default choices of the actual app field.
  const options = useMemo(() => {
    const baseChoices: FieldChoice[] = choices ?? field.params?.choices ?? [];
    if (formItem.params?.selections?.enabled ?? false) {
      const selectionChoicesSet = new Set(formItem.params?.selections?.choiceIDs ?? []);
      return baseChoices.filter((choice) => selectionChoicesSet.has(choice.value as string)) ?? [];
    } else {
      return baseChoices;
    }
  }, [choices, field.params?.choices, formItem.params?.selections]);

  // When opening the dropdown, if the user is in a mobile device, try scrolling the dummy
  // item above the dropdown to the top of the page, so they can see options and input while
  // typing in. Selector component does not offer this behavior by default...
  const handleOpenChanged = (opened: boolean): void => {
    if (opened && isMobilePhone()) dummyItemRef.current?.scrollIntoView(true);
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
    >
      <span ref={dummyItemRef} />
      <KeyValueCustomSelect<"value", "label", FieldChoice>
        collision={isMobilePhone() ? "none" : "flipfit"}
        onChange={formField.onChange}
        onChangeFocused={formField.onChangeFocused}
        onOpenedChange={handleOpenChanged}
        options={options}
        placeholder={$t(fieldsText.singleSelectPlaceholder)}
        searchPlaceholder={$t(fieldsText.singleSelectSearchOptionPlaceholder)}
        renderOption={(option) => (
          <div className="single-select-field-control-option">
            <Paragraph weight="medium" wordBreak="break-word" color="currentColor">
              {option.label}
            </Paragraph>

            {option.value_help_text && (
              <Paragraph size="xs" color="currentColor">
                {option.value_help_text}
              </Paragraph>
            )}
          </div>
        )}
        showFilterGreaterThan={4}
        state={formField.state}
        value={formField.value}
      />
    </FormFieldControl>
  );
};
