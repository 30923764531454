import { KeyValueCustomMultipleSelect, KeyValueCustomSelect, Pill } from "@smartsuite/react-ui/lib";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as yup from "yup";
import { getAssignedToMembers } from "../../../../api";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormContext } from "../../../../hooks/useFormContext";
import { useFormField } from "../../../../hooks/useFormField";
import { Fields } from "../../../../types/fields";
import { Member } from "../../../../types/form";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { fieldsStateText, fieldsText } from "../../fields.text";
import { AssignToFormValue } from "../assign-to.config";
import { useIsPreview } from "../../../../hooks/useIsPreview";

export type AssignedToFieldControlProps = FormFieldProps;

export const AssignedToFieldControl: React.FunctionComponent<AssignedToFieldControlProps> = ({
  caption,
  field,
  formItem,
  label,
  name,
  required,
}) => {
  const { isPreview } = useIsPreview();

  const requiredText = $t(validatorText.validationRequired);
  const formField = useFormField<Member[]>({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.object())
        .when({
          is: () => formItem.required,
          then: (at) => at.required(requiredText).min(1, requiredText),
        })
    ),
  });

  const { accountId, config } = useFormContext();

  const [options, setOptions] = useState<AssignToFormValue[]>([]);
  const { data, isLoading } = useQuery(
    `${Fields.userfield}_${field.slug}`,
    async () => {
      if (accountId && config.sharing_hash && field.slug) {
        return await getAssignedToMembers(accountId, config.sharing_hash, field.slug, isPreview);
      }
    },
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    setOptions(
      (data ?? [])
        .sort((m1, m2) => m1.name.toLowerCase().localeCompare(m2.name.toLowerCase()))
        .map((member) => ({
          id: member.id,
          name: member.name,
          member,
        }))
    );
  }, [data]);

  // Supports single or multi select of record titles based on the properties of the
  // Assigned To field from the form
  const isMultiple = field.params?.entries_allowed === "multiple";

  const handleSelectChange = (changeValue: Member | Member[]): void => {
    if (changeValue) {
      if (Array.isArray(changeValue)) {
        formField.onChange(changeValue);
      } else {
        formField.onChange([changeValue]);
      }
    } else {
      formField.onChange([]);
    }
  };

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
    >
      {!isMultiple && (
        <KeyValueCustomSelect<"member", "name", AssignToFormValue>
          labelField="name"
          options={options}
          placeholder={
            isLoading ? $t(fieldsStateText.loading) : $t(fieldsText.singleSelectPlaceholder)
          }
          searchPlaceholder={$t(fieldsText.singleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value?.[0]}
          valueField="member"
          valueFieldPath="id"
          virtualized={true}
          itemHeight={36}
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}

      {isMultiple && (
        <KeyValueCustomMultipleSelect<"member", "name", AssignToFormValue>
          labelField="name"
          options={options}
          placeholder={
            isLoading ? $t(fieldsStateText.loading) : $t(fieldsText.multipleSelectPlaceholder)
          }
          renderSelectedOption={(option) => <Pill text={option.name} />}
          searchPlaceholder={$t(fieldsText.multipleSelectSearchOptionPlaceholder)}
          showFilterGreaterThan={4}
          state={formField.state}
          value={formField.value}
          valueField="member"
          valueFieldPath="id"
          virtualized={true}
          itemHeight={36}
          onChange={handleSelectChange}
          onChangeFocused={formField.onChangeFocused}
        />
      )}
    </FormFieldControl>
  );
};
