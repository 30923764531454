import {
  BasePluginsExtension,
  BaseSchemaExtension,
  BoldExtension,
  ColorExtension,
  FormattingToolbarExtension,
  HeadingExtension,
  HighlightExtension,
  ItalicExtension,
  ListExtension,
  PopupExtension,
  StrikethroughExtension,
  TrailingParagraphExtension,
  UnderlineExtension,
  UrlExtension,
} from "@smartsuite/smartdoc";
import { CalloutType } from "../../types/form";

export const editorExtensions = [
  new BaseSchemaExtension({
    enableParagraphAlignment: false,
  }),
  new HighlightExtension(),
  new ColorExtension(),
  new ListExtension(),
  new TrailingParagraphExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new UnderlineExtension(),
  new StrikethroughExtension(),
  new HeadingExtension(),
  new UrlExtension(),
  new PopupExtension(),
  new BasePluginsExtension(),
  new FormattingToolbarExtension({ mode: "floating" }),
];

export const calloutColors: Record<CalloutType, string> = {
  success: "#3EAC40",
  info: "#3A86FF",
  warning: "#FFB938",
  error: "#FF5757",
  note: "#883CD0",
  decision: "#6A849B",
};

interface ContainerColor {
  backgroundColor: string;
  borderColor: string;
}

export const calloutContentContainerColor: Record<CalloutType, ContainerColor> = {
  success: {
    backgroundColor: "#EEF6EE",
    borderColor: "#CDEECE",
  },
  info: {
    backgroundColor: "#E7F0FE",
    borderColor: "#B7E3FB",
  },
  warning: {
    backgroundColor: "#FFF2DC",
    borderColor: "#FFECB3",
  },
  error: {
    backgroundColor: "#FEECEE",
    borderColor: "#FEBDC7",
  },
  note: {
    backgroundColor: "#F2EAFA",
    borderColor: "#DFCCFF",
  },
  decision: {
    backgroundColor: "#F1F2F3",
    borderColor: "#D7D7DB",
  },
};
