import { Ref, useEffect, useImperativeHandle, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";

import "./SignatureFieldControl.sass";

export interface SignaturePadInputProps {
  actionsRef?: Ref<SignaturePadInputActionsRef>;
  onChange: (base64: string) => void;
  onBlur: () => void;
  onFocus: () => void;
}

export interface SignaturePadInputActionsRef {
  clear: () => void;
  setValue: (base64: string) => void;
}

export const SignaturePadInput: React.FunctionComponent<SignaturePadInputProps> = ({
  actionsRef,
  onChange,
  onBlur,
  onFocus,
}) => {
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>();

  useEffect(() => {
    if (!signaturePad) {
      return;
    }

    const handleBeginStroke = (): void => {
      onFocus();
    };

    const handleEndStroke = (): void => {
      onChange(signaturePad.toDataURL());

      setTimeout(() => {
        // needs to delay blur otherwise formik ends up in a messy state
        onBlur();
      });
    };

    signaturePad.instance.addEventListener("beginStroke", handleBeginStroke);
    signaturePad.instance.addEventListener("endStroke", handleEndStroke);

    return () => {
      signaturePad.instance.removeEventListener("beginStroke", handleBeginStroke);
      signaturePad.instance.removeEventListener("endStroke", handleEndStroke);
    };
  }, [signaturePad, onChange, onBlur, onFocus]);

  useImperativeHandle(actionsRef, () => ({
    clear: () => signaturePad?.clear(),
    setValue: (base64: string) => signaturePad?.fromDataURL(base64),
  }));

  return (
    <SignaturePad ref={setSignaturePad} height={64} options={{ backgroundColor: "transparent" }} />
  );
};
