import React, {
  CSSProperties,
  MutableRefObject,
  forwardRef,
  useEffect,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { useVirtualList } from "./hooks/useVirtualList";
import { ElementPropsWithElementRefAndRenderer } from "@smartsuite/react-ui/lib/libs/react-scrollbars-custom/types";
import { noop } from "rxjs";
import { getValueBetweenRange } from "@smartsuite/react-ui/lib/helpers/number";
import { Scrollbar } from "@smartsuite/react-ui/lib/components/utils/Scrollbar/Scrollbar";

export type SimpleVirtualScrollRef = {
  scrollToIndex: (index: number) => void;
};

export type ItemRendererComponent<TItem> = React.FunctionComponent<{
  item: TItem;
  index?: number;
  style?: CSSProperties;
}>;

export type SimpleListVirtualScrollProps<TItem> = {
  items: TItem[];
  className?: string;
  style?: CSSProperties;
  maxHeight?: number;
  barOffsetX?: number;
  gutterRight?: number;
  gutterBottom?: number;
  gutterTop?: number;
  noScrollX?: boolean;
  noScrollY?: boolean;
  additionalContentRender?: JSX.Element;
  containerClassName?: string;
  itemHeight: number | ((index: number) => number);
  contentProps?: ElementPropsWithElementRefAndRenderer;
  children: ItemRendererComponent<TItem>;
  onScrollerEl?: (el: HTMLElement) => void;
  onChangeHeight?: (height: number) => void;
};

function SimpleListVirtualScrollRaw<TItem>(
  props: SimpleListVirtualScrollProps<TItem>,
  ref?: MutableRefObject<SimpleVirtualScrollRef>
): React.ReactElement {
  const { onChangeHeight = noop } = props;
  const itemsWithIds = useMemo(
    () => props.items.map((item, index) => ({ id: index.toString(), data: item })),
    [props.items]
  );

  const { list, containerProps, containerSize, wrapperProps, scrollToPosition, scrollToIndex } =
    useVirtualList(itemsWithIds, {
      overscan: 1,
      itemSize: props.itemHeight,
    });

  const viewHeight = getValueBetweenRange(
    props.maxHeight ?? 0,
    containerSize.height ?? 0,
    wrapperProps.style.height
  );

  const height = props.maxHeight ? viewHeight : "";

  const handleScroll = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ scrollTop }: any) => {
      scrollToPosition(scrollTop);
    },
    [scrollToPosition]
  );

  useEffect(() => {
    if (wrapperProps?.style?.height) {
      onChangeHeight(wrapperProps.style.height);
    }
  }, [onChangeHeight, wrapperProps.style.height]);

  useImperativeHandle(ref, () => ({ scrollToIndex }), [scrollToIndex]);

  const ItemRenderer = props.children;

  return (
    <Scrollbar
      {...containerProps}
      style={{ ...props.style, height: `${height}px` }}
      classes={props.className}
      onScroll={handleScroll}
      contentProps={props.contentProps}
      gutterRight={props.gutterRight}
      gutterTop={props.gutterTop}
      gutterBottom={props.gutterBottom}
      barOffsetX={props.barOffsetX}
      noScrollX={props.noScrollX}
      noScrollY={props.noScrollY}
      scrollerProps={{
        elementRef(el: HTMLElement | null): void {
          if (props.onScrollerEl && el) props.onScrollerEl(el);
        },
      }}
    >
      {props.additionalContentRender}
      <div {...wrapperProps} className={props.containerClassName}>
        {list.map((item) => {
          return <ItemRenderer key={item.data.id} item={item.data.data} index={item.index} />;
        })}
      </div>
    </Scrollbar>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const SimpleListVirtualScroll = forwardRef(SimpleListVirtualScrollRaw) as <TItem>(
  props: SimpleListVirtualScrollProps<TItem> & { ref?: MutableRefObject<SimpleVirtualScrollRef> }
) => ReturnType<typeof SimpleListVirtualScrollRaw>;
