import { isDocEmpty } from "@smartsuite/smartdoc";
import { addressFieldValueToSysRoot } from "../registry/fields/address/address.helpers";
import { AddressFieldValue } from "../registry/fields/address/address.types";
import { AssignToFormValue } from "../registry/fields/assign-to/assign-to.config";
import {
  FullNameFieldValue,
  fullNameFieldValueToSysRoot,
} from "../registry/fields/full-name/full-name.config";
import { LinkedRecordFormValue } from "../registry/fields/linked-record/linked-record.config";
import {
  SignatureFieldValue,
  isSignatureEmpty,
} from "../registry/fields/signature/signature.config";
import { SmartdocFieldValue } from "../registry/fields/smartdoc/smartdoc.config";
import { convertTimeTo24HourFormat, isValidTime } from "../registry/fields/time/time.helpers";
import { FormConfigApplicationStructureItem } from "../types/form";
import { Comparison } from "@smartsuite/types";
import { IpAddressValue } from "../registry/fields/ip-address/ip-address.config";

const isNil = (value: unknown): boolean => value === null || value === undefined;
export const isBlank = (value: unknown): boolean => isNil(value) || value === "";
const isBoolean = (value: unknown): boolean => typeof value === "boolean";
const isNumber = (value: unknown): boolean => typeof value === "number" && !Number.isNaN(value);
const isString = (value: unknown): boolean => typeof value === "string";

/**
 * Performs additional data processing for forms values to make them comparable with the
 * comparer functions.
 * For example, some fields are rendered as dropdowns and the selected value is a string.
 * The rules provided might require the value to be a boolean, or an integer, which will
 * fail in comparison using the field-logic helpers. We then convert the values in these
 * scenarios to something usable.
 * @param formValue The value of the form field, stored in Formik
 * @param appField The App field that the condition is configured for, from the App structure
 * @returns a formatted version of the form value, ready to be compared with the field-logic
 * helpers if the field type requires additional treatment.
 */
export const prepareFormValueToComparer = (
  formValue: unknown,
  appField: FormConfigApplicationStructureItem,
  comparison: Comparison
): unknown => {
  switch (appField.field_type) {
    case "addressfield":
      return prepareAddressFormValueToComparer(formValue);
    case "currencyfield":
      return prepareNumericFormValueToComparer(formValue);
    case "emailfield":
      return prepareEmailFormValueToComparer(formValue);
    case "filefield":
      return prepareFileFormValueToComparer(formValue, comparison);
    case "fullnamefield":
      return prepareFullNameFormValueToComparer(formValue, appField);
    case "linkedrecordfield":
      return prepareLinkedRecordFormValueToComparer(formValue, comparison);
    case "linkfield":
      return prepareLinkFormValueToComparer(formValue);
    case "multipleselectfield":
      return prepareArrayFormValueToComparer(formValue);
    case "numberfield":
      return prepareNumericFormValueToComparer(formValue);
    case "numbersliderfield":
      return prepareNumericFormValueToComparer(formValue);
    case "percentfield":
      return prepareNumericFormValueToComparer(formValue);
    case "percentcompletefield":
      return prepareNumericFormValueToComparer(formValue);
    case "phonefield":
      return preparePhoneFormValueToComparer(formValue as string[]);
    case "ratingfield":
      return prepareNumericFormValueToComparer(formValue);
    case "recordtitlefield":
      return prepareTextValueToComparer(formValue);
    case "richtextareafield":
      return prepareSmartDocValueToComparer(formValue);
    case "signaturefield":
      return prepareSignatureValueToComparer(formValue);
    case "textfield":
      return prepareTextValueToComparer(formValue);
    case "textareafield":
      return prepareTextValueToComparer(formValue);
    case "timefield":
      return prepareTimeFormValueToComparer(formValue);
    case "userfield":
      return prepareAssignedToFormValueToComparer(formValue, comparison);
    case "yesnofield":
      return prepareYesNoFormValueToComparer(formValue);
    case "ipaddressfield":
      return prepareIPAddressFormValueToComparer(formValue);
    case "colorpickerfield":
      return prepareArrayFormValueToComparer(formValue);
    case "tagsfield":
      return prepareArrayFormValueToComparer(formValue);
    default:
      return formValue;
  }
};

/**
 * Base type conversion functions
 */
const prepareArrayFormValueToComparer = (formValue: unknown): unknown[] => {
  if (isNil(formValue)) return [];
  return formValue as unknown[];
};

const prepareNumericFormValueToComparer = (formValue: unknown): number | null => {
  if (isNumber(formValue)) return formValue as number;
  return null;
};

const prepareTextValueToComparer = (formValue: unknown): string => {
  if (isNil(formValue)) return "";
  return formValue as string;
};

/**
 * Custom conversion functions
 */

// Address fields are processed as strings, so we concatenate all available values
const prepareAddressFormValueToComparer = (formValue: unknown): string => {
  if (isNil(formValue)) return "";
  if (isString(formValue)) return formValue as string;
  return addressFieldValueToSysRoot(formValue as AddressFieldValue);
};

// Assigned To fields are evaluated differently depending on the comparison used.
// - "contains" and "does not contain" compare over the names of the users.
// - All other comparisons compare over the IDs of the users.
const prepareAssignedToFormValueToComparer = (
  formValue: unknown,
  comparison: Comparison
): unknown[] | string => {
  if (["contains", "not_contains"].includes(comparison)) {
    if (isNil(formValue)) return "";
    if (Array.isArray(formValue)) {
      return (formValue as AssignToFormValue[])
        .map((f) => f.name)
        .filter((f) => !!f)
        .join(",");
    }
    return formValue as string;
  } else {
    return prepareArrayFormValueToComparer((formValue as AssignToFormValue[])?.map((f) => f.id));
  }
};

const prepareEmailFormValueToComparer = (formValue: unknown): string[] => {
  // TODO: once email fields actually start storing the values as a list of strings, update this
  if (isBlank(formValue)) return [];
  if (isString(formValue)) return [formValue as string];
  return formValue as string[];
};

// Converts the files array into values depending on the comparison type.
// - "is empty" and "is not empty" compare over a string formed by uploaded file handles.
// - "file name contains" compares over a joined string formed by uploaded file names.
// - "file type is" compares over an array of strings of uploaded file types.
const prepareFileFormValueToComparer = (
  formValue: unknown,
  comparison: Comparison
): unknown[] | string => {
  if (["is_empty", "is_not_empty"].includes(comparison)) {
    if (isNil(formValue)) return "";
    if (Array.isArray(formValue)) {
      return formValue
        .map((file) => file?.handle)
        .filter((f) => !!f)
        .join("");
    }
    return formValue as string;
  } else if (comparison === "file_name_contains") {
    if (isNil(formValue)) return "";
    if (Array.isArray(formValue)) {
      return formValue
        .map((file) => file?.metadata?.filename)
        .filter((f) => !!f)
        .join(" ");
    }
    return formValue as string;
  } else if (comparison === "file_type_is") {
    if (isNil(formValue)) return [];
    if (Array.isArray(formValue)) {
      return formValue.map((file) => file?.file_type).filter((f) => !!f);
    }
  }
  return formValue as unknown[];
};

const prepareFullNameFormValueToComparer = (
  formValue: unknown,
  appField: FormConfigApplicationStructureItem
): string => {
  if (isNil(formValue)) return "";
  if (isString(formValue)) return formValue as string;
  return fullNameFieldValueToSysRoot(formValue as FullNameFieldValue, appField.params.choices);
};

// Converts the linked records array into values depending on the comparison type.
// - "contains" and "not contains" compare over a string formed by record IDs.
//   - TODO: Update this to compare over the record labels. Tricky because only the actual
//     LR field fetches the values for the dropdown, and we cannot pass them in here to
//     map their IDs to labels.
// - All the rest compares over an array of strings of record IDs.
const prepareLinkedRecordFormValueToComparer = (
  formValue: unknown,
  comparison: Comparison
): unknown[] | string => {
  if (["contains", "not_contains"].includes(comparison)) {
    if (isNil(formValue)) return "";
    if (Array.isArray(formValue)) {
      return (formValue as LinkedRecordFormValue[])
        .map((f) => f.title)
        .filter((f) => !!f)
        .join(",");
    }
    return formValue as string;
  } else {
    return prepareArrayFormValueToComparer(
      (formValue as LinkedRecordFormValue[])?.map((f) => f.id)
    );
  }
};

const prepareLinkFormValueToComparer = (formValue: unknown): string[] => {
  if (isBlank(formValue)) return [];
  if (isString(formValue)) return [formValue as string];
  return formValue as string[];
};

const preparePhoneFormValueToComparer = (formValue: string[]): string[] => {
  if (!formValue) return [];
  return formValue.filter((f) => !!f && isString(f)).map((f) => f.replace(/\D+/, ""));
};

const prepareSmartDocValueToComparer = (formValue: unknown): string | null => {
  if (isNil(formValue) || isDocEmpty((formValue as SmartdocFieldValue).data)) return null;
  return formValue as string;
};

const prepareSignatureValueToComparer = (formValue: unknown): SignatureFieldValue | null => {
  if (isNil(formValue)) return null;
  if (isSignatureEmpty(formValue as SignatureFieldValue)) return null;
  return formValue as SignatureFieldValue;
};

const prepareTimeFormValueToComparer = (formValue: unknown): string | null => {
  if (isNil(formValue)) return null;
  if (!isValidTime(formValue as string)) return null;
  return convertTimeTo24HourFormat(formValue as string);
};

const prepareYesNoFormValueToComparer = (formValue: unknown): boolean | null => {
  if (isBoolean(formValue)) return formValue as boolean;
  return null;
};

const prepareIPAddressFormValueToComparer = (formValue: unknown): string[] => {
  return (formValue as IpAddressValue)?.map((value) => value.address) || [];
};
