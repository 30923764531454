import { Colors, TextColorNames } from "@smartsuite/types";
import { PillStyles } from "@smartsuite/react-ui/lib/components/widgets/Pill/Pill";

export const linkedRecordPillStyles: PillStyles = {
  borderDefault: Colors.linkedRecordFieldColor,
  backgroundDefault: Colors.linkedRecordFieldColor,
  backgroundHover: Colors.linkedRecordFieldHoverColor,
  borderHover: Colors.linkedRecordFieldHoverColor,
  removeButtonColor: TextColorNames.secondary,
  removeButtonColorHover: TextColorNames.primary,
  borderRadius: 5,
};
