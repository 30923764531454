import { FC } from "react";
import { SimpleRecord } from "@smartsuite/types";
import classNames from "classnames";

import "./SocialNetworkField.sass";

interface SocialNetworkFieldProps {
  record: SimpleRecord;
  fieldSlug: string;
}

export const SocialNetworkField: FC<SocialNetworkFieldProps> = ({ record, fieldSlug }) => {
  const facebookUserName = record[fieldSlug]?.facebook_username;
  const instagramUserName = record[fieldSlug]?.instagram_username;
  const linkedinUserName = record[fieldSlug]?.linkedin_username;
  const twitterUserName = record[fieldSlug]?.twitter_username;

  const facebookClasses = classNames("social-network-field__image", {
    "social-network-field__image--disabled": !facebookUserName,
  });

  const instagramClasses = classNames("social-network-field__image", {
    "social-network-field__image--disabled": !instagramUserName,
  });

  const linkedinClasses = classNames("social-network-field__image", {
    "social-network-field__image--disabled": !linkedinUserName,
  });

  const twitterClasses = classNames("social-network-field__image", {
    "social-network-field__image--disabled": !twitterUserName,
  });

  return (
    <div className="social-network-field">
      <img className={facebookClasses} src="/images/facebook.svg" alt="Facebook" />
      <img className={instagramClasses} src="/images/instagram.svg" alt="Instagram" />
      <img className={linkedinClasses} src="/images/linkedin.svg" alt="LinkedIn" />
      <img className={twitterClasses} src="/images/twitter.svg" alt="Twitter" />
    </div>
  );
};
