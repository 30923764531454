import { FC, useMemo } from "react";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FormConfigCalloutItem } from "../../types/form";
import { Icon } from "@smartsuite/react-ui/lib";
import { calloutTypeIcons } from "@smartsuite/smartdoc/lib/cjs/extensions/callout/constants";
import { Doc, Editor } from "@smartsuite/smartdoc";
import {
  calloutContentContainerColor,
  editorExtensions,
  calloutColors,
} from "./FormCalloutItem.constants";

interface FormCalloutItemProps {
  item: FormConfigCalloutItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormCalloutItem: FC<FormCalloutItemProps> = ({ item, checkFieldVisibility }) => {
  const { calloutType, doc } = item.params;

  const parsedDoc: Doc = useMemo(() => (doc ? JSON.parse(doc) : null), [doc]);

  const isEmpty = useMemo(
    () => (parsedDoc ? !parsedDoc.content.find((content) => !!content.content) : null),
    [parsedDoc]
  );

  if (!!isEmpty || !calloutType) return null;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  return (
    <div
      data-testid="form-callout-item"
      className="form-callout-item"
      style={{
        backgroundColor: calloutContentContainerColor[calloutType].backgroundColor,
        borderColor: calloutContentContainerColor[calloutType].borderColor,
      }}
    >
      <Icon
        name={calloutTypeIcons[calloutType]}
        color={calloutColors[calloutType]}
        dimension={{ height: 16, width: 16 }}
      />
      <Editor initialDoc={parsedDoc} extensions={editorExtensions} editable={false} />
    </div>
  );
};
