import { FieldRegistryConfig } from "../../types";
import { type SmartdocDocument } from "./SmartdocFieldControl/Smartdoc";
import { SmartdocFieldControl } from "./SmartdocFieldControl/SmartdocFieldControl";

export interface SmartdocFieldValue {
  data: SmartdocDocument;
}

export const smartdocFieldConfig: FieldRegistryConfig<SmartdocFieldValue> = {
  icon: "smartdoc",
  control: SmartdocFieldControl,
};
