import { isEnterKey, Modal, ModalOverlay, NoResults } from "@smartsuite/react-ui/lib";
import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import cx from "classnames";
import { useKeyboardNavigation } from "@smartsuite/react-ui/lib/hooks/useKeyboardNavigation/useKeyboardNavigation";
import { useEventListener } from "@smartsuite/react-ui/lib/helpers/useEventListener";
import { onKeyDownData } from "@smartsuite/react-ui/lib/hooks/useKeyboardNavigation/types";
import { AppField, ID, SimpleRecord } from "@smartsuite/types";

import { SelectLinkedRecordModalHeader } from "./components/SelectLinkedRecordModalHeader/SelectLinkedRecordModalHeader";
import { SelectLinkedRecordModalFooter } from "./components/SelectLinkedRecordModalFooter/SelectLinkedRecordModalFooter";

import { useFormContext } from "../../../../../../hooks/useFormContext";
import { Fields } from "../../../../../../types/fields";
import { getRelatedRecords } from "../../../../../../api";
import { UseFormFieldResult } from "../../../../../../hooks/useFormField";
import { $t } from "../../../../../../utils/intl";
import { SelectLinkedRecordModalText } from "./SelectLinkedRecordModal.text";
import { SelectRecordPreloader } from "./components/SelectRecordPreloader/SelectRecordPreloader";
import { RecordList } from "./components/RecordList/RecordList";
import { isMacOs } from "../../../../../../utils/devices";
import { useLinkedRecords } from "../../hooks/useLinkedRecords";
import { getSortedRecords } from "../../linked-records.helpers";
import {
  FormConfigApplicationStructureItem,
  FormConfigItem,
  LinkedRecordObject,
} from "../../../../../../types/form";

import "./SelectLinkedRecordModal.sass";

interface SelectLinkedRecordModalProps {
  onSave: (selectedRecords: LinkedRecordObject[]) => void;
  field: FormConfigApplicationStructureItem;
  formField: UseFormFieldResult<LinkedRecordObject[]>;
  formItem: FormConfigItem;
  onClose: () => void;
  showFooter?: boolean;
  alreadySelectedRecords?: LinkedRecordObject[];
}

export const SelectLinkedRecordModal: FC<SelectLinkedRecordModalProps> = ({
  field,
  formItem,
  formField,
  onClose,
  onSave,
  showFooter,
  alreadySelectedRecords,
}) => {
  const recordListRef = useRef<HTMLDivElement>(null);

  const formContext = useFormContext();
  const { values } = useFormikContext();

  const [selectedRecords, setSelectedRecords] = useState<ID[]>([]);
  const [options, setOptions] = useState<SimpleRecord[]>([]);

  const classes = cx("select-linked-record-modal", {
    "no-footer": !showFooter,
  });

  const {
    isMultiple,
    isPreview,
    linkedApplicationFields,
    records,
    isRecordsLoading,
    linkedAppStructureLoading,
    searchValue,
    setSearchValue,
  } = useLinkedRecords({
    field,
    formItem,
    formField,
  });

  const visibleFields =
    formItem.params.visible_fields ??
    field.params?.select_record_modal_visible_fields ??
    field.params?.visible_fields ??
    [];

  const { data: relatedRecords, isLoading: relatedRecordsLoading } = useQuery(
    `${Fields.linkedrecordfield}_${field.slug}_${field.params?.linked_application}_records_with_related`,
    async () => {
      if (
        formContext.accountId &&
        formContext.config.sharing_hash &&
        field.params?.linked_application
      ) {
        return await getRelatedRecords({
          accountId: formContext.accountId,
          linkedAppId: field.params.linked_application,
          hash: formContext.config.sharing_hash,
          isPreview,
          visibleFields,
        });
      }
    },
    {
      staleTime: Infinity,
    }
  );

  const fieldsToShow = visibleFields.reduce(
    (acc: { [key: string]: AppField }, fieldSlug: string) => {
      const field = linkedApplicationFields?.[fieldSlug];

      if (field?.slug === "title") return acc;

      if (field) {
        acc[fieldSlug] = field;
      }

      if (fieldSlug.includes(".")) {
        // Nested field
        const mainFieldSlug = fieldSlug.split(".")[0];
        const mainField = linkedApplicationFields?.[mainFieldSlug];

        if (mainField) {
          acc[fieldSlug] = mainField;
        }
      }

      return acc;
    },
    {}
  );

  useEffect(() => {
    const unselectedRecords =
      records?.filter(
        (record) =>
          !alreadySelectedRecords?.find((selectedRecord) => selectedRecord.id === record.id)
      ) ?? [];

    const filteredRecords = unselectedRecords
      .sort((lr1, lr2) => lr1.title.toLowerCase().localeCompare(lr2.title.toLowerCase()))
      .filter((option) => {
        if (!searchValue) {
          return true;
        }

        return option.title.toLowerCase().includes(searchValue.toLowerCase());
      });

    const sortedRecords = getSortedRecords({
      records: filteredRecords,
      formItem,
      field,
      linkedApplicationFields,
    });

    setOptions(sortedRecords);
  }, [
    records,
    field,
    formItem,
    formField.value,
    formContext.config.application.structure,
    formContext.config.form_state.items,
    isMultiple,
    values,
    searchValue,
    alreadySelectedRecords,
    linkedApplicationFields,
  ]);

  const handleSave = useCallback(
    (selectedRecords: ID[]): void => {
      const selectedRecordsData = selectedRecords.reduce((acc: LinkedRecordObject[], record) => {
        const recordData: SimpleRecord | undefined = records?.find((r) => r.id === record);

        if (recordData) {
          return [
            ...acc,
            {
              id: recordData.id.toString(),
              title: recordData.title,
            },
          ];
        }

        return acc;
      }, []);

      onSave(
        isMultiple
          ? [...(alreadySelectedRecords ?? []), ...selectedRecordsData]
          : selectedRecordsData
      );

      onClose();
    },
    [alreadySelectedRecords, isMultiple, onClose, onSave, records]
  );

  const handleRecordClick = useCallback(
    (recordId: ID): void => {
      const newSelectedRecords = selectedRecords.includes(recordId)
        ? selectedRecords.filter((id) => id !== recordId)
        : [...selectedRecords, recordId];

      setSelectedRecords(newSelectedRecords);

      if (!isMultiple) {
        handleSave(newSelectedRecords);
      }
    },
    [handleSave, isMultiple, selectedRecords]
  );

  const loading = isRecordsLoading || linkedAppStructureLoading || relatedRecordsLoading;

  const handleSaveKeyPress = useCallback(
    (event: Event) => {
      const keyboardEvent = event as KeyboardEvent;
      if (isMacOs() && keyboardEvent.metaKey) {
        if (keyboardEvent.key === "s" || keyboardEvent.code === "KeyS") {
          event.preventDefault();
          event.stopPropagation();
          handleSave(selectedRecords);
          return;
        }
      }
      if (keyboardEvent.ctrlKey && (keyboardEvent.key === "s" || keyboardEvent.code === "KeyS")) {
        event.preventDefault();
        event.stopPropagation();
        handleSave(selectedRecords);
      }
    },
    [handleSave, selectedRecords]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent, data: onKeyDownData<SimpleRecord>) => {
      if (isEnterKey(event)) {
        handleRecordClick(data.item.id);
      }
    },
    [handleRecordClick]
  );

  const { cursor, focusedItem } = useKeyboardNavigation<SimpleRecord>({
    ref: recordListRef as MutableRefObject<HTMLDivElement | undefined>,
    items: options,
    enableTabNavigation: true,
    onKeyDown: handleKeyDown,
  });

  useEventListener(document.body, "keydown", handleSaveKeyPress, !!selectedRecords?.length);

  return (
    <ModalOverlay active backdrop onClose={onClose}>
      <Modal
        ariaLabel="Select linked records modal"
        className={classes}
        withoutHat
        size="L"
        ref={recordListRef}
        hatHeight={0}
        header={
          <SelectLinkedRecordModalHeader
            searchValue={searchValue}
            onChangeSearchValue={setSearchValue}
            onClose={onClose}
          />
        }
        footer={
          <SelectLinkedRecordModalFooter
            selectedRecordsLength={selectedRecords.length}
            onClose={onClose}
            onSave={() => handleSave(selectedRecords)}
          />
        }
      >
        {!loading && !options?.length && (
          <NoResults
            className="select-linked-record-modal__no-found"
            img="/images/no-search-results.svg"
            imgGutter={false}
            title={$t(SelectLinkedRecordModalText.searchPlaceholder)}
          />
        )}
        {loading && <SelectRecordPreloader />}

        {!loading && options?.length > 0 && (
          <RecordList
            fieldsToShow={fieldsToShow}
            linkedAppFields={linkedApplicationFields}
            records={options}
            selectedRecords={selectedRecords}
            onRecordClick={handleRecordClick}
            relatedRecords={relatedRecords}
            focusedItem={focusedItem}
            cursor={cursor}
          />
        )}
      </Modal>
    </ModalOverlay>
  );
};
