import { SharedFile } from "../../../types/file";
import { FieldRegistryConfig } from "../../types";
import { FileFieldControl } from "./FileFieldControl/FileFieldControl";

export type FileFieldValue = SharedFile[];

export const fileFieldConfig: FieldRegistryConfig<FileFieldValue> = {
  icon: "attachment",
  control: FileFieldControl,
};
