import { createIntl, createIntlCache } from "react-intl";

import messagesBG from "../locale/compiled/bg.json";
import messagesDE from "../locale/compiled/de.json";
import messagesEN from "../locale/compiled/en.json";
import messagesES from "../locale/compiled/es-mx.json";
import messagesFR from "../locale/compiled/fr.json";
import messagesHI from "../locale/compiled/hi.json";
import messagesIT from "../locale/compiled/it.json";
import messagesJA from "../locale/compiled/ja.json";
import messagesNL from "../locale/compiled/nl.json";
import messagesPL from "../locale/compiled/pl.json";
import messagesPT from "../locale/compiled/pt-br.json";
import messagesRO from "../locale/compiled/ro.json";
import messagesRU from "../locale/compiled/ru.json";
import messagesSV from "../locale/compiled/sv.json";
import messagesUK from "../locale/compiled/uk.json";
import messagesZH from "../locale/compiled/zh-Hans.json";

// Returns 'en' language by default
export const getMessagesForLocale = (
  locale: string
): { locale: string; messages: Record<string, string> } => {
  const languageCode = locale.toLowerCase().split("-")[0]; // e.g.: 'en-US' to 'en'
  switch (languageCode) {
    case "bg":
      return { locale: "bg", messages: messagesBG };
    case "de":
      return { locale: "de", messages: messagesDE };
    case "es":
      return { locale: "es", messages: messagesES };
    case "fr":
      return { locale: "fr", messages: messagesFR };
    case "hi":
      return { locale: "hi", messages: messagesHI };
    case "it":
      return { locale: "it", messages: messagesIT };
    case "ja":
      return { locale: "ja", messages: messagesJA };
    case "nl":
      return { locale: "nl", messages: messagesNL };
    case "pl":
      return { locale: "pl", messages: messagesPL };
    case "pt":
      return { locale: "pt", messages: messagesPT };
    case "ro":
      return { locale: "ro", messages: messagesRO };
    case "ru":
      return { locale: "ru", messages: messagesRU };
    case "sv":
      return { locale: "sv", messages: messagesSV };
    case "uk":
      return { locale: "uk", messages: messagesUK };
    case "zh":
      return { locale: "zh", messages: messagesZH };
    default:
      return { locale: "en", messages: messagesEN };
  }
};

const userLocale = navigator.language ?? "en";
const cache = createIntlCache();
const messages = getMessagesForLocale(userLocale).messages;
export const intl = createIntl(
  {
    locale: userLocale,
    defaultLocale: "en",
    messages,
  },
  cache
);

// This intentionally only exposes the formatMessage method, although intl
// provides many other methods and APIs to use. Consider using intl.<method>
// if you need to use something else, or adding another variable as an alias.
// eslint-disable-next-line @typescript-eslint/unbound-method
export const $t = intl.$t;
