import { FieldChoice } from "../../../types/form";
import { FieldRegistryConfig } from "../../types";
import { FullNameFieldControl } from "./FullNameFieldControl/FullNameFieldControl";

export interface FullNameFieldValue {
  first_name: string;
  last_name: string;
  middle_name: string;
  title: string;
}

export const fullNameFieldValueToSysRoot = (
  value: FullNameFieldValue,
  fieldChoices?: FieldChoice[]
): string => {
  const titleLabel = fieldChoices?.find((choice) => choice.value === value?.title)?.label;
  return [titleLabel, value?.first_name, value?.middle_name, value?.last_name]
    .filter((part) => !!part)
    .join(" ");
};

export const fullNameFieldConfig: FieldRegistryConfig<FullNameFieldValue> = {
  icon: "full-name",
  control: FullNameFieldControl,
};
