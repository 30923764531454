import * as filestack from "filestack-js";

/**
 * Create a filestack client with environment variables and default configuration plus additional given options.
 */
export function createFilestackClient(
  apiKey: string | undefined,
  options: filestack.ClientOptions = {}
): filestack.Client {
  const cname = process.env.REACT_APP_FILESTACK_CNAME;
  const key = apiKey ?? process.env.REACT_APP_FILESTACK_API_KEY ?? "";

  if (!cname) {
    throw new Error("REACT_APP_FILESTACK_CNAME environment variable is not set");
  }

  return filestack.init(key, { cname, ...options });
}

export const filestackMaxSize = 5 * 1024 * 1024 * 1024; // 5GB

export const AnyFile: string[] | undefined = undefined; // filestack simply doesn't validate file types by default
