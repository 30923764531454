import { CountryCode, E164Number } from "libphonenumber-js/types";
import { FieldRegistryConfig } from "../../types";
import { PhoneFieldControlSingle } from "./PhoneFieldControlSingle/PhoneFieldControlSingle";
import { parsePhoneNumber } from "react-phone-number-input";
import { PhoneFieldControlMultiple } from "./PhoneFieldControlMultiple/PhoneFieldControlMultiple";

export type PhoneFormValue = E164Number[];

export interface PhoneNumber {
  sys_root: string;
  sys_title: string;
  phone_country: CountryCode | undefined;
  phone_extension: string;
  phone_type: number;
  phone_number: string;
}

export type PhoneFieldValue = PhoneNumber[];

function parsePhoneModel(phoneNumbers: E164Number[]): PhoneFieldValue {
  if (!phoneNumbers) return [];

  return phoneNumbers.reduce<PhoneFieldValue>((acc, phoneNumber) => {
    const phoneObj = parsePhoneNumber(phoneNumber);
    if (!phoneObj) return acc;

    const { country, countryCallingCode, nationalNumber } = { ...phoneObj };

    return [
      ...acc,
      {
        phone_country: country,
        sys_title: `+${countryCallingCode} ${phoneObj.formatNational()}`,
        phone_extension: "",
        phone_type: 1, // TODO: check phone type in the future
        sys_root: `${countryCallingCode}${nationalNumber}`,
        phone_number: nationalNumber,
      },
    ];
  }, []);
}

export const phoneFieldConfig: FieldRegistryConfig<PhoneFormValue, PhoneFieldValue> = {
  icon: "phone",
  control: (props) =>
    props.field.params.entries_allowed === "single" ? (
      <PhoneFieldControlSingle {...props} />
    ) : (
      <PhoneFieldControlMultiple {...props} />
    ),
  convertFormValueToApi: parsePhoneModel,
};
