import { defineMessages } from "react-intl";
import { $t } from "./intl";

export const ListOfCountries = defineMessages({
  Afghanistan: { defaultMessage: "Afghanistan", id: "uWSAC7" },
  AlandIslands: { defaultMessage: "Åland Islands", id: "CINaAR" },
  Albania: { defaultMessage: "Albania", id: "90h7/c" },
  Algeria: { defaultMessage: "Algeria", id: "xrCy9R" },
  AmericanSamoa: { defaultMessage: "American Samoa", id: "1xokhb" },
  Andorra: { defaultMessage: "Andorra", id: "WU4UUT" },
  Angola: { defaultMessage: "Angola", id: "sX2X1Y" },
  Anguilla: { defaultMessage: "Anguilla", id: "XRFmnD" },
  Antarctica: { defaultMessage: "Antarctica", id: "1vXyK5" },
  AntiguaAndBarbuda: { defaultMessage: "Antigua & Barbuda", id: "cogFRy" },
  Argentina: { defaultMessage: "Argentina", id: "wHkOq4" },
  Armenia: { defaultMessage: "Armenia", id: "5LRsH1" },
  Aruba: { defaultMessage: "Aruba", id: "Y42Bah" },
  AscensionIsland: { defaultMessage: "Ascension Island", id: "6idjD5" },
  Australia: { defaultMessage: "Australia", id: "D4A2LZ" },
  Austria: { defaultMessage: "Austria", id: "ZwXv1C" },
  Azerbaijan: { defaultMessage: "Azerbaijan", id: "NDmBum" },
  Bahamas: { defaultMessage: "Bahamas", id: "Vur4Pc" },
  Bahrain: { defaultMessage: "Bahrain", id: "/5cdjt" },
  Bangladesh: { defaultMessage: "Bangladesh", id: "gEWjsM" },
  Barbados: { defaultMessage: "Barbados", id: "IC39KF" },
  Belarus: { defaultMessage: "Belarus", id: "ZwfOoA" },
  Belgium: { defaultMessage: "Belgium", id: "o5InVy" },
  Belize: { defaultMessage: "Belize", id: "dtDgwz" },
  Benin: { defaultMessage: "Benin", id: "inFU+b" },
  Bermuda: { defaultMessage: "Bermuda", id: "lNpHJU" },
  Bhutan: { defaultMessage: "Bhutan", id: "MK2kPw" },
  Bolivia: { defaultMessage: "Bolivia", id: "6ZgBbw" },
  BosniaAndHerzegovina: { defaultMessage: "Bosnia & Herzegovina", id: "Cq9xXx" },
  Botswana: { defaultMessage: "Botswana", id: "+hgjwh" },
  Brazil: { defaultMessage: "Brazil", id: "zJ+Qdi" },
  BritishIndianOceanTerritory: { defaultMessage: "British Indian Ocean Territory", id: "Jo8O9K" },
  BritishVirginIslands: { defaultMessage: "British Virgin Islands", id: "39y4D4" },
  Brunei: { defaultMessage: "Brunei", id: "NYfs4P" },
  Bulgaria: { defaultMessage: "Bulgaria", id: "uLjfHx" },
  BurkinaFaso: { defaultMessage: "Burkina Faso", id: "1ROxNo" },
  Burundi: { defaultMessage: "Burundi", id: "o+2sFT" },
  Cambodia: { defaultMessage: "Cambodia", id: "cB8Agh" },
  Cameroon: { defaultMessage: "Cameroon", id: "ECeXar" },
  Canada: { defaultMessage: "Canada", id: "2abiy0" },
  CanaryIslands: { defaultMessage: "Canary Islands", id: "wlrvF/" },
  CapeVerde: { defaultMessage: "Cape Verde", id: "UQHsPm" },
  CaribbeanNetherlands: { defaultMessage: "Caribbean Netherlands", id: "jNX/Ev" },
  CaymanIslands: { defaultMessage: "Cayman Islands", id: "80nWOt" },
  CentralAfricanRepublic: { defaultMessage: "Central African Republic", id: "MjNTEu" },
  CeutaAndMelilla: { defaultMessage: "Ceuta & Melilla", id: "uoApk4" },
  Chad: { defaultMessage: "Chad", id: "6AXoVq" },
  Chile: { defaultMessage: "Chile", id: "QtvgOo" },
  China: { defaultMessage: "China", id: "1q/ois" },
  ChristmasIsland: { defaultMessage: "Christmas Island", id: "4mW6ME" },
  CocosKeelingIslands: { defaultMessage: "Cocos (Keeling) Islands", id: "JcQK6/" },
  Colombia: { defaultMessage: "Colombia", id: "PZh7w+" },
  Comoros: { defaultMessage: "Comoros", id: "zB+1uJ" },
  CongoBrazzaville: { defaultMessage: "Congo - Brazzaville", id: "0TKMsL" },
  CongoKinshasa: { defaultMessage: "Congo - Kinshasa", id: "6hmPbS" },
  CookIslands: { defaultMessage: "Cook Islands", id: "MQbqy9" },
  CostaRica: { defaultMessage: "Costa Rica", id: "HmnV8f" },
  CoteDIvoire: { defaultMessage: "Côte d’Ivoire", id: "glmrbz" },
  Croatia: { defaultMessage: "Croatia", id: "Evxi/h" },
  Cuba: { defaultMessage: "Cuba", id: "6qOZYA" },
  Curacao: { defaultMessage: "Curaçao", id: "gQ9NjL" },
  Cyprus: { defaultMessage: "Cyprus", id: "f51mrK" },
  Czechia: { defaultMessage: "Czechia", id: "b3+qKo" },
  Denmark: { defaultMessage: "Denmark", id: "OjT4Gq" },
  DiegoGarcia: { defaultMessage: "Diego Garcia", id: "eWc4vV" },
  Djibouti: { defaultMessage: "Djibouti", id: "iGb1sS" },
  Dominica: { defaultMessage: "Dominica", id: "oQCGsZ" },
  DominicanRepublic: { defaultMessage: "Dominican Republic", id: "XM2eiF" },
  Ecuador: { defaultMessage: "Ecuador", id: "TkvM0N" },
  Egypt: { defaultMessage: "Egypt", id: "BIR9MS" },
  ElSalvador: { defaultMessage: "El Salvador", id: "lAqxac" },
  EquatorialGuinea: { defaultMessage: "Equatorial Guinea", id: "A0QuW0" },
  Eritrea: { defaultMessage: "Eritrea", id: "4+tITf" },
  Estonia: { defaultMessage: "Estonia", id: "oM/Jlx" },
  Eswatini: { defaultMessage: "Eswatini", id: "2aTh1C" },
  Ethiopia: { defaultMessage: "Ethiopia", id: "HkNqjk" },
  FalklandIslands: { defaultMessage: "Falkland Islands", id: "Jm15g7" },
  FaroeIslands: { defaultMessage: "Faroe Islands", id: "aZxVvE" },
  Fiji: { defaultMessage: "Fiji", id: "zSzTfC" },
  Finland: { defaultMessage: "Finland", id: "OIc/xw" },
  France: { defaultMessage: "France", id: "9KADbj" },
  FrenchGuiana: { defaultMessage: "French Guiana", id: "5FAe7C" },
  FrenchPolynesia: { defaultMessage: "French Polynesia", id: "vzHYL7" },
  FrenchSouthernTerritories: { defaultMessage: "French Southern Territories", id: "ZPneo2" },
  Gabon: { defaultMessage: "Gabon", id: "elJBhI" },
  Gambia: { defaultMessage: "Gambia", id: "58SIKu" },
  Georgia: { defaultMessage: "Georgia", id: "C/46Vz" },
  Germany: { defaultMessage: "Germany", id: "PGzyr2" },
  Ghana: { defaultMessage: "Ghana", id: "PJLOZK" },
  Gibraltar: { defaultMessage: "Gibraltar", id: "Yn6mMH" },
  Greece: { defaultMessage: "Greece", id: "yil/vB" },
  Greenland: { defaultMessage: "Greenland", id: "sroDBL" },
  Grenada: { defaultMessage: "Grenada", id: "Wyr8z1" },
  Guadeloupe: { defaultMessage: "Guadeloupe", id: "g12swr" },
  Guam: { defaultMessage: "Guam", id: "ec+8jt" },
  Guatemala: { defaultMessage: "Guatemala", id: "fApUje" },
  Guernsey: { defaultMessage: "Guernsey", id: "Fohnyv" },
  Guinea: { defaultMessage: "Guinea", id: "rbZX5h" },
  GuineaBissau: { defaultMessage: "Guinea-Bissau", id: "hz2KSv" },
  Guyana: { defaultMessage: "Guyana", id: "y19xMJ" },
  Haiti: { defaultMessage: "Haiti", id: "TTeL00" },
  Honduras: { defaultMessage: "Honduras", id: "gI1AIL" },
  HongKongSARChina: { defaultMessage: "Hong Kong SAR China", id: "eepWZy" },
  Hungary: { defaultMessage: "Hungary", id: "T6CwRX" },
  Iceland: { defaultMessage: "Iceland", id: "Yr+/el" },
  India: { defaultMessage: "India", id: "i5dhvD" },
  Indonesia: { defaultMessage: "Indonesia", id: "vQOevT" },
  Iran: { defaultMessage: "Iran, Islamic Republic Of", id: "S3tsO1" },
  Iraq: { defaultMessage: "Iraq", id: "qAiQn0" },
  Ireland: { defaultMessage: "Ireland", id: "fGxzwb" },
  IsleOfMan: { defaultMessage: "Isle of Man", id: "ub/Qvb" },
  Israel: { defaultMessage: "Israel", id: "vwXIZQ" },
  Italy: { defaultMessage: "Italy", id: "VzQxqn" },
  Jamaica: { defaultMessage: "Jamaica", id: "QQFsPa" },
  Japan: { defaultMessage: "Japan", id: "3Rh+pv" },
  Jersey: { defaultMessage: "Jersey", id: "QEpb4w" },
  Jordan: { defaultMessage: "Jordan", id: "BoKhrr" },
  Kazakhstan: { defaultMessage: "Kazakhstan", id: "VMm7cd" },
  Kenya: { defaultMessage: "Kenya", id: "6307RX" },
  Kiribati: { defaultMessage: "Kiribati", id: "rkGcmk" },
  Kosovo: { defaultMessage: "Kosovo", id: "tHXDZ5" },
  Kuwait: { defaultMessage: "Kuwait", id: "g6e3dg" },
  Kyrgyzstan: { defaultMessage: "Kyrgyzstan", id: "+Im81x" },
  Laos: { defaultMessage: "Laos", id: "FpzDjr" },
  Latvia: { defaultMessage: "Latvia", id: "mNdVd9" },
  Lebanon: { defaultMessage: "Lebanon", id: "tgKpK7" },
  Lesotho: { defaultMessage: "Lesotho", id: "4kjIFD" },
  Liberia: { defaultMessage: "Liberia", id: "ikBdCN" },
  Libya: { defaultMessage: "Libya", id: "p55qbX" },
  Liechtenstein: { defaultMessage: "Liechtenstein", id: "7HIoTU" },
  Lithuania: { defaultMessage: "Lithuania", id: "pFuHho" },
  Luxembourg: { defaultMessage: "Luxembourg", id: "LhhU6X" },
  MacaoSARChina: { defaultMessage: "Macao SAR China", id: "5SOqHt" },
  Madagascar: { defaultMessage: "Madagascar", id: "aNjcfm" },
  Malawi: { defaultMessage: "Malawi", id: "/hi87u" },
  Malaysia: { defaultMessage: "Malaysia", id: "b7zond" },
  Maldives: { defaultMessage: "Maldives", id: "Q11y5F" },
  Mali: { defaultMessage: "Mali", id: "I73NDr" },
  Malta: { defaultMessage: "Malta", id: "k8wgb4" },
  MarshallIslands: { defaultMessage: "Marshall Islands", id: "iVENNr" },
  Martinique: { defaultMessage: "Martinique", id: "Bq2nwA" },
  Mauritania: { defaultMessage: "Mauritania", id: "sXmy5o" },
  Mauritius: { defaultMessage: "Mauritius", id: "XBetJ4" },
  Mayotte: { defaultMessage: "Mayotte", id: "6SzqQV" },
  Mexico: { defaultMessage: "Mexico", id: "QXG+La" },
  Micronesia: { defaultMessage: "Micronesia, Federated States of", id: "VggUVc" },
  Moldova: { defaultMessage: "Moldova, Republic of", id: "WeJDO6" },
  Monaco: { defaultMessage: "Monaco", id: "qXzX3N" },
  Mongolia: { defaultMessage: "Mongolia", id: "sxNj0/" },
  Montenegro: { defaultMessage: "Montenegro", id: "nxY39E" },
  Montserrat: { defaultMessage: "Montserrat", id: "MKz1Tv" },
  Morocco: { defaultMessage: "Morocco", id: "s8wxd4" },
  Mozambique: { defaultMessage: "Mozambique", id: "A8elG+" },
  MyanmarBurma: { defaultMessage: "Myanmar (Burma)", id: "HVZIiq" },
  Namibia: { defaultMessage: "Namibia", id: "DYK0ve" },
  Nauru: { defaultMessage: "Nauru", id: "4tH4ib" },
  Nepal: { defaultMessage: "Nepal", id: "tGuLQJ" },
  Netherlands: { defaultMessage: "Netherlands", id: "hT/s2i" },
  NewCaledonia: { defaultMessage: "New Caledonia", id: "HNQOND" },
  NewZealand: { defaultMessage: "New Zealand", id: "tcWR8m" },
  Nicaragua: { defaultMessage: "Nicaragua", id: "IeYjFw" },
  Niger: { defaultMessage: "Niger", id: "uXZM4v" },
  Nigeria: { defaultMessage: "Nigeria", id: "uKVTE3" },
  Niue: { defaultMessage: "Niue", id: "cPyO3E" },
  NorfolkIsland: { defaultMessage: "Norfolk Island", id: "81kGin" },
  NorthernMarianaIslands: { defaultMessage: "Northern Mariana Islands", id: "krCZKf" },
  NorthKorea: { defaultMessage: "North Korea", id: "ZvV7k0" },
  NorthMacedonia: { defaultMessage: "North Macedonia", id: "ovJt9C" },
  Norway: { defaultMessage: "Norway", id: "mdq/wu" },
  Oman: { defaultMessage: "Oman", id: "uWzT/K" },
  Pakistan: { defaultMessage: "Pakistan", id: "u5SBAU" },
  Palau: { defaultMessage: "Palau", id: "7Yy0+b" },
  PalestinianTerritories: { defaultMessage: "Palestinian Territories", id: "lKT6Eq" },
  Panama: { defaultMessage: "Panama", id: "+c9FIk" },
  PapuaNewGuinea: { defaultMessage: "Papua New Guinea", id: "/0qKds" },
  Paraguay: { defaultMessage: "Paraguay", id: "mNZnPy" },
  Peru: { defaultMessage: "Peru", id: "5+dpGU" },
  Philippines: { defaultMessage: "Philippines", id: "RRQ7fk" },
  PitcairnIslands: { defaultMessage: "Pitcairn Islands", id: "3Zn/g3" },
  Poland: { defaultMessage: "Poland", id: "SZjU9E" },
  Portugal: { defaultMessage: "Portugal", id: "67jBS8" },
  PseudoAccents: { defaultMessage: "Pseudo-Accents", id: "VfeDA5" },
  PseudoBidi: { defaultMessage: "Pseudo-Bidi", id: "MASrm5" },
  PuertoRico: { defaultMessage: "Puerto Rico", id: "52kdGd" },
  Qatar: { defaultMessage: "Qatar", id: "331fBb" },
  Reunion: { defaultMessage: "Reunion", id: "30Mg5/" },
  Romania: { defaultMessage: "Romania", id: "Jnm5JX" },
  Russia: { defaultMessage: "Russia", id: "OhkbDU" },
  Rwanda: { defaultMessage: "Rwanda", id: "Co/5iq" },
  Samoa: { defaultMessage: "Samoa", id: "tvE5JV" },
  SanMarino: { defaultMessage: "San Marino", id: "YaQUSr" },
  SaoTomeAndPrincipe: { defaultMessage: "São Tomé & Príncipe", id: "ELRSyQ" },
  SaudiArabia: { defaultMessage: "Saudi Arabia", id: "cbMqh0" },
  Senegal: { defaultMessage: "Senegal", id: "E902E8" },
  Serbia: { defaultMessage: "Serbia", id: "fV65BM" },
  Seychelles: { defaultMessage: "Seychelles", id: "NAHlOT" },
  SierraLeone: { defaultMessage: "Sierra Leone", id: "mhcNvv" },
  Singapore: { defaultMessage: "Singapore", id: "rhaGlx" },
  SintMaarten: { defaultMessage: "Sint Maarten", id: "eZzIn6" },
  Slovakia: { defaultMessage: "Slovakia", id: "kkyKXY" },
  Slovenia: { defaultMessage: "Slovenia", id: "LDo7Px" },
  SolomonIslands: { defaultMessage: "Solomon Islands", id: "EfncpL" },
  Somalia: { defaultMessage: "Somalia", id: "NqFp7f" },
  SouthAfrica: { defaultMessage: "South Africa", id: "XR8z3v" },
  SouthGeorgiaAndSouthSandwichIslands: {
    defaultMessage: "South Georgia & South Sandwich Islands",
    id: "x0q+G6",
  },
  SouthKorea: { defaultMessage: "South Korea", id: "tnrxhT" },
  SouthSudan: { defaultMessage: "South Sudan", id: "ZOWwOS" },
  Spain: { defaultMessage: "Spain", id: "GL9Msi" },
  SriLanka: { defaultMessage: "Sri Lanka", id: "tm3FzH" },
  StBarthelemy: { defaultMessage: "St. Barthélemy", id: "hEEbaL" },
  StHelena: { defaultMessage: "St. Helena", id: "yFQtsV" },
  StKittsAndNevis: { defaultMessage: "St. Kitts & Nevis", id: "5GTLeH" },
  StLucia: { defaultMessage: "St. Lucia", id: "e9Evw1" },
  StMartin: { defaultMessage: "St. Martin", id: "tG7cGj" },
  StPierreAndMiquelon: { defaultMessage: "St. Pierre & Miquelon", id: "ruijBk" },
  StVincentAndGrenadines: { defaultMessage: "St. Vincent & Grenadines", id: "tCz2W6" },
  Sudan: { defaultMessage: "Sudan", id: "VYWEFA" },
  Suriname: { defaultMessage: "Suriname", id: "PHVDJ8" },
  SvalbardAndJanMayen: { defaultMessage: "Svalbard & Jan Mayen", id: "HczxEa" },
  Sweden: { defaultMessage: "Sweden", id: "cOtCHW" },
  Switzerland: { defaultMessage: "Switzerland", id: "ERkh0f" },
  Syria: { defaultMessage: "Syria", id: "G5CvJf" },
  Taiwan: { defaultMessage: "Taiwan", id: "K283wu" },
  Tajikistan: { defaultMessage: "Tajikistan", id: "2DrFi/" },
  Tanzania: { defaultMessage: "Tanzania, United Republic of", id: "u4ZK8I" },
  Thailand: { defaultMessage: "Thailand", id: "dQZuWX" },
  TimorLeste: { defaultMessage: "Timor-Leste", id: "zEvuLO" },
  Togo: { defaultMessage: "Togo", id: "DIFKwk" },
  Tokelau: { defaultMessage: "Tokelau", id: "WELugi" },
  Tonga: { defaultMessage: "Tonga", id: "xqD0n2" },
  TrinidadAndTobago: { defaultMessage: "Trinidad & Tobago", id: "dynaTo" },
  TristanDaCunha: { defaultMessage: "Tristan da Cunha", id: "vZTF7o" },
  Tunisia: { defaultMessage: "Tunisia", id: "d8Z26u" },
  Turkey: { defaultMessage: "Turkey", id: "k/pu+/" },
  Turkmenistan: { defaultMessage: "Turkmenistan", id: "884qo6" },
  TurksAndCaicosIslands: { defaultMessage: "Turks & Caicos Islands", id: "7DZuAW" },
  Tuvalu: { defaultMessage: "Tuvalu", id: "5Zsb8t" },
  Uganda: { defaultMessage: "Uganda", id: "6TiqPJ" },
  Ukraine: { defaultMessage: "Ukraine", id: "3T2iOg" },
  UnitedArabEmirates: { defaultMessage: "United Arab Emirates", id: "eMHa+3" },
  UnitedKingdom: { defaultMessage: "United Kingdom", id: "SKtwiU" },
  UnitedStates: { defaultMessage: "United States", id: "GZDSC0" },
  Uruguay: { defaultMessage: "Uruguay", id: "EbbVcC" },
  USOutlyingIslands: { defaultMessage: "U.S. Outlying Islands", id: "xo8j+B" },
  USVirginIslands: { defaultMessage: "U.S. Virgin Islands", id: "VWAeFy" },
  Uzbekistan: { defaultMessage: "Uzbekistan", id: "IOia3K" },
  Vanuatu: { defaultMessage: "Vanuatu", id: "/9BpZC" },
  VaticanCity: { defaultMessage: "Vatican City", id: "NlBzML" },
  Venezuela: { defaultMessage: "Venezuela", id: "ZkjVLX" },
  Vietnam: { defaultMessage: "Vietnam", id: "+ieU8R" },
  WallisAndFutuna: { defaultMessage: "Wallis & Futuna", id: "lDKkIu" },
  WesternSahara: { defaultMessage: "Western Sahara", id: "XEAUVf" },
  Yemen: { defaultMessage: "Yemen", id: "EFE6ZV" },
  Zambia: { defaultMessage: "Zambia", id: "t50FYQ" },
  Zimbabwe: { defaultMessage: "Zimbabwe", id: "gaBIUj" },
});

export const countryBaseNameCodeMap: Record<
  string,
  { label: { defaultMessage: string; id: string }; code: string }
> = {
  Afghanistan: { label: ListOfCountries.Afghanistan, code: "AF" },
  AlandIslands: { label: ListOfCountries.AlandIslands, code: "AX" },
  Albania: { label: ListOfCountries.Albania, code: "AL" },
  Algeria: { label: ListOfCountries.Algeria, code: "DZ" },
  AmericanSamoa: { label: ListOfCountries.AmericanSamoa, code: "AS" },
  Andorra: { label: ListOfCountries.Andorra, code: "AD" },
  Angola: { label: ListOfCountries.Angola, code: "AO" },
  Anguilla: { label: ListOfCountries.Anguilla, code: "AI" },
  Antarctica: { label: ListOfCountries.Antarctica, code: "AQ" },
  AntiguaAndBarbuda: { label: ListOfCountries.AntiguaAndBarbuda, code: "AG" },
  Argentina: { label: ListOfCountries.Argentina, code: "AR" },
  Armenia: { label: ListOfCountries.Armenia, code: "AM" },
  Aruba: { label: ListOfCountries.Aruba, code: "AW" },
  AscensionIsland: { label: ListOfCountries.AscensionIsland, code: "AC" },
  Australia: { label: ListOfCountries.Australia, code: "AU" },
  Austria: { label: ListOfCountries.Austria, code: "AT" },
  Azerbaijan: { label: ListOfCountries.Azerbaijan, code: "AZ" },
  Bahamas: { label: ListOfCountries.Bahamas, code: "BS" },
  Bahrain: { label: ListOfCountries.Bahrain, code: "BH" },
  Bangladesh: { label: ListOfCountries.Bangladesh, code: "BD" },
  Barbados: { label: ListOfCountries.Barbados, code: "BB" },
  Belarus: { label: ListOfCountries.Belarus, code: "BY" },
  Belgium: { label: ListOfCountries.Belgium, code: "BE" },
  Belize: { label: ListOfCountries.Belize, code: "BZ" },
  Benin: { label: ListOfCountries.Benin, code: "BJ" },
  Bermuda: { label: ListOfCountries.Bermuda, code: "BM" },
  Bhutan: { label: ListOfCountries.Bhutan, code: "BT" },
  Bolivia: { label: ListOfCountries.Bolivia, code: "BO" },
  BosniaAndHerzegovina: { label: ListOfCountries.BosniaAndHerzegovina, code: "BA" },
  Botswana: { label: ListOfCountries.Botswana, code: "BW" },
  Brazil: { label: ListOfCountries.Brazil, code: "BR" },
  BritishIndianOceanTerritory: { label: ListOfCountries.BritishIndianOceanTerritory, code: "IO" },
  BritishVirginIslands: { label: ListOfCountries.BritishVirginIslands, code: "VG" },
  Brunei: { label: ListOfCountries.Brunei, code: "BN" },
  Bulgaria: { label: ListOfCountries.Bulgaria, code: "BG" },
  BurkinaFaso: { label: ListOfCountries.BurkinaFaso, code: "BF" },
  Burundi: { label: ListOfCountries.Burundi, code: "BI" },
  Cambodia: { label: ListOfCountries.Cambodia, code: "KH" },
  Cameroon: { label: ListOfCountries.Cameroon, code: "CM" },
  Canada: { label: ListOfCountries.Canada, code: "CA" },
  CanaryIslands: { label: ListOfCountries.CanaryIslands, code: "IC" },
  CapeVerde: { label: ListOfCountries.CapeVerde, code: "CV" },
  CaribbeanNetherlands: { label: ListOfCountries.CaribbeanNetherlands, code: "BQ" },
  CaymanIslands: { label: ListOfCountries.CaymanIslands, code: "KY" },
  CentralAfricanRepublic: { label: ListOfCountries.CentralAfricanRepublic, code: "CF" },
  CeutaAndMelilla: { label: ListOfCountries.CeutaAndMelilla, code: "EA" },
  Chad: { label: ListOfCountries.Chad, code: "TD" },
  Chile: { label: ListOfCountries.Chile, code: "CL" },
  China: { label: ListOfCountries.China, code: "CN" },
  ChristmasIsland: { label: ListOfCountries.ChristmasIsland, code: "CX" },
  CocosKeelingIslands: { label: ListOfCountries.CocosKeelingIslands, code: "CC" },
  Colombia: { label: ListOfCountries.Colombia, code: "CO" },
  Comoros: { label: ListOfCountries.Comoros, code: "KM" },
  CongoBrazzaville: { label: ListOfCountries.CongoBrazzaville, code: "CG" },
  CongoKinshasa: { label: ListOfCountries.CongoKinshasa, code: "CD" },
  CookIslands: { label: ListOfCountries.CookIslands, code: "CK" },
  CostaRica: { label: ListOfCountries.CostaRica, code: "CR" },
  CoteDIvoire: { label: ListOfCountries.CoteDIvoire, code: "CI" },
  Croatia: { label: ListOfCountries.Croatia, code: "HR" },
  Cuba: { label: ListOfCountries.Cuba, code: "CU" },
  Curacao: { label: ListOfCountries.Curacao, code: "CW" },
  Cyprus: { label: ListOfCountries.Cyprus, code: "CY" },
  Czechia: { label: ListOfCountries.Czechia, code: "CZ" },
  Denmark: { label: ListOfCountries.Denmark, code: "DK" },
  DiegoGarcia: { label: ListOfCountries.DiegoGarcia, code: "DG" },
  Djibouti: { label: ListOfCountries.Djibouti, code: "DJ" },
  Dominica: { label: ListOfCountries.Dominica, code: "DM" },
  DominicanRepublic: { label: ListOfCountries.DominicanRepublic, code: "DO" },
  Ecuador: { label: ListOfCountries.Ecuador, code: "EC" },
  Egypt: { label: ListOfCountries.Egypt, code: "EG" },
  ElSalvador: { label: ListOfCountries.ElSalvador, code: "SV" },
  EquatorialGuinea: { label: ListOfCountries.EquatorialGuinea, code: "GQ" },
  Eritrea: { label: ListOfCountries.Eritrea, code: "ER" },
  Estonia: { label: ListOfCountries.Estonia, code: "EE" },
  Eswatini: { label: ListOfCountries.Eswatini, code: "SZ" },
  Ethiopia: { label: ListOfCountries.Ethiopia, code: "ET" },
  FalklandIslands: { label: ListOfCountries.FalklandIslands, code: "FK" },
  FaroeIslands: { label: ListOfCountries.FaroeIslands, code: "FO" },
  Fiji: { label: ListOfCountries.Fiji, code: "FJ" },
  Finland: { label: ListOfCountries.Finland, code: "FI" },
  France: { label: ListOfCountries.France, code: "FR" },
  FrenchGuiana: { label: ListOfCountries.FrenchGuiana, code: "GF" },
  FrenchPolynesia: { label: ListOfCountries.FrenchPolynesia, code: "PF" },
  FrenchSouthernTerritories: { label: ListOfCountries.FrenchSouthernTerritories, code: "TF" },
  Gabon: { label: ListOfCountries.Gabon, code: "GA" },
  Gambia: { label: ListOfCountries.Gambia, code: "GM" },
  Georgia: { label: ListOfCountries.Georgia, code: "GE" },
  Germany: { label: ListOfCountries.Germany, code: "DE" },
  Ghana: { label: ListOfCountries.Ghana, code: "GH" },
  Gibraltar: { label: ListOfCountries.Gibraltar, code: "GI" },
  Greece: { label: ListOfCountries.Greece, code: "GR" },
  Greenland: { label: ListOfCountries.Greenland, code: "GL" },
  Grenada: { label: ListOfCountries.Grenada, code: "GD" },
  Guadeloupe: { label: ListOfCountries.Guadeloupe, code: "GP" },
  Guam: { label: ListOfCountries.Guam, code: "GU" },
  Guatemala: { label: ListOfCountries.Guatemala, code: "GT" },
  Guernsey: { label: ListOfCountries.Guernsey, code: "GG" },
  Guinea: { label: ListOfCountries.Guinea, code: "GN" },
  GuineaBissau: { label: ListOfCountries.GuineaBissau, code: "GW" },
  Guyana: { label: ListOfCountries.Guyana, code: "GY" },
  Haiti: { label: ListOfCountries.Haiti, code: "HT" },
  Honduras: { label: ListOfCountries.Honduras, code: "HN" },
  HongKongSARChina: { label: ListOfCountries.HongKongSARChina, code: "HK" },
  Hungary: { label: ListOfCountries.Hungary, code: "HU" },
  Iceland: { label: ListOfCountries.Iceland, code: "IS" },
  India: { label: ListOfCountries.India, code: "IN" },
  Indonesia: { label: ListOfCountries.Indonesia, code: "ID" },
  Iran: { label: ListOfCountries.Iran, code: "IR" },
  Iraq: { label: ListOfCountries.Iraq, code: "IQ" },
  Ireland: { label: ListOfCountries.Ireland, code: "IE" },
  IsleOfMan: { label: ListOfCountries.IsleOfMan, code: "IM" },
  Israel: { label: ListOfCountries.Israel, code: "IL" },
  Italy: { label: ListOfCountries.Italy, code: "IT" },
  Jamaica: { label: ListOfCountries.Jamaica, code: "JM" },
  Japan: { label: ListOfCountries.Japan, code: "JP" },
  Jersey: { label: ListOfCountries.Jersey, code: "JE" },
  Jordan: { label: ListOfCountries.Jordan, code: "JO" },
  Kazakhstan: { label: ListOfCountries.Kazakhstan, code: "KZ" },
  Kenya: { label: ListOfCountries.Kenya, code: "KE" },
  Kiribati: { label: ListOfCountries.Kiribati, code: "KI" },
  Kosovo: { label: ListOfCountries.Kosovo, code: "XK" },
  Kuwait: { label: ListOfCountries.Kuwait, code: "KW" },
  Kyrgyzstan: { label: ListOfCountries.Kyrgyzstan, code: "KG" },
  Laos: { label: ListOfCountries.Laos, code: "LA" },
  Latvia: { label: ListOfCountries.Latvia, code: "LV" },
  Lebanon: { label: ListOfCountries.Lebanon, code: "LB" },
  Lesotho: { label: ListOfCountries.Lesotho, code: "LS" },
  Liberia: { label: ListOfCountries.Liberia, code: "LR" },
  Libya: { label: ListOfCountries.Libya, code: "LY" },
  Liechtenstein: { label: ListOfCountries.Liechtenstein, code: "LI" },
  Lithuania: { label: ListOfCountries.Lithuania, code: "LT" },
  Luxembourg: { label: ListOfCountries.Luxembourg, code: "LU" },
  MacaoSARChina: { label: ListOfCountries.MacaoSARChina, code: "MO" },
  Madagascar: { label: ListOfCountries.Madagascar, code: "MG" },
  Malawi: { label: ListOfCountries.Malawi, code: "MW" },
  Malaysia: { label: ListOfCountries.Malaysia, code: "MY" },
  Maldives: { label: ListOfCountries.Maldives, code: "MV" },
  Mali: { label: ListOfCountries.Mali, code: "ML" },
  Malta: { label: ListOfCountries.Malta, code: "MT" },
  MarshallIslands: { label: ListOfCountries.MarshallIslands, code: "MH" },
  Martinique: { label: ListOfCountries.Martinique, code: "MQ" },
  Mauritania: { label: ListOfCountries.Mauritania, code: "MR" },
  Mauritius: { label: ListOfCountries.Mauritius, code: "MU" },
  Mayotte: { label: ListOfCountries.Mayotte, code: "YT" },
  Mexico: { label: ListOfCountries.Mexico, code: "MX" },
  Micronesia: { label: ListOfCountries.Micronesia, code: "FM" },
  Moldova: { label: ListOfCountries.Moldova, code: "MD" },
  Monaco: { label: ListOfCountries.Monaco, code: "MC" },
  Mongolia: { label: ListOfCountries.Mongolia, code: "MN" },
  Montenegro: { label: ListOfCountries.Montenegro, code: "ME" },
  Montserrat: { label: ListOfCountries.Montserrat, code: "MS" },
  Morocco: { label: ListOfCountries.Morocco, code: "MA" },
  Mozambique: { label: ListOfCountries.Mozambique, code: "MZ" },
  MyanmarBurma: { label: ListOfCountries.MyanmarBurma, code: "MM" },
  Namibia: { label: ListOfCountries.Namibia, code: "NA" },
  Nauru: { label: ListOfCountries.Nauru, code: "NR" },
  Nepal: { label: ListOfCountries.Nepal, code: "NP" },
  Netherlands: { label: ListOfCountries.Netherlands, code: "NL" },
  NewCaledonia: { label: ListOfCountries.NewCaledonia, code: "NC" },
  NewZealand: { label: ListOfCountries.NewZealand, code: "NZ" },
  Nicaragua: { label: ListOfCountries.Nicaragua, code: "NI" },
  Niger: { label: ListOfCountries.Niger, code: "NE" },
  Nigeria: { label: ListOfCountries.Nigeria, code: "NG" },
  Niue: { label: ListOfCountries.Niue, code: "NU" },
  NorfolkIsland: { label: ListOfCountries.NorfolkIsland, code: "NF" },
  NorthernMarianaIslands: { label: ListOfCountries.NorthernMarianaIslands, code: "MP" },
  NorthKorea: { label: ListOfCountries.NorthKorea, code: "KP" },
  NorthMacedonia: { label: ListOfCountries.NorthMacedonia, code: "MK" },
  Norway: { label: ListOfCountries.Norway, code: "NO" },
  Oman: { label: ListOfCountries.Oman, code: "OM" },
  Pakistan: { label: ListOfCountries.Pakistan, code: "PK" },
  Palau: { label: ListOfCountries.Palau, code: "PW" },
  PalestinianTerritories: { label: ListOfCountries.PalestinianTerritories, code: "PS" },
  Panama: { label: ListOfCountries.Panama, code: "PA" },
  PapuaNewGuinea: { label: ListOfCountries.PapuaNewGuinea, code: "PG" },
  Paraguay: { label: ListOfCountries.Paraguay, code: "PY" },
  Peru: { label: ListOfCountries.Peru, code: "PE" },
  Philippines: { label: ListOfCountries.Philippines, code: "PH" },
  PitcairnIslands: { label: ListOfCountries.PitcairnIslands, code: "PN" },
  Poland: { label: ListOfCountries.Poland, code: "PL" },
  Portugal: { label: ListOfCountries.Portugal, code: "PT" },
  PseudoAccents: { label: ListOfCountries.PseudoAccents, code: "XA" },
  PseudoBidi: { label: ListOfCountries.PseudoBidi, code: "XB" },
  PuertoRico: { label: ListOfCountries.PuertoRico, code: "PR" },
  Qatar: { label: ListOfCountries.Qatar, code: "QA" },
  Reunion: { label: ListOfCountries.Reunion, code: "RE" },
  Romania: { label: ListOfCountries.Romania, code: "RO" },
  Russia: { label: ListOfCountries.Russia, code: "RU" },
  Rwanda: { label: ListOfCountries.Rwanda, code: "RW" },
  Samoa: { label: ListOfCountries.Samoa, code: "WS" },
  SanMarino: { label: ListOfCountries.SanMarino, code: "SM" },
  SaoTomeAndPrincipe: { label: ListOfCountries.SaoTomeAndPrincipe, code: "ST" },
  SaudiArabia: { label: ListOfCountries.SaudiArabia, code: "SA" },
  Senegal: { label: ListOfCountries.Senegal, code: "SN" },
  Serbia: { label: ListOfCountries.Serbia, code: "RS" },
  Seychelles: { label: ListOfCountries.Seychelles, code: "SC" },
  SierraLeone: { label: ListOfCountries.SierraLeone, code: "SL" },
  Singapore: { label: ListOfCountries.Singapore, code: "SG" },
  SintMaarten: { label: ListOfCountries.SintMaarten, code: "SX" },
  Slovakia: { label: ListOfCountries.Slovakia, code: "SK" },
  Slovenia: { label: ListOfCountries.Slovenia, code: "SI" },
  SolomonIslands: { label: ListOfCountries.SolomonIslands, code: "SB" },
  Somalia: { label: ListOfCountries.Somalia, code: "SO" },
  SouthAfrica: { label: ListOfCountries.SouthAfrica, code: "ZA" },
  SouthGeorgiaAndSouthSandwichIslands: {
    label: ListOfCountries.SouthGeorgiaAndSouthSandwichIslands,
    code: "GS",
  },
  SouthKorea: { label: ListOfCountries.SouthKorea, code: "KR" },
  SouthSudan: { label: ListOfCountries.SouthSudan, code: "SS" },
  Spain: { label: ListOfCountries.Spain, code: "ES" },
  SriLanka: { label: ListOfCountries.SriLanka, code: "LK" },
  StBarthelemy: { label: ListOfCountries.StBarthelemy, code: "BL" },
  StHelena: { label: ListOfCountries.StHelena, code: "SH" },
  StKittsAndNevis: { label: ListOfCountries.StKittsAndNevis, code: "KN" },
  StLucia: { label: ListOfCountries.StLucia, code: "LC" },
  StMartin: { label: ListOfCountries.StMartin, code: "MF" },
  StPierreAndMiquelon: { label: ListOfCountries.StPierreAndMiquelon, code: "PM" },
  StVincentAndGrenadines: { label: ListOfCountries.StVincentAndGrenadines, code: "VC" },
  Sudan: { label: ListOfCountries.Sudan, code: "SD" },
  Suriname: { label: ListOfCountries.Suriname, code: "SR" },
  SvalbardAndJanMayen: { label: ListOfCountries.SvalbardAndJanMayen, code: "SJ" },
  Sweden: { label: ListOfCountries.Sweden, code: "SE" },
  Switzerland: { label: ListOfCountries.Switzerland, code: "CH" },
  Syria: { label: ListOfCountries.Syria, code: "SY" },
  Taiwan: { label: ListOfCountries.Taiwan, code: "TW" },
  Tajikistan: { label: ListOfCountries.Tajikistan, code: "TJ" },
  Tanzania: { label: ListOfCountries.Tanzania, code: "TZ" },
  Thailand: { label: ListOfCountries.Thailand, code: "TH" },
  TimorLeste: { label: ListOfCountries.TimorLeste, code: "TL" },
  Togo: { label: ListOfCountries.Togo, code: "TG" },
  Tokelau: { label: ListOfCountries.Tokelau, code: "TK" },
  Tonga: { label: ListOfCountries.Tonga, code: "TO" },
  TrinidadAndTobago: { label: ListOfCountries.TrinidadAndTobago, code: "TT" },
  TristanDaCunha: { label: ListOfCountries.TristanDaCunha, code: "TA" },
  Tunisia: { label: ListOfCountries.Tunisia, code: "TN" },
  Turkey: { label: ListOfCountries.Turkey, code: "TR" },
  Turkmenistan: { label: ListOfCountries.Turkmenistan, code: "TM" },
  TurksAndCaicosIslands: { label: ListOfCountries.TurksAndCaicosIslands, code: "TC" },
  Tuvalu: { label: ListOfCountries.Tuvalu, code: "TV" },
  Uganda: { label: ListOfCountries.Uganda, code: "UG" },
  Ukraine: { label: ListOfCountries.Ukraine, code: "UA" },
  UnitedArabEmirates: { label: ListOfCountries.UnitedArabEmirates, code: "AE" },
  UnitedKingdom: { label: ListOfCountries.UnitedKingdom, code: "GB" },
  UnitedStates: { label: ListOfCountries.UnitedStates, code: "US" },
  Uruguay: { label: ListOfCountries.Uruguay, code: "UY" },
  USOutlyingIslands: { label: ListOfCountries.USOutlyingIslands, code: "UM" },
  USVirginIslands: { label: ListOfCountries.USVirginIslands, code: "VI" },
  Uzbekistan: { label: ListOfCountries.Uzbekistan, code: "UZ" },
  Vanuatu: { label: ListOfCountries.Vanuatu, code: "VU" },
  VaticanCity: { label: ListOfCountries.VaticanCity, code: "VA" },
  Venezuela: { label: ListOfCountries.Venezuela, code: "VE" },
  Vietnam: { label: ListOfCountries.Vietnam, code: "VN" },
  WallisAndFutuna: { label: ListOfCountries.WallisAndFutuna, code: "WF" },
  WesternSahara: { label: ListOfCountries.WesternSahara, code: "EH" },
  Yemen: { label: ListOfCountries.Yemen, code: "YE" },
  Zambia: { label: ListOfCountries.Zambia, code: "ZM" },
  Zimbabwe: { label: ListOfCountries.Zimbabwe, code: "ZW" },
};

export const generateTranslatedCountriesListWithCodes = (): Array<{
  value: string;
  label: string;
}> => {
  const countryKeys = Object.keys(countryBaseNameCodeMap);
  return countryKeys
    .map((countryKey) => ({
      label: $t(countryBaseNameCodeMap[countryKey].label),
      value: countryBaseNameCodeMap[countryKey].code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getUntranslatedCountryNameForCode = (countryCode: string): string | undefined => {
  if (!countryCode) return undefined;
  const countryKeys = Object.keys(countryBaseNameCodeMap);
  const countryKey = countryKeys.find((key) => countryBaseNameCodeMap[key].code === countryCode);
  return countryKey ? countryBaseNameCodeMap[countryKey].label?.defaultMessage : undefined;
};
