import { Paragraph } from "@smartsuite/react-ui/lib";

import { $t } from "../../utils/intl";
import "./FooterBranding.sass";
import { FooterBrandingText } from "./FooterBranding.text";

export const FooterBranding: React.FC = () => {
  return (
    <footer className="footer-branding">
      <Paragraph size="xxs" color="tertiary">
        {$t(FooterBrandingText.poweredBy)}
      </Paragraph>
      <a href="http://smartsuite.com" target="_blank" rel="noreferrer">
        <img
          src="/images/smartsuite-logo-footer.svg"
          alt={$t(FooterBrandingText.smartSuiteLogo)}
          width={81}
          height={13}
        />
      </a>
    </footer>
  );
};
