import React, { FC } from "react";
import { Text } from "@smartsuite/react-ui/lib/components/typography/Text/Text";

import "./SelectModalSaveButtonTooltip.sass";
import { $t } from "../../../../../../../../../../utils/intl";
import { SelectModalSaveButtonTooltipText } from "./SelectModalSaveButtonTooltip.text";

export const SelectModalSaveButtonTooltip: FC<React.PropsWithChildren> = () => {
  const shortKey = window.navigator.userAgent.includes("Mac") ? "⌘ + S" : "Ctrl + S";

  return (
    <div className="select-modal-save-button-tooltip">
      <Text color="reverse" size="xs" weight="semibold">
        {$t(SelectModalSaveButtonTooltipText.saveButtonLabel)}
      </Text>
      <Text color="inactive" size="xs" weight="semibold">
        {shortKey}
      </Text>
    </div>
  );
};
