import { FieldRegistryConfig } from "../../types";
import { SingleSelectFieldValue } from "../single-select/single-select.config";
import { SingleSelectFieldControl } from "../single-select/SingleSelectFieldControl/SingleSelectFieldControl";

export interface StatusFieldValue {
  value: string;
  label: string;
}

export const statusFieldConfig: FieldRegistryConfig<SingleSelectFieldValue, StatusFieldValue> = {
  icon: "status",
  control: SingleSelectFieldControl,
  convertFormValueToApi: (value) => ({ value, label: "" }),
};
