import { FieldRegistryConfig } from "../../types";
import { DurationFieldControl } from "./DurationFieldControl/DurationFieldControl";
import { $t } from "../../../utils/intl";
import { fieldsText } from "../fields.text";

export type DurationFieldValue = number;

export const durationFieldConfig: FieldRegistryConfig<DurationFieldValue> = {
  icon: "duration",
  control: (props) => (
    <DurationFieldControl {...props} placeholder={$t(fieldsText.durationPlaceholder)} />
  ),
};
