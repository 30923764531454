import { Paragraph } from "@smartsuite/react-ui/lib";
import cx from "classnames";
import React from "react";
import { $t } from "../../utils/intl";
import { formFieldControlText } from "./FormFieldControl.text";
import { InputGroupState } from "@smartsuite/react-ui/lib/components/forms/InputGroup/InputGroup.constants";

export interface FormFieldControlProps {
  label: string;
  state: InputGroupState;
  caption: string | undefined;
  required: boolean;
  htmlFor?: string;
  errorMessage: string | undefined;
  children: React.ReactNode;
}

export const FormFieldControl: React.FC<FormFieldControlProps> = ({
  label,
  state,
  caption,
  htmlFor,
  required,
  errorMessage,
  children,
}) => {
  return (
    <div className={cx("form-field-control", `form-field-control--state-${state}`)}>
      {label && (
        <label htmlFor={htmlFor} className="form-field-control__label">
          <Paragraph weight="medium" size="l-semi-plus" color="currentColor">
            {label}
          </Paragraph>

          {required && (
            <Paragraph
              className="form-field-control__required"
              color="error"
              size="xxxs"
              weight="semibold"
            >
              {$t(formFieldControlText.required)}
            </Paragraph>
          )}
        </label>
      )}

      {caption && (
        <div className="form-field-control__caption">
          <Paragraph size="s" color="secondary">
            {caption}
          </Paragraph>
        </div>
      )}

      <div className="form-field-control__body">{children}</div>

      {errorMessage && (
        <div className="form-field-control__error-message">
          <Paragraph color="error" size="s">
            {errorMessage}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
