import { defineMessages } from "react-intl";

export const FullNameFieldControlMessages = defineMessages({
  firstNamePlaceholder: {
    defaultMessage: "First name",
    id: "pONqz8",
  },
  middleNamePlaceholder: {
    defaultMessage: "Middle name",
    id: "VMyUHM",
  },
  lastNamePlaceholder: {
    defaultMessage: "Last name",
    id: "txUL0F",
  },
  titlePlaceholder: {
    defaultMessage: "Title",
    id: "9a9+ww",
  },
});
