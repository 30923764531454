import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FieldControlComponent } from "../../registry/FieldControlComponent";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../../types/form";

export interface FormFieldItemProps {
  appField?: FormConfigApplicationStructureItem;
  item: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormFieldItem: React.FC<FormFieldItemProps> = ({
  appField,
  item,
  checkFieldVisibility,
}) => {
  if (!appField) return null;

  // Check if the field has and conditions configured and enabled, and if the condition is
  // satisfied. If so, let it render the field. Also takes in account that the conditions
  // reference other fields, which also need to be visible in order for the condition to be
  // satisfied.
  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  return (
    <div
      key={item.slug}
      className="form-field-item"
      data-testid="form-field-item"
      data-field-name={item.slug}
    >
      <FieldControlComponent formItem={item} field={appField} />
    </div>
  );
};
