import { FC, ReactNode, useCallback, useState, MouseEvent } from "react";
import { FieldChoice, TagsFieldValue, TextColorNames } from "@smartsuite/types";
import cx from "classnames";
import {
  AddButton,
  HashtagPill,
  KeyValueCustomMultipleSelect,
  Text,
} from "@smartsuite/react-ui/lib";
import { globalModalsZIndex } from "@smartsuite/react-ui/lib/constants/zIndex";

import "./TagsFieldSelect.sass";
import { $t } from "../../../../../utils/intl";
import { TagsFieldSelectMessages } from "./TagsFieldSelect.text";

interface TagsFieldSelectProps {
  tags: FieldChoice[];
  value: TagsFieldValue;
  onChangeValue: (value: string[]) => void;
  onOpenedChange?: (open: boolean) => void;
}

export const TagsFieldSelect: FC<TagsFieldSelectProps> = ({
  tags,
  value,
  onChangeValue,
  onOpenedChange,
}) => {
  const [opened, setOpened] = useState(false);
  const [tagsValue, setTagsValue] = useState<string[]>(value);

  const handleTagsValueChange = useCallback(
    (currentValue: string[]) => {
      setTagsValue(currentValue);
      onChangeValue(currentValue);
    },
    [onChangeValue, setTagsValue]
  );

  const handleRemove = useCallback(
    (e: MouseEvent, removed: FieldChoice): void => {
      e.stopPropagation();
      const newValue = tagsValue.filter((val) => val !== removed.value);
      setTagsValue(newValue);
      onChangeValue(newValue);
    },
    [onChangeValue, setTagsValue, tagsValue]
  );

  const handleOpenChange = (): void => {
    setOpened(!opened);
    onOpenedChange?.(!opened);
  };

  const renderOption = useCallback((option: FieldChoice): ReactNode => {
    return (
      <HashtagPill className="tags-field-select__pill" disabled={true} showTagIcon={false}>
        {option.label}
      </HashtagPill>
    );
  }, []);

  const renderSelectedOption = useCallback(
    (option: FieldChoice): ReactNode => {
      return (
        <HashtagPill
          className="tags-field-select__pill is-selected"
          showRemove={true}
          onRemove={(event) => handleRemove(event, option)}
        >
          {option.label}
        </HashtagPill>
      );
    },
    [handleRemove]
  );

  const renderEmptyMessage = (): ReactNode => (
    <div className="tags-field-select__empty">
      <Text color="secondary">{$t(TagsFieldSelectMessages.noTags)}</Text>
    </div>
  );

  const classes = cx("tags-field-select", {
    "is-opened": opened,
  });

  return (
    <div className={classes}>
      <AddButton
        className="tags-field-select__add-button"
        textStyle={{
          size: "xs",
          weight: "semibold",
          color: TextColorNames.primary,
          hoverColor: TextColorNames.primary,
        }}
      />

      <KeyValueCustomMultipleSelect
        inputBackground="fully-transparent"
        arrowBackground="white"
        placeholder=""
        selectListLayout="horizontal"
        my="center top"
        at="center bottom+12"
        scrollListHeight="92px"
        searchPlaceholder={$t(TagsFieldSelectMessages.searchPlaceholder)}
        showFilterGreaterThan={0}
        minWidth={400}
        zIndex={globalModalsZIndex}
        value={tagsValue}
        options={tags}
        showDropdownSearchIcon={false}
        showSelectedOptionInDropdown={false}
        hidePlaceholderArrow={true}
        selectListFooter={true}
        optionWithCheckbox={false}
        renderEmptyMessage={renderEmptyMessage}
        renderOption={renderOption}
        renderSelectedOption={renderSelectedOption}
        onChange={(value) => handleTagsValueChange(value as string[])}
        onOpenedChange={handleOpenChange}
      />
    </div>
  );
};
