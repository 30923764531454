import React, { FC } from "react";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";

import { FormConfigItem } from "../../types/form";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FormFieldControl } from "../FormFieldControl/FormFieldControl";

import "./FormRecaptchaItem.sass";
import { useFormField } from "../../hooks/useFormField";
import { validateWithYup } from "../../registry/validator";
import * as yup from "yup";
import { $t } from "../../utils/intl";
import { validatorText } from "../../registry/validator.text";

interface FormRecaptchaItemProps {
  item: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormRecaptchaItem: FC<FormRecaptchaItemProps> = ({ item, checkFieldVisibility }) => {
  const isItemConditionSatisfied = checkFieldVisibility(item.slug);

  const formField = useFormField<string>({
    name: item.slug,
    validate: validateWithYup(
      yup.object().when({
        is: () => isItemConditionSatisfied,
        then: (object) => object.required($t(validatorText.validationRequired)),
      })
    ),
  });

  if (!isItemConditionSatisfied) return null;

  const handleChange = (token: string | null): void => {
    formField.onChange({
      token,
      shouldSkip: true,
    });
  };

  return (
    <div data-testid="recaptcha-item" className="recaptcha-item" data-field-name={item.slug}>
      <FormFieldControl
        label={item.params.label ?? ""}
        state={formField.state}
        caption={item.params.caption}
        required={false}
        errorMessage={formField.errorMessage}
      >
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
          onChange={handleChange}
        />
      </FormFieldControl>
    </div>
  );
};
