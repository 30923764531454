import { Filter } from "@smartsuite/types";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../../../../../../types/form";

export const getFilterData = (
  formItemParams: FormConfigItem["params"],
  fieldParams: FormConfigApplicationStructureItem["params"]
): Filter | undefined => {
  if (formItemParams.filter_data || formItemParams.filter_data === null) {
    return formItemParams.filter_data;
  }

  if (
    formItemParams?.linked_record_conditions?.enabled &&
    formItemParams?.linked_record_conditions?.object
  ) {
    return formItemParams.linked_record_conditions.object;
  }

  return fieldParams?.filter_data;
};
