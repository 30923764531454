import React, { FC, useState } from "react";
import * as yup from "yup";
import { ipv4Ipv6Regex } from "@smartsuite/types";
import { IconButton, Paragraph, TextFieldGroup } from "@smartsuite/react-ui/lib";

import { FormFieldProps } from "../../../../../types";
import { useFormField } from "../../../../../../hooks/useFormField";
import { IpAddressValue } from "../../../ip-address.config";
import { applyYupDefaults, validateWithYup } from "../../../../../validator";
import { FormFieldControl } from "../../../../../../components/FormFieldControl/FormFieldControl";
import { AddIPAddress } from "./components/AddIPAddress/AddIPAddress";

import "./IPAddressFieldControlMultiple.sass";
import { $t } from "../../../../../../utils/intl";
import { validatorText } from "../../../../../validator.text";

type IPAddressFieldControlMultipleProps = FormFieldProps;

export const IPAddressFieldControlMultiple: FC<IPAddressFieldControlMultipleProps> = ({
  label,
  name,
  field,
  formItem,
  required,
  caption,
  placeholder,
}) => {
  const [enteredValue, setEnteredValue] = useState<string>("");

  const formField = useFormField<IpAddressValue>({
    name,
    validate: validateWithYup(
      applyYupDefaults(
        yup
          .array()
          .test("isValidEnteredValue", " ", (value) => {
            return enteredValue ? ipv4Ipv6Regex.test(enteredValue) : true;
          })
          .of(
            yup.object().test("isValidIpAddress", " ", (value) => {
              return value ? ipv4Ipv6Regex.test(value.address) : true;
            })
          ),
        field,
        formItem
      )
    ),
  });

  const handleAddNewAddress = (value: string): void => {
    if (formField.value) {
      formField.onChange([...formField.value, { address: value, country_code: "" }]);
    } else {
      formField.onChange([{ address: value, country_code: "" }]);
    }
  };

  const handleChangeValue = (index: number, value: string): void => {
    const newValue = [...formField.value];
    newValue[index] = { address: value, country_code: "" };
    formField.onChange(newValue);
  };

  const handleDeleteAddress = (index: number): void => {
    const newValue = [...formField.value];
    newValue.splice(index, 1);
    formField.onChange(newValue);
  };

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
    >
      <div className="ip-address-field-control-multiple">
        {formField.value?.map((value, index) => {
          const isValueValid = ipv4Ipv6Regex.test(value.address);

          return (
            <div key={index}>
              <TextFieldGroup
                name={formField.name}
                placeholder={placeholder}
                required={required}
                value={value?.address}
                onBlur={formField.onBlur}
                onChange={(event) => handleChangeValue(index, event.target.value)}
                onFocus={formField.onFocus}
              >
                <IconButton
                  className="phone-field-control-multiple__delete_button"
                  name="close"
                  ariaLabel="delete"
                  onClick={() => handleDeleteAddress(index)}
                />
              </TextFieldGroup>

              {!isValueValid && (
                <div className="ip-address-field-control-multiple__error-message">
                  <Paragraph color="error" size="s">
                    {$t(validatorText.validationIPAddress)}
                  </Paragraph>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <AddIPAddress
        onAddNewAddress={handleAddNewAddress}
        placeholder={placeholder}
        forceAdding={!formField.value?.length}
        onChangeEnteredValue={setEnteredValue}
      />
    </FormFieldControl>
  );
};
