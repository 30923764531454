import { Dialog } from "@smartsuite/react-ui/lib";
import React from "react";
import { $t } from "../../utils/intl";

import "./FormNotFoundMessage.sass";
import { FormNotFoundMessageText } from "./FormNotFoundMessage.text";

export const FormNotFoundMessage: React.FC = () => {
  return (
    <div className="form-not-found-message">
      <img
        src="/images/form-404-rectangles.svg"
        alt={$t(FormNotFoundMessageText.imgAlt)}
        className="form-not-found-message__background-image"
      />
      <Dialog.Wrapper type="warning">
        <Dialog.Image background="red" url="/images/form-404-sad.svg" />
        <Dialog.Header>
          <Dialog.Header.Heading text={$t(FormNotFoundMessageText.header)} />
          <Dialog.Header.Description text={$t(FormNotFoundMessageText.description)} />
        </Dialog.Header>
      </Dialog.Wrapper>
    </div>
  );
};
