import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FormConfigVideoItem } from "../../types/form";
import { FC, useMemo } from "react";
import ReactPlayer from "react-player";

interface FormVideoItemProps {
  item: FormConfigVideoItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormVideoItem: FC<FormVideoItemProps> = ({ item, checkFieldVisibility }) => {
  const { videoUrl, maxHeight } = item.params;

  const isGoogleDriveUrl = useMemo(() => videoUrl?.includes("drive.google.com"), [videoUrl]);

  if (!videoUrl) return null;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  return isGoogleDriveUrl ? (
    <iframe
      title="google drive video"
      className="form-video-item"
      src={videoUrl.replace("/view", "/preview")}
      width="100%"
      height={maxHeight}
      allow="autoplay"
    />
  ) : (
    <ReactPlayer
      data-testid="form-video-item"
      className="form-video-item"
      width="100%"
      height={maxHeight}
      url={videoUrl}
      controls
    />
  );
};
