/* eslint-disable no-template-curly-in-string */

import { FieldValidator } from "formik";
import * as yup from "yup";

import { extractErrorMessage } from "../helpers/errors";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../types/form";
import { $t } from "../utils/intl";
import { validatorText } from "./validator.text";

export function applyYupDefaults(
  schema: yup.AnySchema,
  field: FormConfigApplicationStructureItem,
  formItem: FormConfigItem
): yup.AnySchema {
  return schema
    .transform((value, originalValue) =>
      typeof originalValue === "string" ? originalValue.trim() || null : value
    )
    .when({
      is: () => formItem.required,
      then: (s) =>
        s.test("required", $t(validatorText.validationRequired), (value) => {
          return (
            value !== null &&
            value !== undefined &&
            (typeof value === "string" ? !!value.trim() : true) &&
            (Array.isArray(value) ? value.length > 0 : true)
          );
        }),
    })
    .when({
      is: () => field.params.min_value !== undefined && field.params.max_value !== undefined,
      then: (s) =>
        (s as yup.NumberSchema).test(
          "numberBetweenMinMaxValue",
          $t(validatorText.validationNumberMinMax, {
            minValue: field.params.min_value as number,
            maxValue: field.params.max_value as number,
          }),
          (value) =>
            value === undefined ||
            (value >= (field.params.min_value as number) &&
              value <= (field.params.max_value as number))
        ),
    })
    .when({
      is: () => field.params.min_value !== undefined,
      then: (s) => (s as yup.NumberSchema).min(field.params.min_value as number),
    })
    .when({
      is: () => field.params.max_value !== undefined,
      then: (s) => (s as yup.NumberSchema).max(field.params.max_value as number),
    })
    .nullable();
}

export function validateWithYup(schema: yup.AnySchema): FieldValidator {
  return (value) => {
    if (!schema) {
      return;
    }

    try {
      schema.validateSync(value);
    } catch (err) {
      return extractErrorMessage((err as yup.ValidationError)?.errors);
    }
  };
}
