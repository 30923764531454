import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { ColorPickerPill, ColorPickerDropdown } from "@smartsuite/react-ui/lib";
import { ColorPickerDisplayFormat, ColorPickerValue } from "@smartsuite/types";

import { defaultColorPickerValue } from "../../../color-picker.config";

import "./ColorPickerFieldItem.sass";
import cx from "classnames";
import { ColorPickerFieldItemMessages } from "./ColorPickerFieldItem.text";
import { $t } from "../../../../../../utils/intl";

export interface ColorPickerFieldItemProps {
  className?: string;
  value?: ColorPickerValue;
  displayFormat: ColorPickerDisplayFormat;
  onClear?: (event?: MouseEvent) => void;
  showClear?: boolean;
  onChange: (value: ColorPickerValue) => void;
  onOpenDropdown?: () => void;
  onCloseDropdown?: () => void;
}

export const ColorPickerFieldItem: FC<ColorPickerFieldItemProps> = ({
  className,
  value,
  displayFormat,
  onClear,
  onChange,
  showClear,
  onOpenDropdown,
  onCloseDropdown,
}) => {
  const pillContainerRef = useRef<HTMLButtonElement>(null);

  const [isEditing, setIsEditing] = useState(!value);

  const handleClick = (): void => {
    setIsEditing((state) => !state);

    if (isEditing) {
      onCloseDropdown?.();
    }
  };

  const handleCloseDropdown = (): void => {
    setIsEditing(false);
    onCloseDropdown?.();
  };

  useEffect(() => {
    if (isEditing) {
      onOpenDropdown?.();
    }
  }, [isEditing, onOpenDropdown]);

  useEffect(() => {
    if (!value) {
      onChange(defaultColorPickerValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const containerClasses = cx("color-picker-field-item", className);

  return (
    <>
      <button
        type={"button"}
        data-testid="color-picker-field-item"
        className={containerClasses}
        ref={pillContainerRef}
        onClick={handleClick}
      >
        {value && (
          <ColorPickerPill
            value={value.value}
            name={value.name}
            showClear={showClear}
            onClear={onClear}
            displayFormat={displayFormat}
          />
        )}
      </button>
      <ColorPickerDropdown
        targetElement={pillContainerRef}
        isOpen={isEditing}
        color={value ?? defaultColorPickerValue}
        displayFormat={displayFormat}
        onChange={onChange}
        onCloseDropdown={handleCloseDropdown}
        colorNameLabel={$t(ColorPickerFieldItemMessages.colorNameText)}
        colorNamePlaceholder={$t(ColorPickerFieldItemMessages.colorNamePlaceholder)}
      />
    </>
  );
};
