import { defineMessages } from "react-intl";

export const ColorPickerFieldItemMessages = defineMessages({
  colorNameText: {
    defaultMessage: "Color name",
    id: "1Puir5",
  },
  colorNamePlaceholder: {
    defaultMessage: "Enter color name",
    id: "tm8xbR",
  },
});
