import { Paragraph, Title } from "@smartsuite/react-ui/lib";
import React from "react";

import "./FormTitle.sass";

export interface FormTitleProps {
  title: string | null;
  description: string | null;
}

export const FormTitle: React.FC<FormTitleProps> = ({ title, description }) => {
  if (!title) {
    return null;
  }

  return (
    <header className="form-title">
      <Title tagName="h1" size="xxl" weight="bold">
        {title}
      </Title>

      {description && (
        <Paragraph color="secondary" className="form-title__description">
          {description}
        </Paragraph>
      )}
    </header>
  );
};
