import { defineMessages } from "react-intl";

export const FooterBrandingText = defineMessages({
  poweredBy: {
    defaultMessage: "Powered by",
    id: "U8QBHO",
  },
  smartSuiteLogo: {
    defaultMessage: "SmartSuite logo",
    id: "kT10Uv",
  },
});
