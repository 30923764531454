import { AddressFieldTypes } from "./address.types";

export const GOOGLE_API_LIBRARIES: [
  "places" | "drawing" | "geometry" | "localContext" | "visualization"
] = ["places"];

export const autocompleteTypesMap: Map<AddressFieldTypes, string[]> = new Map([
  [AddressFieldTypes.location_address, ["geocode"]],
  [AddressFieldTypes.location_city, ["(cities)"]],
  [AddressFieldTypes.location_state, ["(regions)"]],
]);
