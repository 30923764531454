import React, { FC, Suspense } from "react";

import { FormConfigItem } from "../../types/form";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

import "./FormHTMLBlockItem.sass";
import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";

const HTMLBlockSerializer = React.lazy(
  () => import(/* webpackChunkName: "html-block-serializer" */ "./HTMLBlockSerializer")
);

export interface FormHTMLBlockItemProps {
  state: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormHTMLBlockItem: FC<FormHTMLBlockItemProps> = ({ state, checkFieldVisibility }) => {
  const isItemConditionSatisfied = checkFieldVisibility(state.slug);
  if (!isItemConditionSatisfied) return null;

  const doc = state.params?.doc;

  return (
    <div className="form-html-block-item">
      <ErrorBoundary>
        <Suspense fallback={null}>
          <HTMLBlockSerializer doc={doc} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
