import { ColorPickerFieldValue } from "@smartsuite/types/lib/field-values/color-picker";

import { ColorPickerFieldControl } from "./ColorPickerFieldControl/ColorPickerFieldControl";
import { FieldRegistryConfig } from "../../types";

export const defaultColorPickerValue = {
  value: "#000000",
  name: "",
};

export const colorPickerConfig: FieldRegistryConfig<ColorPickerFieldValue> = {
  icon: "color-picker",
  control: (props) => <ColorPickerFieldControl {...props} />,
};
