import { BaseFieldTypeLogic } from "@smartsuite/fields-logic/lib/field-type-registry/field-logic";
import { AddressFieldLogic } from "@smartsuite/fields-logic/lib/fields/address-field";
import { AssignedToFieldLogic } from "@smartsuite/fields-logic/lib/fields/assigned-to";
import { CurrencyFieldLogic } from "@smartsuite/fields-logic/lib/fields/currency-field";
import { DateFieldLogic } from "@smartsuite/fields-logic/lib/fields/date-field/date.fieldtype";
import { EmailFieldLogic } from "@smartsuite/fields-logic/lib/fields/email-field";
import { FileFieldLogic } from "@smartsuite/fields-logic/lib/fields/file-field";
import { FullNameFieldLogic } from "@smartsuite/fields-logic/lib/fields/full-name-field";
import { LinkFieldLogic } from "@smartsuite/fields-logic/lib/fields/link-field";
import { LinkedRecordFieldLogic } from "@smartsuite/fields-logic/lib/fields/linked-record-field";
import { MultipleSelectFieldLogic } from "@smartsuite/fields-logic/lib/fields/multiple-select-field/multiple-select.fieldtype";
import { NumberFieldType } from "@smartsuite/fields-logic/lib/fields/number-field";
import { NumberSliderFieldLogic } from "@smartsuite/fields-logic/lib/fields/number-slider-field";
import { PercentCompleteFieldLogic } from "@smartsuite/fields-logic/lib/fields/percent-complete-field";
import { PercentFieldLogic } from "@smartsuite/fields-logic/lib/fields/percent-field";
import { PhoneFieldLogic } from "@smartsuite/fields-logic/lib/fields/phone-field";
import { RatingFieldLogic } from "@smartsuite/fields-logic/lib/fields/rating-field";
import { RecordTitleFieldLogic } from "@smartsuite/fields-logic/lib/fields/record-title-field";
import { SignatureFieldLogic } from "@smartsuite/fields-logic/lib/fields/signature-field";
import { SingleSelectFieldLogic } from "@smartsuite/fields-logic/lib/fields/single-select-field/single-select.fieldtype";
import { SmartDocFieldLogic } from "@smartsuite/fields-logic/lib/fields/smartdoc-field";
import { StatusFieldLogic } from "@smartsuite/fields-logic/lib/fields/status-field/status.fieldtype";
import { TextareaFieldLogic } from "@smartsuite/fields-logic/lib/fields/text-area-field";
import { TextFieldLogic } from "@smartsuite/fields-logic/lib/fields/text-field/text.fieldtype";
import { TimeFieldLogic } from "@smartsuite/fields-logic/lib/fields/time-field/time.fieldtype";
import { YesNoFieldLogic } from "@smartsuite/fields-logic/lib/fields/yes-no-field";
import { IPAddressFieldLogic } from "@smartsuite/fields-logic/lib/fields/ip-address-field";
import { DurationFieldLogic } from "@smartsuite/fields-logic/lib/fields/duration-field";
import { ColorPickerFieldLogic } from "@smartsuite/fields-logic/lib/fields/color-picker-field";
import { TagsFieldLogic } from "@smartsuite/fields-logic/lib/fields/tags-field";
import { AutonumberFieldLogic } from "@smartsuite/fields-logic/lib/fields/autonumber-field";
import { CountFieldLogic } from "@smartsuite/fields-logic/lib/fields/count-field";
import { AvailabilityStatusFieldLogic } from "@smartsuite/fields-logic/lib/fields/availability-status-field";
import { ButtonFieldLogic } from "@smartsuite/fields-logic/lib/fields/button-field";
import { ChecklistFieldLogic } from "@smartsuite/fields-logic/lib/fields/checklist-field";
import { CommentsCountFieldLogic } from "@smartsuite/fields-logic/lib/fields/comments-count";
import { DateRangeFieldLogic } from "@smartsuite/fields-logic/lib/fields/date-range";
import { DueDateFieldLogic } from "@smartsuite/fields-logic/lib/fields/due-date-field";
import { FirstCreatedFieldLogic } from "@smartsuite/fields-logic/lib/fields/first-created";
import { FormulaFieldLogic } from "@smartsuite/fields-logic/lib/fields/formula-field";
import { LastUpdatedFieldLogic } from "@smartsuite/fields-logic/lib/fields/last-updated";
import { LookupFieldLogic } from "@smartsuite/fields-logic/lib/fields/lookup-field";
import { RecordIdFieldLogic } from "@smartsuite/fields-logic/lib/fields/record-id-field";
import { RollupFieldLogic } from "@smartsuite/fields-logic/lib/fields/rollup-field";
import { SocialNetworkFieldLogic } from "@smartsuite/fields-logic/lib/fields/social-network-field";
import { SubitemsFieldLogic } from "@smartsuite/fields-logic/lib/fields/subitems-field";
import { TextNestedFieldLogic } from "@smartsuite/fields-logic/lib/fields/textnested-field";
import { TimeTrackingLogFieldLogic } from "@smartsuite/fields-logic/lib/fields/time-tracking-log-field";
import { VoteFieldLogic } from "@smartsuite/fields-logic/lib/fields/vote-field";
import { DependencyFieldLogic } from "@smartsuite/fields-logic/lib/fields/dependency-field";
import { DependencyRelationFieldLogic } from "@smartsuite/fields-logic/lib/fields/dependency-relation-field";

import { Fields } from "@smartsuite/types";

type FieldTypes = Record<Fields, typeof BaseFieldTypeLogic>;

export const fieldTypes = {
  addressfield: AddressFieldLogic,
  currencyfield: CurrencyFieldLogic,
  datefield: DateFieldLogic,
  emailfield: EmailFieldLogic,
  filefield: FileFieldLogic,
  fullnamefield: FullNameFieldLogic,
  linkedrecordfield: LinkedRecordFieldLogic,
  linkfield: LinkFieldLogic,
  multipleselectfield: MultipleSelectFieldLogic,
  numberfield: NumberFieldType,
  numbersliderfield: NumberSliderFieldLogic,
  percentcompletefield: PercentCompleteFieldLogic,
  percentfield: PercentFieldLogic,
  phonefield: PhoneFieldLogic,
  ratingfield: RatingFieldLogic,
  recordtitlefield: RecordTitleFieldLogic,
  richtextareafield: SmartDocFieldLogic, // aka smartdocfield
  signaturefield: SignatureFieldLogic,
  singleselectfield: SingleSelectFieldLogic,
  statusfield: StatusFieldLogic,
  textareafield: TextareaFieldLogic,
  textfield: TextFieldLogic,
  timefield: TimeFieldLogic,
  userfield: AssignedToFieldLogic, // aka Assigned To
  yesnofield: YesNoFieldLogic,
  durationfield: DurationFieldLogic,
  ipaddressfield: IPAddressFieldLogic,
  colorpickerfield: ColorPickerFieldLogic,
  tagsfield: TagsFieldLogic,
  autonumberfield: AutonumberFieldLogic,
  countfield: CountFieldLogic,
  availabilitystatusfield: AvailabilityStatusFieldLogic,
  buttonfield: ButtonFieldLogic,
  checklistfield: ChecklistFieldLogic,
  commentscountfield: CommentsCountFieldLogic,
  daterangefield: DateRangeFieldLogic,
  duedatefield: DueDateFieldLogic,
  firstcreatedfield: FirstCreatedFieldLogic,
  formulafield: FormulaFieldLogic,
  lastupdatedfield: LastUpdatedFieldLogic,
  lookupfield: LookupFieldLogic,
  recordidfield: RecordIdFieldLogic,
  rollupfield: RollupFieldLogic,
  socialnetworkfield: SocialNetworkFieldLogic,
  subitemsfield: SubitemsFieldLogic,
  textnestedfield: TextNestedFieldLogic,
  timetrackingfield: TimeTrackingLogFieldLogic,
  unknownfield: BaseFieldTypeLogic,
  dependencyfield: DependencyFieldLogic,
  dependencyrelationfield: DependencyRelationFieldLogic,
  votefield: VoteFieldLogic,
} as FieldTypes;
