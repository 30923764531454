import { isEnterKey, TextFieldGroup } from "@smartsuite/react-ui/lib";
import cx from "classnames";
import React from "react";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { FormFieldProps } from "../../../types";
import { TextFieldValue } from "../text.config";
import { validateByType } from "../validation";
import { PlainTextFieldProps } from "@smartsuite/react-ui/lib/components/forms/PlainTextField/PlainTextField";

export type TextFieldControlProps = FormFieldProps &
  Pick<PlainTextFieldProps, "placeholder" | "type">;

export const TextFieldControl: React.FunctionComponent<TextFieldControlProps> = ({
  caption,
  className,
  field,
  formItem,
  label,
  name,
  placeholder,
  required,
  type,
}) => {
  const formField = useFormField<TextFieldValue>({
    name,
    validate: validateByType(type, field, formItem),
  });

  const maxLength = field.params.max_length;

  // Prevents attempted form submission on enter. It also prevents
  // a previously edited dropdown field to have its value cleaned.
  // TODO: Need to investigate why we are keeping a reference to those
  // fields and clearing them when editing these here
  const handleKeyDown = (event: KeyboardEvent): void => {
    if (isEnterKey(event)) event.preventDefault();
  };

  const inputClassName = cx("form-text-field", className, {
    "has-value": !!formField.value,
  });

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
    >
      <TextFieldGroup
        acceleratorEnabled={!!maxLength}
        className={inputClassName}
        inputGroupState={formField.state}
        maxLength={maxLength}
        name={formField.name}
        placeholder={placeholder}
        required={required}
        type={type}
        value={formField.value}
        onBlur={formField.onBlur}
        onChange={formField.onChange}
        onFocus={formField.onFocus}
        onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
      />
    </FormFieldControl>
  );
};
