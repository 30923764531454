import { defineMessages } from "react-intl";

export const yesNoFieldText = defineMessages({
  no: {
    defaultMessage: "No",
    id: "oUWADl",
  },
  yes: {
    defaultMessage: "Yes",
    id: "a5msuh",
  },
});
