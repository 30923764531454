import { FieldRegistryConfig } from "../../types";
import { RatingDisplayFormat } from "@smartsuite/types";
import { RatingFieldControl } from "./RatingFieldControl/RatingFieldControl";

export type RatingFieldValue = {
  color: string;
  display_format: RatingDisplayFormat;
  scale: number;
};

export const ratingFieldConfig: FieldRegistryConfig<RatingFieldValue> = {
  icon: "rating-field",
  control: RatingFieldControl,
};
