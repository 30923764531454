import { FormikValues } from "formik";
import { Country } from "react-phone-number-input";
import { Fields } from "./fields";

import { FileSecurity } from "./file";
import { Filter, SortRule, Tag } from "@smartsuite/types";

export interface FormConfig {
  application: FormConfigApplication;
  file_security?: FileSecurity;
  form_state: FormState;
  sharing_hash: string;
  display_smartsuite_branding: boolean;
  filestack_api_key: string;
  tags: Tag[];
}

export interface Member {
  id: string;
  name: string;
}

export interface FormState {
  description: string | null;
  display_message: string | null;
  has_progress_restore: boolean;
  items: FormConfigItem[];
  logo_handle: string | null;
  redirect_to_url: string | null;
  submit_label: string | null;
  time_format: "12h" | "24h" | null;
  title: string | null;
}

export type FormStateItemConditions = {
  enabled?: boolean;
  object?: Filter;
};

export type FormStateItemSelections = {
  enabled?: boolean;
  choiceIDs?: string[];
};

export interface FormConfigItem {
  type: FormItemType;
  slug: string;
  required: boolean;
  params: {
    caption?: string;
    conditions?: FormStateItemConditions;
    linked_record_conditions?: FormStateItemConditions;
    items?: FormConfigItem[];
    label?: string;
    selections?: FormStateItemSelections;
    hidden?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export type ImageAlignment = "left" | "right" | "center" | "cover";

export interface FormConfigImageItem extends FormConfigItem {
  params: {
    caption?: string;
    conditions?: FormStateItemConditions;
    items?: FormConfigItem[];
    label?: string;
    selections?: FormStateItemSelections;
    imageUrl?: string;
    fileStackImageHandle?: string;
    alignment?: ImageAlignment;
    maxHeight?: number;
  };
}

export interface FormConfigVideoItem extends FormConfigItem {
  params: {
    caption?: string;
    conditions?: FormStateItemConditions;
    items?: FormConfigItem[];
    label?: string;
    selections?: FormStateItemSelections;
    videoUrl?: string;
    maxHeight?: number;
  };
}

export type CalloutType = "info" | "note" | "success" | "warning" | "error" | "decision";

export interface FormConfigCalloutItem extends FormConfigItem {
  params: {
    caption?: string;
    conditions?: FormStateItemConditions;
    items?: FormConfigItem[];
    label?: string;
    selections?: FormStateItemSelections;
    calloutType?: CalloutType;
    doc?: string;
  };
}

interface Color {
  color: string;
}

export interface FormConfigDividerItem extends FormConfigItem {
  params: {
    caption?: string;
    conditions?: FormStateItemConditions;
    items?: FormConfigItem[];
    label?: string;
    selections?: FormStateItemSelections;
    title?: string;
    color?: Color;
  };
}

export enum FormItemType {
  field = "field",
  html_block = "html_block",
  section = "section",
  image = "image",
  video = "video",
  callout = "callout",
  divider = "divider",
  recaptcha = "recaptcha",
}

export interface FormConfigApplication {
  structure: FormConfigApplicationStructureItem[];
}

export interface FormConfigApplicationStructureItem {
  slug: string;
  label: string;
  field_type: Fields;
  params: AppFieldParams;
}

export interface FieldChoice {
  label: string;
  value: string;
}

export interface AppFieldParams {
  choices?: FieldChoice[];
  default_country?: Country;
  entries_allowed?: "single" | "multiple";
  help_text?: string;
  linked_application?: string;
  max_length?: number;
  max_value?: number;
  min_value?: number;
  placeholder?: string;
  required?: boolean;
  scale?: number;
  display_fields?: string[];
  color?: string;
  display_format?: string;
  duration_format?: string;
  include_labels?: boolean;
  rating_highest?: string;
  rating_lowest?: string;
  filter_data?: Filter;
  sort_data?: SortRule[];
  select_record_modal_sort_data?: SortRule[];
  select_record_modal_visible_fields?: string[];
  visible_fields?: string[];
}

export interface BaseRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type FormValues = FormikValues;

export interface LinkedRecordObject {
  id: string;
  title: string;
}
