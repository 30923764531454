import { FC, useEffect, useMemo, useRef, useState } from "react";
import { noop } from "rxjs";
import { Text } from "@smartsuite/react-ui/lib/components/typography/Text/Text";
import { SimpleRecord } from "@smartsuite/types/lib/models/record";
import { AppField, ID } from "@smartsuite/types";
import { RecordItem } from "./RecordItem/RecordItem";
import {
  SimpleListVirtualScroll,
  SimpleVirtualScrollRef,
} from "../SimpleVirtualScroll/SimpleVirtualScroll";
import { BaseRecord } from "../../../../../../../../types/form";

import "./RecordList.sass";

const MAX_VISIBLE_FIELDS = 4;

interface RecordListProps {
  records: SimpleRecord[];
  fieldsToShow?: { [key: string]: AppField };
  linkedAppFields?: { [key: string]: AppField };
  selectedRecords: ID[];
  onRecordClick: (recordId: ID) => void;
  relatedRecords?: BaseRecord[];
  focusedItem: SimpleRecord;
  cursor: number;
}

const headerHeight = 44;
const itemHeight = 80;

export const RecordList: FC<RecordListProps> = ({
  records,
  fieldsToShow,
  linkedAppFields,
  selectedRecords,
  onRecordClick,
  relatedRecords,
  cursor,
  focusedItem,
}) => {
  const [height, setHeight] = useState(0);
  const virtualScrollRef = useRef<SimpleVirtualScrollRef>({
    scrollToIndex: noop,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const offsetIndex = useMemo(() => {
    if (focusedItem) {
      const scrollBarHeight = wrapperRef.current?.offsetHeight;
      const itemsInView = Math.floor((scrollBarHeight ?? 0) / itemHeight);
      if (cursor < itemsInView) {
        return 0;
      }
      return cursor;
    }
  }, [cursor, focusedItem]);

  useEffect(() => {
    virtualScrollRef.current.scrollToIndex(offsetIndex ?? 0);
  }, [offsetIndex]);

  if (!fieldsToShow) {
    return null;
  }

  return (
    <div
      className="record-list"
      aria-label="Linked Records List"
      style={{ height: height + headerHeight }}
    >
      <div className="record-list__wrapper" ref={wrapperRef}>
        <SimpleListVirtualScroll
          className="record-list__scrollbar"
          containerClassName="record-list__scrollbar-body"
          ref={virtualScrollRef}
          items={records}
          additionalContentRender={
            <div className="record-list__header">
              {Object.keys(fieldsToShow)
                .slice(0, MAX_VISIBLE_FIELDS)
                .map((key) => {
                  const value = fieldsToShow[key];

                  let title = value?.label;

                  if (key.includes(".")) {
                    const nestedField = linkedAppFields?.[key.split(".")[0]]?.nested?.find(
                      (nestedField) => nestedField.slug === key.split(".")[1]
                    );

                    if (nestedField) {
                      title = `${value?.label}: ${nestedField.label}`;
                    }
                  }

                  return (
                    <Text
                      key={title}
                      className="record-list__title"
                      size="xs"
                      weight="semibold"
                      color="tertiary"
                      wrapMode="ellipsis"
                    >
                      {title}
                    </Text>
                  );
                })}
            </div>
          }
          itemHeight={80}
          gutterRight={3}
          noScrollX={false}
          noScrollY={false}
          onChangeHeight={setHeight}
        >
          {({ item: record }) => {
            return (
              <RecordItem
                key={record.id}
                fieldsToShow={fieldsToShow}
                record={record}
                isFocused={focusedItem?.id === record?.id}
                isSelected={selectedRecords.includes(record.id)}
                maxVisibleFields={MAX_VISIBLE_FIELDS}
                onClick={onRecordClick}
                relatedRecords={relatedRecords}
              />
            );
          }}
        </SimpleListVirtualScroll>
      </div>
    </div>
  );
};
