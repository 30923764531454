import { $t } from "../../../utils/intl";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { TextAreaFieldControl } from "./TextareaFieldControl/TextAreaFieldControl";

export type TextareaFieldValue = string;

export const textareaFieldConfig: FieldRegistryConfig<TextareaFieldValue> = {
  icon: "textarea",
  control: (props) => (
    <TextAreaFieldControl placeholder={$t(fieldsText.textPlaceholder)} {...props} />
  ),
};
