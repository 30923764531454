import { $t } from "../../../utils/intl";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { TextFieldControl } from "./TextFieldControl/TextFieldControl";

export type TextFieldValue = string;

export const textFieldConfig: FieldRegistryConfig<TextFieldValue> = {
  icon: "text",
  control: (props) => <TextFieldControl placeholder={$t(fieldsText.textPlaceholder)} {...props} />,
};
