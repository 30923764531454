import { FC } from "react";
import * as yup from "yup";
import { RatingButtons, Text } from "@smartsuite/react-ui/lib";
import { RatingDisplayFormat } from "@smartsuite/types";

import { FormFieldProps } from "../../../types";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import "./RatingFieldControl.sass";

export const RatingFieldControl: FC<FormFieldProps> = ({
  field,
  formItem,
  name,
  label,
  required,
  caption,
}) => {
  const formField = useFormField<number>({
    name,
    validate: validateWithYup(applyYupDefaults(yup.string(), field, formItem)),
  });

  const handleChange = (value: number): void => {
    if (value === formField.value) {
      formField.onChange(null);
      return;
    }
    formField.onChange(value);
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
    >
      <div className="rating-field">
        {field.params.include_labels && (
          <Text size="xs" wrapMode="ellipsis" className="rating-field__label">
            {field.params.rating_lowest}
          </Text>
        )}
        <RatingButtons
          displayFormat={(field.params.display_format as RatingDisplayFormat) ?? "stars"}
          value={formField.value}
          maxRate={field.params.scale ?? 5}
          disableTooltip={false}
          color={field.params.color ?? "#FDA80D"}
          onChangeValue={handleChange}
        />
        {field.params.include_labels && (
          <Text size="xs" wrapMode="ellipsis" className="rating-field__label">
            {field.params.rating_highest}
          </Text>
        )}
      </div>
    </FormFieldControl>
  );
};
