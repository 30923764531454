import { defineMessages } from "react-intl";

export const TagsFieldSelectMessages = defineMessages({
  noTags: {
    defaultMessage: "No matching tags.",
    id: "fCjXoN",
  },
  searchPlaceholder: {
    defaultMessage: "Search tags",
    id: "Km4h+B",
  },
});
