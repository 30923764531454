import { defineMessages } from "react-intl";

export const AddressFieldControlMessages = defineMessages({
  locationAddressSingleLinePlaceholder: {
    defaultMessage: "Enter street",
    id: "zj1uuK",
  },
  locationAddressPlaceholder: {
    defaultMessage: "Street",
    id: "BaIwdV",
  },
  locationAddress2Placeholder: {
    defaultMessage: "Suite / Apt / Bldg (optional)",
    id: "9/TaS2",
  },
  locationCityPlaceholder: {
    defaultMessage: "City",
    id: "TE4fIS",
  },
  locationStatePlaceholder: {
    defaultMessage: "State / Region",
    id: "wJJax6",
  },
  locationZipPlaceholder: {
    defaultMessage: "Postal Code",
    id: "UqKMjl",
  },
  locationCountryPlaceholder: {
    defaultMessage: "Country",
    id: "vONi+O",
  },
  locationCountrySearchPlaceholder: {
    defaultMessage: "Search a country",
    id: "wtq0Zz",
  },
});
