import { FormConfigDividerItem } from "../../types/form";
import React, { FC } from "react";
import cx from "classnames";
import { Paragraph } from "@smartsuite/react-ui/lib";

interface FormDividerItemProps {
  item: FormConfigDividerItem;
  checkFieldVisibility: (slug: string) => boolean;
}

export const FormDividerItem: FC<FormDividerItemProps> = ({ item, checkFieldVisibility }) => {
  const { color, title } = item.params;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);
  if (!isItemConditionSatisfied) return null;

  const classes = cx("divider-item", {
    "divider-item__with-text": title,
  });

  return (
    <div className={classes}>
      <hr className="divider-item__divider" style={{ borderColor: color?.color }} />
      <Paragraph className="divider-item__text" size="m" color="primary">
        {title}
      </Paragraph>
      <hr className="divider-item__divider" style={{ borderColor: color?.color }} />
    </div>
  );
};
