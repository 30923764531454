import { Paragraph, Text, Title } from "@smartsuite/react-ui/lib";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FormConfig } from "../../types/form";
import { $t } from "../../utils/intl";

import "./FormSubmittedMessage.sass";
import { FormSubmittedMessageText } from "./FormSubmittedMessage.text";

export interface FormSubmittedMessageProps {
  config: FormConfig;
  onSubmitAnother: () => void;
}

export const FormSubmittedMessage: React.FC<FormSubmittedMessageProps> = ({
  config,
  onSubmitAnother,
}) => {
  const multilineMessage = config.form_state?.display_message?.split("\n");

  return (
    <div className="form-submitted-message">
      <Paragraph className="form-submitted-message__img-container">
        <img src="/images/form-sent.svg" alt="Form Sent" />
      </Paragraph>

      <Title size="xxl" weight="bold">
        {$t(FormSubmittedMessageText.thankYou)}
      </Title>

      <Paragraph size="l" weight="regular" className="form-submitted-message__subtitle">
        {config.form_state?.display_message ? (
          <Text size="l">
            {multilineMessage?.map((line, index) => {
              return (
                <span key={index}>
                  {line}
                  <br />
                </span>
              );
            })}
          </Text>
        ) : (
          <FormattedMessage
            defaultMessage={`Form was <bold>successfully submitted.</bold>`}
            id="Fcny+z"
            values={{
              bold: (content) => {
                return (
                  <Text size="l" weight="semibold">
                    {content}
                  </Text>
                );
              },
            }}
          />
        )}
      </Paragraph>

      <Paragraph
        size="l"
        weight="regular"
        className="form-submitted-message__retry-button"
        color={"tertiary"}
      >
        <FormattedMessage
          defaultMessage={`Want to submit another response ? <link>Click here</link>.`}
          id="1x4g3N"
          values={{
            link: (content) => {
              return (
                <Text
                  color="link"
                  className="form-submitted-message__submit-another"
                  size="l"
                  weight="regular"
                  onClick={onSubmitAnother}
                >
                  {content}
                </Text>
              );
            },
          }}
        />
      </Paragraph>
    </div>
  );
};
