/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInputGroupState } from "@smartsuite/react-ui/lib";
import { FieldHookConfig, FormikHandlers, isInputEvent, useField } from "formik";
import React, { useCallback } from "react";
import { useFormikErrorMessage } from "./useFormikErrorMessage";
import { InputGroupState } from "@smartsuite/react-ui/lib/components/forms/InputGroup/InputGroup.constants";

export interface UseFormFieldResult<T> {
  value: T;
  name: string;
  errorMessage: string | undefined;
  state: InputGroupState;
  onChange: FormikHandlers["handleChange"];
  onBlur: (event?: React.FocusEvent) => void;
  onFocus: () => void;
  onChangeFocused: (focused: boolean) => void;
}

/**
 * Extends Formik's `useField` hook with our own input state bindings and error message information.
 *
 * @param config The formik's field configuration.
 * @returns
 */
export function useFormField<T = any>(config: FieldHookConfig<T>): UseFormFieldResult<T> {
  const [{ value, name, onChange, onBlur }, meta, { setValue, setTouched }] = useField(config);
  const errorMessage = useFormikErrorMessage(meta);
  const { state, onBlur: stateOnBlur, onFocus: stateOnFocus } = useInputGroupState(!!errorMessage);

  const handleChange = useCallback(
    (eventOrValue: unknown) => {
      if (isInputEvent(eventOrValue)) {
        return onChange(eventOrValue);
      } else {
        return setValue(eventOrValue as T);
      }
    },
    [onChange, setValue]
  );

  const handleBlur = useCallback(
    (fieldOrEvent: any) => {
      stateOnBlur();

      if (isInputEvent(fieldOrEvent) && (fieldOrEvent.target as any)?.name) {
        onBlur(fieldOrEvent);
      } else {
        setTouched(true);
      }
    },
    [onBlur, stateOnBlur, setTouched]
  );

  const handleChangeFocused = useCallback(
    (focused: boolean) => {
      if (focused) {
        stateOnFocus();
      } else {
        handleBlur(null);
      }
    },
    [stateOnFocus, handleBlur]
  );

  return {
    value,
    name,
    errorMessage,
    state,
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: stateOnFocus,
    onChangeFocused: handleChangeFocused,
  };
}
