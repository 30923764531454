import { Icon, Paragraph, Title } from "@smartsuite/react-ui/lib";
import React from "react";

import { $t } from "../../utils/intl";
import { ProgressRestoreText } from "./ProgressRestore.text";
import "./ProgressRestoreBanner.sass";

export const ProgressRestoreBanner: React.FC = () => {
  return (
    <div className="progress-restore-banner">
      <div className="progress-restore-banner__header">
        <Icon name="checkmark" size="M" color="primary" />
        <Title size="xxs" weight="medium">
          {$t(ProgressRestoreText.autosaveTitle)}
        </Title>
      </div>
      <Paragraph size="m" weight="regular">
        {$t(ProgressRestoreText.autosaveDescription)}
      </Paragraph>
      <Paragraph size="s" weight="regular" color="tertiary">
        {$t(ProgressRestoreText.autosaveNote)}
      </Paragraph>
    </div>
  );
};
