import { Preferences } from "@smartsuite/types";

// Resolves in runtime the user's locale and timezone to use as preferences for comparison
// calculation, especially for Date fields which use moment.js timezone for comparing the
// relative dates (today, yesterday, etc...)

const dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
const userTimezone = dateTimeFormat.timeZone;
const userLocale = dateTimeFormat.locale;

/**
 * NB: We don't have a specific endpoint to fetch all this information for the 'Preferences'
 * type object, so instead we pass in a mocked one.
 */
export const mockPreferences: Preferences = {
  email: "",
  full_name: { first_name: "", last_name: "" },
  language: { label: "English", value: "en" },
  locale: {
    currency_before: false,
    currency_code: "USD",
    date_format: "MM/DD/YYYY",
    first_day_of_week: 1,
    label: userLocale,
    local_date_format: "MM/DD/YYYY",
    local_time_format: "12h",
    time_format: "12h",
    value: userLocale,
  },
  profile_image: {
    created_on: "",
    description: "",
    file_type: "",
    handle: "",
    metadata: {
      container: "",
      filename: "",
      key: "",
      mimetype: "",
      size: 0,
    },
    security: {
      policy: "",
      signature: "",
    },
    transform_options: { crop: { dim: [0, 0, 0, 0] } },
    updated_on: "",
  },
  theme: {
    button_color: "",
    heading_color: "",
    label: "",
    value: "",
  },
  timezone: { label: userTimezone, value: userTimezone },
};
